.captcha {
    &-title {
        height: 42px;
        width: 100%;
        text-align: center;
        border-radius: 2px;
        background-color: #f3f3f3;
        color: #bbb;
        font-size: 14px;
        letter-spacing: .1px;
        line-height: 42px;
        @media (max-width: 1024px) {
            height: px2rem(80px);
            line-height: px2rem(80px);
        }
    }

    &-show {
        display: none;
        height: 42px;
        width: 100%;
        text-align: center;
        border-radius: 2px;
        background-color: #f3f3f3;
        @media (max-width: 1024px) {
            height: px2rem(80px);
        }
    }

    &-loading {
        margin: auto;
        width: 70px;
        height: 20px;
    }

    &-loading-dot {
        float: left;
        width: px2rem(8px);
        height: px2rem(8px);
        margin: px2rem(18px) px2rem(4px);
        background: #ccc;
        border-radius: 50%;
        opacity: 0;
        box-shadow: 0 0 2px black;
        animation: loadingFade 1s infinite;

        &:nth-child(2) {
            animation-delay: .1s
        }

        &:nth-child(3) {
            animation-delay: .2s
        }

        &:nth-child(4) {
            animation-delay: .3s
        }
    }

    .geetest_holder.geetest_wind .geetest_logo,
    .geetest_holder.geetest_wind .geetest_success_logo {
        display: none;
    }
}

@keyframes loadingFade {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .8;
    }

    100% {
        opacity: 0;
    }
}

.index-captcha {
    width: 100%;

    .captcha-title {
        width: 100%;
        text-align: left;
        background: transparent;
        color: #fff;
        font-size: 14px;
        letter-spacing: .1px;
    }

    .captcha-show {
        display: none;
        width: 100%;
        text-align: left;
        background: transparent;
    }

    .captcha-loading {
        margin: auto;
        width: 70px;
        height: 20px;
    }

    .captcha-loading-dot {
        float: left;
        width: px2rem(8px);
        height: px2rem(8px);
        margin: px2rem(18px) px2rem(4px);
        background: #ccc;
        border-radius: 50%;
        opacity: 0;
        box-shadow: 0 0 2px black;
        animation: loadingFade 1s infinite;

        &:nth-child(2) {
            animation-delay: .1s
        }

        &:nth-child(3) {
            animation-delay: .2s
        }

        &:nth-child(4) {
            animation-delay: .3s
        }
    }

    .geetest_holder.geetest_wind .geetest_radar_tip,
    .geetest_holder.geetest_wind .geetest_success_radar_tip {
        padding: 0 px2rem(46px);
        height: px2rem(80px);
        line-height: px2rem(80px);
        font-size: 16px;
        @media (max-width: 1024px) {
            font-size: px2rem(26px);
        }
        @media (max-width: 480px) {
            padding: 0 35px;
        }
    }

    .geetest_holder.geetest_wind .geetest_radar_tip {
        color: #fff;
    }


    .geetest_holder.geetest_wind .geetest_logo,
    .geetest_holder.geetest_wind .geetest_success_logo {
        display: none;
    }

    .geetest_holder.geetest_wind * {
        font-family: PingFang SC, Microsoft YaHei, Helvetica Neue, Arial, Source Han Sans SC, sans-serif;
    }

    .geetest_holder.geetest_wind .geetest_radar_btn {
        background: transparent;
        border: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:hover {
            background: transparent;
        }
    }

    .geetest_holder.geetest_wind.geetest_radar_click_ready .geetest_radar_btn {
        background: transparent;
    }

    @media (max-width: 480px) {
        .geetest_holder.geetest_wind .geetest_radar {
            margin: 6px;
            width: 20px;
            height: 20px;
        }
    }

    .geetest_holder.geetest_wind {
        height: px2rem(80px);
    }

    &.mini-program {
        .geetest_holder.geetest_wind {
            height: px2rem(60px);
        }
        .geetest_holder.geetest_wind .geetest_radar_tip,
        .geetest_holder.geetest_wind .geetest_success_radar_tip {
            padding: 0 px2rem(46px);
            height: px2rem(60px);
            line-height: px2rem(60px);
            font-size: 16px;
            @media (max-width: 1024px) {
                font-size: px2rem(26px);
            }
            @media (max-width: 480px) {
                padding: 0 35px;
            }
        }
    }
}