.shop-landing-banner {
    position: relative;
    padding-top: percentage(800/1920);
    overflow: hidden;
    width: 100%;
    min-width: 1250px;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
    }
    .word {
        position: absolute;
        left: 50%;
        margin-left: -725px;
        z-index: 4;
        top: 50%;
        transform: translateY(-60%);
        backface-visibility: hidden;
        &.anim {
            .title,
            ul {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }
    .title {
        color: #fff;
        font-weight: bold;
        font-size: 68px;
        margin-bottom: px2rem(20px);
        transition: all 1.2s cubic-bezier(.47, .7, .29, 1.45);
        opacity: 0;
        transform: translateX(50%);
    }
    ul {
        transition: all 1.2s .4s cubic-bezier(.47, .7, .29, 1.45);
        opacity: 0;
        transform: translateX(50%);
        li {
            position: relative;
            font-size: 36px;
            line-height: 60px;
            color: #8ce2f9;
            padding-left: 30px;
            &:before {
                width: 6px;
                height: 6px;
                display: block;
                content: '';
                position: absolute;
                left: 0;
                top: 21px;
                border: 6px solid #f8cd4e;
                border-radius: 100%;
            }
        }
    }
    .img-pc {
        display: block;
    }
    @media (max-width: 1440px) {
        .word {
            margin-left: 0;
            left: px2rem(30px);
        }
        .title {
            font-size: 48px;
        }
        ul li {
            font-size: 28px;
            line-height: 50px;
            &:before {
                top: 16px;
            }
        }
    }
    @media (max-width: 1250px) {
        .title {
            font-size: 36px;
        }
        ul li {
            font-size: 24px;
            line-height: 40px;
            &:before {
                top: 11px;
            }
        }
    }
}
.shop-landing-advantage {
    background-color: #e3e9f1;
    padding-top: px2rem(70px);
    min-width: 1250px;
    .top {
        text-align: center;
        margin-bottom: px2rem(30px);
        .title {
            font-size: 48px;
            color: #2761b7;
            font-weight: bold;
            margin-bottom: 5px;
        }
        .p {
            font-size: 20px;
            color: #333;
        }
        .en {
            font-size: 12px;
            color: #c3c3c3;
        }
    }
    .list {
        font-size: 0;
        .item {
            display: inline-block;
            vertical-align: top;
            width: 50%;
            text-align: center;
            background-color: #f5f5f5;
            position: relative;
            padding-bottom: (680/1920) * 100%;
            overflow: hidden;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            &:nth-child(2n+1) {
                .wrapper {
                    transform: translateX(-100%);
                }
            }
            &:nth-child(2n) {
                .wrapper {
                    transform: translateX(100%);
                }
            }
            .wrapper {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: all .6s ease-in;
                opacity: 0;
                &.anim {
                    transform: translateX(0);
                    opacity: 1;
                }
            }
            .img {
                position: absolute;
                display: block;
                width: 100%;
                //height: 100%;
                bottom: 0;
                left: 0;
            }
            .img-m {
                display: none;
                width: 100%;
            }
            .word {
                position: absolute;
                left: 0;
                top: 80px;
                text-align: center;
                width: 100%;
                z-index: 2;
            }
            .t {
                font-size: 36px;
                font-weight: bold;
                color: #fff;
            }
            .p {
                font-size: 20px;
                line-height: 36px;
                color: #fff;
            }
            @media screen and (max-width: 1440px) {
                .word {
                    top: 40px;
                }
                .t {
                    font-size: 28px;
                    margin-bottom: 5px;
                }
                .p {
                    font-size: 16px;
                    line-height: 28px;
                }
            }
            @media screen and (max-width: 1366px) {
                .t {
                    font-size: 32px;
                }
                .p {
                    top: 80px;
                    font-size: 16px;
                    line-height: 26px;
                }
            }
        }
    }
    @media (max-width: 1250px) {
        .top {
            .title {
                font-size: 36px;
            }
        }
        .list {
            .item {
                .word {
                    top: 30px;
                }
                .t {
                    font-size: 26px;
                }
            }
        }
    }
}
.shop-landing-highlight {
    padding: px2rem(100px) px2rem(30px) 0;
    background-color: #dfe3e8;
    background-image: url(../images/shop_landing_bg6.jpg);
    background-repeat: no-repeat;
    background-position: center top;
    box-sizing: border-box;
    min-width: 1250px;
    .top {
        text-align: center;
        margin-bottom: px2rem(30px);
        .title {
            font-size: 48px;
            color: #fff;
            font-weight: bold;
            margin-bottom: 5px;
        }
        .p {
            font-size: 20px;
            color: #fff;
        }
        .en {
            font-size: 12px;
            color: rgba(#c3c3c3, .2);
        }
    }
    .slide-box {
        margin: 0 auto;
        background-color: #fff;
        max-width: 1440px;
        .hd {
            display: flex;
            .item {
                position: relative;
                flex: 1;
                overflow: hidden;
                height: 108px;
                line-height: 108px;
                text-align: center;
                color: #fff;
                font-size: 24px;
                font-weight: bold;
                cursor: pointer;
                &.on {
                    &:after {
                        border-bottom: 8px solid #fff;
                        border-right: 12px solid transparent;
                        border-left: 12px solid transparent;
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        margin-left: -12px;
                        content: '';
                        display: block;
                        z-index: 5;
                    }
                }
                &:nth-child(1) {
                    background-color: #1365ba;
                }
                &:nth-child(2) {
                    background-color: #1970ca;
                }
                &:nth-child(3) {
                    background-color: #1199d9;
                }
                &:nth-child(4) {
                    background-color: #0ca7e1;
                }
                &:nth-child(5) {
                    background-color: #37cff4;
                }
            }
        }
        .bd {
            .box {
                display: none;
                position: relative;
                overflow: hidden;
                &:first-child {
                    display: block;
                }
                .img-pc {
                    display: block;
                    width: 100%;
                }
                .word {
                    position: absolute;
                    z-index: 2;
                }
                .t {
                    font-size: 42px;
                    font-weight: bold;
                    color: #5196e2;
                    margin-bottom: 10px;
                }
                .p {
                    font-size: 22px;
                    line-height: 38px;
                    color: #596f87;
                }
                &:nth-child(1) {
                    .word {
                        top: percentage(75/730);
                        left: 50%;
                        margin-left: -40px;
                        right: 90px;
                    }
                    ul {
                        li {
                            position: relative;
                            padding-left: 20px;
                            font-size: 22px;
                            line-height: 30px;
                            color: #596f87;
                            margin-bottom: px2rem(20px);
                            &:before {
                                position: absolute;
                                left: 0;
                                width: 12px;
                                height: 12px;
                                border-radius: 100%;
                                background: #ffb033;
                                top: 9px;
                                content: '';
                            }
                        }
                    }
                }
                &:nth-child(2) {
                    .word {
                        top: percentage(40/730);
                        left: percentage(120/1440);
                        right: percentage(120/1440);
                        text-align: center;
                    }
                }
                &:nth-child(3) {
                    .word {
                        top: percentage(130/730);
                        left: 50%;
                        margin-left: 68px;
                        right: percentage(130/1440);
                    }
                }
                &:nth-child(4) {
                    .word {
                        top: percentage(130/730);
                        left: 50%;
                        margin-left: 194px;
                        right: percentage(130/1440);
                    }
                }
                &:nth-child(5) {
                    .word {
                        top: percentage(40/730);
                        left: percentage(120/1440);
                        right: percentage(120/1440);
                        text-align: center;
                    }
                }
            }
        }
    }
    @media (max-width: 1440px) {
        .slide-box {
            .bd {
                .box {
                    .t {
                        font-size: 36px;
                    }
                    .p {
                        font-size: 18px;
                        line-height: 30px;
                    }
                    &:nth-child(1) {
                        ul {
                            li {
                                font-size: 18px;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 1250px) {
        .top {
            .title {
                font-size: 36px;
            }
        }
        .slide-box {
            .hd {
                .item {
                    font-size: 20px;
                    height: 80px;
                    line-height: 80px;
                }
            }
            .bd {
                .box {
                    .t {
                        font-size: 28px;
                    }
                    .p {
                        font-size: 16px;
                        line-height: 24px;
                    }
                    &:nth-child(1) {
                        .word {
                            top: px2rem(30px);
                        }
                        ul li {
                            font-size: 16px;
                            line-height: 24px;
                            &:before {
                                top: 6px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.shop-landing-module {
    padding: px2rem(60px) px2rem(30px) 0;
    background-color: #dfe3e8;
    box-sizing: border-box;
    min-width: 1250px;
    .top {
        text-align: center;
        margin-bottom: px2rem(30px);
        .title {
            font-size: 48px;
            color: #2761b7;
            font-weight: bold;
            margin-bottom: 5px;
        }
        .p {
            font-size: 20px;
            color: #333;
        }
        .en {
            font-size: 12px;
            color: #c3c3c3;
        }
    }
    ul {
        font-size: 0;
        border-top: 1px solid #edf1f7;
        border-left: 1px solid #edf1f7;
        background-color: #fff;
        max-width: 1440px;
        margin: 0 auto;
        li {
            display: inline-flex;
            vertical-align: top;
            width: percentage(1/6);
            height: 209px;
            box-sizing: border-box;
            border-right: 1px solid #edf1f7;
            border-bottom: 1px solid #edf1f7;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover {
                box-shadow: 0 0 10px rgba(#1b3c6e, .1);
            }
            img {
                width: 87px;
                height: 87px;
                margin-bottom: 10px;
            }
            .t {
                font-size: 18px;
                color: #000;
            }
        }
    }
    @media (max-width: 1250px) {
        .top {
            .title {
                font-size: 36px;
            }
        }
        ul {
            li {
                height: 175px;
                img {
                    width: 64px;
                    height: 64px;
                }
            }
        }
    }
}
.shop-landing-case {
    padding: px2rem(70px) 0;
    background-color: #dfe3e8;
    min-width: 1250px;
    .top {
        text-align: center;
        .title {
            font-size: 48px;
            color: #2761b7;
            font-weight: bold;
            margin-bottom: 5px;
        }
    }
    .recommend-case-swiper {
        max-width: 1440px;
        margin: 0 auto;
        padding: px2rem(40px) 0 0;
        @media (max-width: 1460px) {
            width: 1120px;
            min-width: 0;
        }
    }
    &-more {
        display: block;
        margin: 0 auto;
        width: 246px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background-color: #fff;
        font-size: 14px;
        color: #666;
        transition: all .2s;
        &:hover {
            background-color: #b60a00;
            color: #fff;
        }
    }
    @media (max-width: 1250px) {
        .top {
            .title {
                font-size: 36px;
            }
        }
    }
}