.mini-program-banner {
    .swiper-container {
        width: 100%;
        height: 800px;
        overflow: hidden;

        @media (max-width: 1800px) {
            height: px2vw(800);
        }

        @media (max-width: 1024px) {
            height: px2rem(500px);
        }

        .swiper-slide {
            overflow: hidden;

            &>div {
                display: block;
                width: 100%;
                height: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                &.pc {
                    display: block;
                }

                &.m {
                    display: none;
                }

                @media (max-width: 1024px) {
                    &.pc {
                        display: none;
                    }

                    &.m {
                        display: block;
                    }
                }
            }

        }

        .swiper-button-prev,
        .swiper-button-next {
            top: 50%;
            transform: translateY(-50%);
            width: 17px;
            height: 31px;
            background-size: contain;
            opacity: .7;
            &:hover {
                opacity: 1;
            }
        }
        .swiper-button-prev {
            left: 80px;
        }
        .swiper-button-next {
            right: 80px;
        }

    }
}

.mini-program-section {
    .title-block {
        .title {
            font-size: px2rem(45px);
            line-height: 1;
            color: #000;
            text-align: center;
            margin-bottom: px2rem(17px);
        }
        .subtitle {
            font-size: px2rem(22px);
            line-height: 1;
            color: #666;
            font-weight: 400;
            text-align: center;
            @media (max-width: 480px) {
                font-size: 12px;
            }
        }
    }
    &.section-1 {
        padding-top: px2rem(100px);
        padding-bottom: px2rem(95px);
        @media (max-width: 1024px) {
            padding-top: px2rem(80px);
            padding-bottom: px2rem(100px);
        }
        .title-block {
            margin-bottom: px2rem(62px);
        }
        .list {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-bottom: -70px;
            @media (max-width: 1024px) {
                margin-bottom: px2rem(-80px);
            }
            .item {
                box-sizing: border-box;
                flex: 0 0 12.5%;
                max-width: 12.5%;
                margin-bottom: 70px;
                @media (max-width: 1024px) {
                    margin-bottom: px2rem(80px);
                    flex: 0 0 25%;
                    max-width: 25%;
                }
                &.more {
                    flex: 0 0 25%;
                    max-width: 25%;
                    @media (max-width: 1024px) {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                    .more-btn {
                        cursor: pointer;
                        background-color: #d42b2e;
                        border-radius: px2rem(50px);
                        margin-left: auto;
                        margin-right: auto;
                        width: 280px;
                        height: 100px;
                        line-height: 100px;
                        text-align: center;
                        color: #fff;
                        font-size: 20px;
                        transition: all .35s ease;
                        &:hover {
                            background-color: darken(#d42b2e, 5%);
                        }
                        @media (max-width: 1024px) {
                            font-size: px2rem(32px);
                        }
                    }
                }
                .item-wp {
                    .icon-box {
                        width: px2rem(100px);
                        height: px2rem(100px);
                        border-radius: 50%;
                        background: #eee;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        perspective: 1000px;
                        transition: all .35s linear;
                        margin: 0 auto;
                        .icon {
                            width: px2rem(40px);
                            height: px2rem(40px);
                            position: relative;
                            transition: all .4s linear;
                            img {
                                display: block;
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                z-index: 1;
                                transform: translate(-50%, -50%);
                                transition: all .45s linear;
                                backface-visibility: hidden;
                                &:nth-child(2) {
                                    z-index: 2;
                                    transform: translate(-50%, -50%) rotateY(180deg);
                                }
                            }
                        }
                    }
                    .line {
                        display: block;
                        width: px2rem(10px);
                        height: px2rem(2px);
                        background-color: #d42b2e;
                        margin: 9px auto;
                        @media (max-width: 1024px) {
                            margin: 6px auto px2rem(23px);
                        }
                        @media (max-width: 480px) {
                            margin-top: 4px;
                        }
                    }
                    .tit {
                        font-size: 16px;
                        line-height: 1;
                        text-align: center;
                        color: #000;
                        @media (max-width: 1024px) {
                            font-size: px2rem(26px);
                        }
                    }
                    &:hover {
                        .icon-box {
                            background: #d42b2e;
                            .icon {
                                img {
                                    transform: translate(-50%, -50%) rotateY(180deg);
                                    &:nth-child(2) {
                                        z-index: 2;
                                        transform: translate(-50%, -50%) rotateY(0deg);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.section-2 {
        background: #e9e9e9;
        padding-top: px2rem(100px);
        padding-bottom: px2rem(100px);
        .title-block {
            margin-bottom: px2rem(50px);
        }
        .list {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin: 0 -11px -25px;
            @media (max-width: 1024px) {
                margin: 0 0 px2rem(25px);
            }
            .item {
                box-sizing: border-box;
                flex: 0 0 33.33%;
                max-width: 33.33%;
                padding-left: 11px;
                padding-right: 11px;
                margin-bottom: px2rem(25px);
                @media (max-width: 1024px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: 0;
                    margin-bottom: px2rem(20px);
                }
                .item-wp {
                    position: relative;
                    z-index: 1;
                    padding: px2rem(32px) px2rem(20px) px2rem(32px) px2rem(40px);
                    overflow: hidden;
                    @media (max-width: 1024px) {
                        padding: px2rem(60px) px2rem(40px);
                    }
                    &::after {
                        content: '';
                        display: block;
                        z-index: -1;
                        position: absolute;
                        bottom: 100%;
                        right: 100%;
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        background-color: #d42b2e;
                        transform-origin: center;
                        transform: translate3d(50%, 50%, 0) scale3d(0, 0, 0);
                        transition: transform .45s ease-in-out;
                    }
                    .bg {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        z-index: -1;
                    }
                    .icon {
                        width: px2rem(62px);
                        height: px2rem(61px);
                        position: relative;
                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            z-index: 1;
                            transform: translate(-50%, -50%);
                            transition: all .45s linear;
                            &:nth-child(2) {
                                z-index: 2;
                                opacity: 0;
                            }
                        }
                    }
                    .tit {
                        margin-top: px2rem(23px);
                        font-size: 26px;
                        color: #000;
                        line-height: 1;
                        text-align: left;
                        @include transition(.4s);
                        @media (max-width: 1024px) {
                            margin-top: px2rem(34px);
                            font-size: px2rem(32px);
                        }
                    }
                    .intro {
                        margin-top: px2rem(21.5px);
                        font-size: 16px; 
                        line-height: 35px;
                        color: #555;
                        @include transition(.4s);
                        @media (max-width: 1024px) {
                            margin-top: px2rem(34px);
                        }
                        p {
                            font-size: 16px;
                            line-height: 35px;
                            color: #555;
                            @include transition(.4s);
                            @media (max-width: 1024px) {
                                font-size: px2rem(26px);
                                line-height: px2rem(50px);
                            }
                        }
                        &>* {
                            position: relative;
                            padding-left: px2rem(17px);
                            &::after {
                                content: '';
                                display: block;
                                width: px2rem(7px);
                                height: px2rem(7px);
                                box-sizing: border-box;
                                border: px2rem(2px) solid #d2d2d2;
                                position: absolute;
                                left: 0;
                                top: px2rem(15px);
                                border-radius: 50%;
                                @media (max-width: 1024px) {
                                    top: px2rem(22px);
                                }
                            }
                        }
                    }
                    &:hover {
                        &::after {
                            transform: translate3d(50%, 50%, 0) scale3d(15, 15, 15);
                        }
                        .icon {
                            img {
                                &:nth-child(1) {
                                    opacity: 0;
                                }
                                &:nth-child(2) {
                                    opacity: 1;
                                }
                            }
                        }
                        .tit {
                            color: #fff;
                        }
                        .intro {
                            color: #fff;
                            p {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
    &.section-3 {
        padding-top: px2rem(100px);
        padding-bottom: px2rem(126px);
        @media (max-width: 1024px) {
            padding-top: px2rem(80px);
            padding-bottom: px2rem(100px);
        }
        .title-block {
            margin-bottom: px2rem(64px);
            @media (max-width: 1024px) {
                margin-bottom: px2rem(70px);
            }
        }
        .content {
            display: flex;
            justify-content: flex-start;
            position: relative;
            z-index: 6;
            @media (max-width: 1024px) {
                flex-wrap: wrap;
            }
            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                border: 1px solid #d42b2e;
                background: #fff;
                position: absolute;
                left: px2rem(10px);
                top: px2rem(-10px);
                z-index: -1;
            }
            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                border: 1px solid #d42b2e;
                background: #fff;
                position: absolute;
                left: px2rem(20px);
                top: px2rem(-20px);
                z-index: -2;
            }
            .left {
                flex: 0 0 calc-percent(720, 1500);
                max-width: calc-percent(720, 1500);
                position: relative;
                box-shadow: 0 0 px2rem(40px) rgba(#d42b2e, .2);
                @media (max-width: 1024px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    height: 0;
                    padding-top: calc-percent(547, 670);
                }
                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    display: block;
                    object-fit: cover;
                }
            }
            .right {
                flex: 0 0 calc-percent(780, 1500);
                max-width: calc-percent(780, 1500);
                box-sizing: border-box;
                padding: px2rem(60px) px2rem(40px);
                background: #fff;
                box-shadow: 0 0 px2rem(40px) rgba(#d42b2e, .2);
                @media (max-width: 1024px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: px2rem(40px) px2rem(20px);
                }
                .list {
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    margin: 0 -10px -20px;
                    @media (max-width: 1024px) {
                        margin: 0 px2rem(-7.5px) px2rem(-30px);
                    }
                    .item {
                        box-sizing: border-box;
                        flex: 0 0 25%;
                        max-width: 25%;
                        padding-left: 10px;
                        padding-right: 10px;
                        margin-bottom: 20px;
                        @media (max-width: 1024px) {
                            flex: 0 0 33.33%;
                            max-width: 33.33%;
                            padding-left: px2rem(7.5px);
                            padding-right: px2rem(7.5px);
                            margin-bottom: px2rem(30px);
                        }
                        .item-wp {
                            box-sizing: border-box;
                            display: block;
                            width: 100%;
                            padding: 14px 10px 15px;
                            border-radius: 25px;
                            background: #e9e9e9;
                            position: relative;
                            z-index: 1;
                            overflow: hidden;
                            font-size: 16px;
                            color: #333;
                            text-align: center;
                            @media (max-width: 1024px) {
                                padding: px2rem(16px) px2rem(10px);
                                font-size: px2rem(26px);
                                border-radius: px2rem(30px);
                            }
                            @include transition(.3s);
                            &::after {
                                content: '';
                                display: block;
                                z-index: -1;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                width: px2rem(100px);
                                height: px2rem(100px);
                                border-radius: 50%;
                                background-color: #d42b2e;
                                transform-origin: center;
                                transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
                                transition: transform .35s linear;
                            }
                            &:hover {
                                color: #fff;
                                &::after {
                                    transform: translate3d(-50%, -50%, 0) scale3d(2, 2, 2);
                                    @media (max-width: 1024px) {
                                        transform: translate3d(-50%, -50%, 0) scale3d(4, 4, 4);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.recommend-case {
        background: #f5f5f5;
    }
}

.mini-program-form {
    padding-top: 90px;
    padding-bottom: 80px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 1024px) {
        padding-top: px2rem(114px);
        padding-bottom: px2rem(107px);
    }
    .title-block {
        margin-bottom: 50px;
        @media (max-width: 1920px) {
            margin-bottom: px2vw(50);
        }
        @media (max-width: 1024px) {
            margin-bottom: px2rem(64px);
        }
        .title {
            font-size: px2rem(50px);
            line-height: 1;
            color: #fff;
            text-align: center;
        }
        .subtitle {
            margin-top: 19px;
            font-size: 16px;
            line-height: 1;
            letter-spacing: px2rem(2px);
            color: #fff;
            text-align: center;
            @media (max-width: 1024px) {
                margin-top: px2rem(22px);
                font-size: px2rem(24px);
            }
        }
    }
    .form-box {
        .form-wp {
            display: flex;
            justify-content: flex-start;
            @media (max-width: 1024px) {
                flex-wrap: wrap;
            }
        }
        .form-cell {
            position: relative;
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            @media (max-width: 1024px) {
                flex: 0 0 100%;
            }
            &:not(:last-child) {
                margin-right: 40px;
                @media (max-width: 1920px) {
                    margin-right: px2vw(40);
                }
                @media (max-width: 1024px) {
                    margin-right: 0;
                    margin-bottom: px2rem(46px);
                }
            }
            .word {
                margin-left: px2rem(4px);
                font-size: 16px;
                line-height: 1;
                color: #fff;
                margin-right: px2rem(20px);
                @media (max-width: 1024px) {
                    font-size: px2rem(26px);
                }
            }
            .index-captcha {
                border-bottom: 1px solid #989797;
            }
            .input {
                border-bottom: 1px solid #989797;
                display: block;
                width: 100%;
                input {
                    border: none;
                    outline: none;
                    appearance: none;
                    padding: px2rem(22px) 0;
                    font-size: 16px;
                    line-height: 1;
                    color: #fff;
                    background: transparent;
                    width: 100%;
                    display: block;
                    &::placeholder {
                        color: #fff;
                    }
                    @media (max-width: 1024px) {
                        font-size: px2rem(26px);
                    }
                }
            }
        }
        .submit-btn {
            display: block;
            margin: px2rem(70px) auto 0;
            width: 350px;
            height: 60px;
            line-height: 60px;
            background: #ce2326;
            color: #fff;
            font-size: 18px;
            letter-spacing: px2rem(1px);
            text-align: center;
            border: none;
            outline: none;
            appearance: none;
            cursor: pointer;
            @include transition();
            &:hover {
                background: darken(#ce2326, 5%)
            }
            @media (max-width: 1024px) {
                width: px2rem(400px);
                height: px2rem(80px);
                line-height: px2rem(80px);
                font-size: px2rem(26px);
            }
        }
    }
    &.mini-program {
        padding-top: px2rem(94px);
        padding-bottom: px2rem(70px);
        .title-block {
            margin-bottom: px2rem(52px);
            .title {
                font-size: 45px;
                @media (max-width: 1024px) {
                    font-size: px2rem(50px);
                }
            }
            .subtitle {
                margin-top: px2rem(20px);
                font-size: 22px;
                letter-spacing: 0;
                @media (max-width: 1024px) {
                    font-size: px2rem(24px);
                }
            }
        }
        .form-box {
            .form-wp {
                flex-wrap: wrap;
                margin: 0 -20px -35px;
                @media (max-width: 1024px) {
                    margin: 0 0 px2rem(-48px);
                }
            }
            .form-cell {
                box-sizing: border-box;
                flex: 0 0 25%;
                max-width: 25%;
                padding-left: 20px;
                padding-right: 20px;
                margin-bottom: 35px;
                &:not(:last-child) {
                    margin-right: 0;
                }
                &:nth-child(5) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
                @media (max-width: 1024px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding-left: px2rem(45px);
                    padding-right: px2rem(45px);
                    margin-bottom: px2rem(48px);
                    &:nth-child(5) {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
                input {
                    padding: px2rem(19px) 0 px2rem(20px);
                }
            }
        }
    }
}