.outbreak-banner {
    width: 100%;
    min-width: 1250px;
    .img {
        position: relative;
        height: 0;
        padding-top: (680/1920) * 100%;
        overflow: hidden;
    }
    img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.outbreak-title {
    position: relative;
    margin-bottom: 15px;
    line-height: 62px;
    text-align: center;
    font-size: 60px;
    font-family: 'Microsoft YaHei';
    //font-family: "SourceHanSerifCN";
    font-weight: bold;
    &.brown {
        color: #9e7f46;
    }
    &.blue {
        color: #205973;
    }
    .h5 {
        position: relative;
        z-index: 2;
    }
    .num {
        position: absolute;
        font-size: 186px;
        font-family: "DB";
        font-weight: bold;
        font-style: italic;
        text-transform: uppercase;
        z-index: 1;
    }
    .n1 {
        left: -345px;
        top: 18px;
        color: rgba(255, 255, 255, .6);
    }
    .n2 {
        right: 80px;
        top: 0;
        color: rgba(255, 255, 255, .5);
    }
    .n3 {
        right: -580px;
        bottom: 45px;
        color: rgba(255, 255, 255, .5);
    }
    @media screen and (max-width: 1500px) {
        line-height: 52px;
        font-size: 50px;
        .n1 {
            left: -260px;
            top: 10px;
            font-size: 140px;
        }
        .n2 {
            right: 50px;
            top: 10px;
            font-size: 140px;
        }
        .n3 {
            right: -450px;
            bottom: 25px;
            font-size: 140px;
        }
    }
    @media screen and (max-width: 1400px) {
        line-height: 48px;
        font-size: 45px;
        .n1 {
            left: -225px;
            top: 10px;
            font-size: 120px;
        }
        .n2 {
            right: 50px;
            top: 10px;
            font-size: 120px;
        }
        .n3 {
            right: -400px;
            font-size: 120px;
        }
    }
}
.outbreak-en {
    line-height: 1;
    text-align: center;
    font-size: 12px;
    font-family: "Arial";
    color: #adadad;
    font-weight: bold;
}
.outbreak-return {
    width: 100%;
    min-width: 1250px;
    padding: 126px 0 140px;
    background: #fff none center center no-repeat;
    background-size: cover;
    box-sizing: border-box;
    overflow: hidden;
    .p {
        margin-top: 65px;
        line-height: 48px;
        text-align: center;
        font-size: 32px;
        color: #6d5752;
        @media screen and (max-width: 1500px) {
            line-height: 42px;
            font-size: 28px;
        }
        @media screen and (max-width: 1400px) {
            line-height: 36px;
            font-size: 24px;
        }
    }
}
.outbreak-learn {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: 1250px;
    height: 1023px;
    padding-top: 136px;
    padding-bottom: 110px;
    background: #fff none center center no-repeat;
    background-size: cover;
    box-sizing: border-box;
    overflow: hidden;
    .p {
        margin-top: 50px;
        line-height: 42px;
        font-size: 30px;
        color: #333;
        @media screen and (max-width: 1500px) {
            font-size: 28px;
        }
        @media screen and (max-width: 1400px) {
            line-height: 36px;
            font-size: 24px;
        }
    }
    .img {
        padding-top: 55px;
        text-align: center;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
.outbreak-menbers {
    width: 100%;
    min-width: 1250px;
    height: 1573px;
    padding-top: 40px;
    padding-bottom: 232px;
    background: #fff none center center no-repeat;
    background-size: cover;
    box-sizing: border-box;
    overflow: hidden;
    .p {
        margin-top: 40px;
        line-height: 48px;
        text-align: center;
        font-size: 30px;
        color: #7d6c68;
        @media screen and (max-width: 1500px) {
            font-size: 28px;
        }
        @media screen and (max-width: 1400px) {
            line-height: 36px;
            font-size: 24px;
        }
    }
    .img {
        padding-top: 60px;
        text-align: center;
        @media screen and (max-width: 1500px) {
            margin: 0 50px;
        }
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
.outbreak-training {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: 1250px;
    height: 981px;
    padding-top: 93px;
    padding-bottom: 98px;
    background: #fff none center center no-repeat;
    background-size: cover;
    box-sizing: border-box;
    overflow: hidden;
    ul {
        margin-top: 45px;
        li {
            position: relative;
            line-height: 55px;
            font-size: 30px;
            color: #333;
            @media screen and (max-width: 1500px) {
                line-height: 45px;
                font-size: 28px;
            }
            @media screen and (max-width: 1400px) {
                line-height: 36px;
                font-size: 24px;
            }
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: -20px;
                width: 10px;
                height: 10px;
                margin-top: -5px;
                background: #c7b99a;
                border-radius: 50%;
            }
        }
    }
    .img {
        padding-top: 36px;
        text-align: center;
        @media screen and (max-width: 1500px) {
            margin: 0 50px;
        }
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
.outbreak-progress {
    width: 100%;
    min-width: 1250px;
    height: 1384px;
    background: #fff none center center no-repeat;
    background-size: cover;
    box-sizing: border-box;
    overflow: hidden;
    .outbreak-list {
        position: relative;
        margin-top: 65px;
        .list {
            display: flex;
            position: absolute;
            top: 0;
            left: -140px;
            width: 100%;
        }
        .img-box {
            width: 330px;
            height: 330px;
            border: 5px solid #fff;
            border-radius: 50%;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        .info-box {
            flex: 1;
            height: 260px;
            overflow: hidden;
        }
        .list-img-01 {
            height: 342px;
            margin-left: -80px;
            img {
                width: auto;
                height: 100%;
            }
        }
        .list-img-02 {
            height: 344px;
            margin-top: -25px;
            margin-left: 250px;
            img {
                width: auto;
                height: 100%;
            }
        }
        .list-img-03 {
            height: 345px;
            margin-top: 32px;
            margin-left: 25px;
            img {
                width: auto;
                height: 100%;
            }
        }
    }
}
.outbreak-service {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: 1250px;
    height: 1082px;
    background: #fff none center center no-repeat;
    background-size: cover;
    box-sizing: border-box;
    overflow: hidden;
    .tip {
        margin-top: 55px;
        line-height: 48px;
        text-align: center;
        font-size: 36px;
        color: #7d6c68;
        @media screen and (max-width: 1500px) {
            line-height: 45px;
            font-size: 30px;
        }
        @media screen and (max-width: 1400px) {
            line-height: 36px;
            font-size: 28px;
        }
    }
    .p {
        margin-top: 36px;
        line-height: 48px;
        text-align: center;
        font-size: 32px;
        color: #9e7f46;
        @media screen and (max-width: 1500px) {
            line-height: 36px;
            font-size: 28px;
        }
        @media screen and (max-width: 1400px) {
            line-height: 30px;
            font-size: 24px;
        }
    }
}