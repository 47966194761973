/*oa banner*/
.oa-banner {
    position: relative;
    min-width: 1250px;
    .pagination-oa {
        position: absolute;
        bottom: 55px;
        left: 0;
        width: 100%;
        text-align: center;
        z-index: 99;
        .swiper-pagination-bullet {
            margin: 0 9px;
            width: 12px;
            height: 12px;
            opacity: 1;
            background-color: #b9c9dc;
            &-active {
                background-color: #2775c4;
            }
        }
    }
    .swiper-banner {
        .swiper-slide {
            position: relative;
        }
        .img {
            position: relative;
            width: 100%;
            padding-bottom: (920/1920) * 100%;
            overflow: hidden;
            img {
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }
    }
}
.oa-article {
    position: relative;
    min-width: 1250px;
    @include articleReset(20px);
    font-family: "Microsoft YaHei";
    line-height: 36px;
    color: #666;
    overflow: hidden;
    p {
        margin-bottom: 0;
    }
    .img {
        text-align: center;
    }
    .p {
        max-width: 1080px;
        text-align: center;
        margin: 0 auto 40px;
    }
    button,
    input,
    select,
    textarea,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    i {
        font-family: "Microsoft YaHei";
    }
    @media screen and (max-width: 1600px) {
        font-size: 18px;
        line-height: 32px;
    }
    @media screen and (max-width: 1440px) {
        font-size: 16px;
        line-height: 28px;
    }
}
.bg-grayish-blue {
    background-color: #b9c9dc;
}
.bg-light-blue {
    background-color: #e2ebf6;
}
.bg-light-gray {
    background-color: #e7ebf0;
    .oa-subtitle {
        p {
            color: #a1a1a1;
        }
    }
}
.oa-title {
    margin: 0 auto 30px;
    max-width: 1080px;
    text-align: center;
    overflow: hidden;
    .title {
        line-height: 1;
        font-size: 100px;
        color: #303948;
        font-weight: bold;
        font-family: SimSun;
        &-blue {
            font-family: Arial;
            color: #0f5297;
        }
    }
    p {
        margin-top: 5px;
        text-transform: uppercase;
        line-height: 1.2;
        font-size: 24px;
        color: #303948;
        span {
            position: relative;
            display: inline-block;
            vertical-align: top;
            padding: 0 18px;
            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                height: 1px;
                width: 380px;
                background-color: #0f5297;
            }
            &:before {
                right: 100%;
            }
            &:after {
                left: 100%;
            }
        }
    }
    @media screen and (max-width: 1600px) {
        .title {
            font-size: 80px;
        }
        p {
            font-size: 20px;
        }
    }
    @media screen and (max-width: 1440px) {
        .title {
            font-size: 66px;
        }
        p {
            font-size: 18px;
        }
    }
}
.oa-subtitle,
.oa-subtitle2 {
    position: relative;
    max-width: 1510px;
    margin: 0 auto;
    z-index: 1;
    .title {
        line-height: 58px;
        font-size: 42px;
        color: #2775c4;
        text-transform: uppercase;
        span {
            display: inline-block;
            vertical-align: top;
        }
    }
    .num {
        line-height: inherit;
        font-size: 55px;
        color: #374357;
        font-family: Arial;
        font-weight: bold;
    }
    .bold {
        line-height: inherit;
        font-size: 48px;
        font-weight: bold;
    }
    p {
        line-height: 1.2;
        font-size: 15px;
        color: #666;
        text-transform: uppercase;
    }
    &-left {
        text-align: left;
    }
    @media screen and (max-width: 1763px) {
        max-width: (1510 / 1763) * 100%;
    }
    @media screen and (max-width: 1600px) {
        .title {
            font-size: 38px;
        }
        .num {
            font-size: 50px;
        }
        .bold {
            font-size: 44px;
        }
        p {
            font-size: 14px;
        }
    }
    @media screen and (max-width: 1440px) {
        .title {
            line-height: 46px;
            font-size: 32px;
        }
        .num {
            line-height: 46px;
            font-size: 40px;
        }
        .bold {
            font-size: 36px;
        }
        p {
            font-size: 13px;
        }
    }
}
.oa-subtitle {
    margin-bottom: 35px;
    text-align: center;
}
.oa-subtitle2 {
    .num {
        float: left;
        margin-top: 2px;
        line-height: 58px;
        font-weight: bold;
    }
    .right {
        float: left;
    }
    @media screen and (max-width: 1440px) {
        .num {
            margin-top: 3px;
        }
    }
    @media screen and (max-width: 1440px) {
        .num {
            line-height: 46px;
        }
    }
}
.oa-subtitle3 {
    margin-bottom: 24px;
    text-align: center;
    .title {
        line-height: 1.3;
        font-size: 38px;
        color: #2775c4;
    }
    p {
        line-height: 1.2;
        font-size: 15px;
        color: #666;
        text-transform: uppercase;
    }
    @media screen and (max-width: 1600px) {
        .title {
            font-size: 32px;
        }
        p {
            font-size: 13px;
        }
    }
    @media screen and (max-width: 1440px) {
        .title {
            font-size: 26px;
        }
        p {
            margin-top: 3px;
            font-size: 10px;
        }
    }
}
.oa-blueprint {
    padding: 130px 0 90px;
}
.oa-menu {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    text-align: center;
    font-size: 0;
    background-color: #303948;
    z-index: 99;
    &.fixed {
        position: fixed;
        top: 75px;
        @media screen and (max-width: 1400px) {
            top: 100px;
        }
    }
    a {
        display: inline-flex;
        vertical-align: top;
        align-items: center;
        padding: 20px 5px;
        min-height: 32px;
        width: 62px;
        line-height: 16px;
        font-size: 13px;
        color: #fff;
        border-right: 1px solid #404956;
        transition: all .3s;
        &:first-child {
            border-left: 1px solid #404956;
        }
        span {
            width: 100%;
        }
        &.on {
            background-color: #2775c4;
        }
    }
}
.oa-function {
    position: relative;
    &-workflow {
        padding: 90px 0 80px;
        .p {
            font-size: 22px;
            font-weight: lighter;
        }
    }
    &-mainline {
        padding-top: 155px;
        background-position: top center;
        background-repeat: no-repeat;
    }
    &-assign {
        padding: 86px 0 40px;
    }
    &-journal {
        padding-top: 90px;
        background-position: top center;
        background-repeat: no-repeat;
    }
    &-bumph {
        padding: 110px 0 70px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        .oa-subtitle {
            margin-bottom: 50px;
        }
    }
    &-email {
        padding-top: 100px;
        background-position: top center;
        background-repeat: no-repeat;
        .oa-subtitle {
            .title {
                color: #84c1ff;
            }
            .num {
                color: #84fffe;
            }
            p {
                color: #fff;
            }
        }
    }
    &-schedule {
        padding-top: 80px;
        .oa-subtitle {
            margin-bottom: 40px;
        }
        .img + .img {
            margin-top: 75px;
        }
    }
    &-gtasks {
        padding: 105px 0 50px;
    }
    &-filecabinet {
        padding: 100px 0 40px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        .oa-subtitle {
            margin-bottom: 56px;
            .title {
                color: #84c1ff;
            }
            .num {
                color: #84fffe;
            }
            p {
                color: #fff;
            }
        }
    }
    &-task {
        padding: 88px 0 20px;
    }
    &-conference {
        padding-top: 110px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        .oa-subtitle2 {
            margin-bottom: 20px;
            .title {
                color: #84c1ff;
            }
            .num {
                color: #84fffe;
            }
            p {
                color: #fff;
            }
        }
    }
    &-vehicle {
        padding: 90px 0 110px;
        background-position: top center;
        background-repeat: no-repeat;
        .img + .img {
            margin-top: 38px;
        }
    }
    &-recruit {
        padding: 75px 0 80px;
    }
    &-asset {
        padding: 90px 0 116px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &-sell {
        padding: 90px 0 100px;
        .p {
            margin-bottom: 20px;
            font-size: 18px;
        }
        .oa-subtitle3 {
            margin-bottom: 10px;
        }
    }
    &-condition {
        padding: 80px 0 85px;
    }
    &-wescrm {
        padding-top: 125px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &-tp {
        padding: 85px 0 110px;
        background-position: top center;
        background-repeat: no-repeat;
    }
    &-install {
        padding: 88px 0 108px;
        .oa-subtitle {
            margin-bottom: 0;
        }
    }
}