.system-banner {
    position: relative;
    width: 100%;
    height: 580px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    .word {
        position: absolute;
        top: 140px;
        left: 50%;
        margin-left: -680px;
        z-index: 2;
        color: #fff;
        line-height: 60px;
        .b {
            font-size: 50px;
            transition: all 1.2s cubic-bezier(.47, .7, .29, 1.45);
            opacity: 0;
            transform: translateX(50%);
            margin-bottom: 10px;
            font-weight: bold;
            em {
                font-size: 60px;
                color: #37659e;
                margin-right: 15px;
            }
        }
        .s {
            font-size: 40px;
            transition: all 1.2s .4s cubic-bezier(.47, .7, .29, 1.45);
            opacity: 0;
            transform: translateX(50%);
            span {
                color: #37659e;
            }
        }
        &.anim {
            .b,
            .s {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .word {
            margin-left: 0;
            left: 100px;
            line-height: 50px;
            .b {
                font-size: 42px;
                em {
                    font-size: 52px;
                }
            }
            .s {
                font-size: 32px;
            }
        }
    }
    @media screen and (max-width: 1366px) {
        height: 480px;
        .word {
            top: 110px;
            line-height: 40px;
            .b {
                font-size: 32px;
                em {
                    font-size: 42px;
                }
            }
            .s {
                font-size: 26px;
            }
        }
    }
}
.system-tips {
    margin-top: 20px;
    font-size: 18px;
    line-height: 30px;
    color: #333;
    text-align: center;
    @media screen and (max-width: 1366px) {
        font-size: 16px;
        line-height: 28px;
    }
}
.system-menu {
    text-align: center;
    background: #f5f5f5;
    height: 150px;
    .wrapper {
        text-align: center;
        background: #f5f5f5;
    }
    .item {
        display: inline-block;
        vertical-align: top;
        text-align: center;
        padding: 26px 50px 0;
        height: 150px;
        box-sizing: border-box;
        transition: all .4s ease;
        i {
            font-size: 44px;
            color: #555;
            margin-bottom: 10px;
            transition: all .4s ease;
            display: inline-block;
        }
        .title {
            font-size: 16px;
            color: #555;
            transition: all .4s ease;
        }
        &:hover,
        &.on {
            i,
            .title {
                color: #d24444;
            }
        }
        &:nth-child(1) {
            i {
                &:before {
                    content: '\e664';
                }
            }
        }
        &:nth-child(2) {
            i {
                &:before {
                    content: '\e676';
                }
            }
        }
        &:nth-child(3) {
            i {
                &:before {
                    content: '\e6e7';
                }
            }
        }
        &:nth-child(4) {
            i {
                &:before {
                    content: '\e666';
                }
            }
        }
        &:nth-child(5) {
            i {
                &:before {
                    content: '\e660';
                }
            }
        }
    }
    &.fixed {
        .wrapper {
            position: fixed;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 9909;
        }
        .item {
            height: 100px;
            padding-top: 12px;
            i {
                font-size: 32px;
                margin-bottom: 10px;
            }
            .title {
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 1366px) {
        .item {
            padding: 22px 40px 0;
            i {
                font-size: 36px;
            }
        }
    }
}
.system-intro {
    text-align: center;
    margin-bottom: 30px;
    .cn {
        font-size: 30px;
        line-height: 48px;
        color: #555;
        margin-bottom: 8px;
    }
    .en {
        font-family: Arial;
        font-size: 14px;
        left: 22px;
        color: #bcbcbc;
    }
}
.system-sub-menu {
    max-width: 1440px;
    margin: 0 auto;
    min-width: 1250px;
    padding-bottom: 30px;
    height: 120px;
    .wrapper {
        font-size: 0;
        max-width: 1440px;
        min-width: 1250px;
        margin: 0 auto;
    }
    &.fixed {
        .system-fixed-con {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 9909;
            width: 100%;
            background-color: #f5f5f5;
        }
        .item {
            a {
                height: 90px;
                &:hover {
                    box-shadow: none;
                }
            }
            .icon {
                font-size: 32px;
                margin-bottom: 0;
            }
            .t {
                font-size: 14px;
            }
        }
    }
    .item {
        display: inline-block;
        vertical-align: top;
        box-sizing: border-box;
        width: (1/7) * 100%;
        padding: 0 5px;
        margin-bottom: 10px;
        a {
            display: flex;
            height: 120px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #f5f5f5;
            border-radius: 8px;
            transition: all .4s ease;
            &:hover {
                box-shadow: 0 0 10px rgba(0, 0, 0, .2);
                .icon,
                .t {
                    color: #d24444;
                }
            }
        }
        .icon {
            font-size: 42px;
            color: #666;
            margin-bottom: 10px;
            transition: all .2s ease;
        }
        .t {
            font-size: 16px;
            color: #666;
            transition: all .2s ease;
            @media screen and (max-width: 1366px) {
                font-size: 14px;
            }
        }
        &.on {
            .icon,
            .t {
                color: #d24444;
            }
        }
        &:nth-child(1) {
            .icon {
                &:before {
                    content: '\e670';
                }
            }
        }
        &:nth-child(2) {
            .icon {
                &:before {
                    content: '\e672';
                }
            }
        }
        &:nth-child(3) {
            .icon {
                &:before {
                    content: '\e674';
                }
            }
        }
        &:nth-child(4) {
            .icon {
                &:before {
                    content: '\e673';
                }
            }
        }
        &:nth-child(5) {
            .icon {
                &:before {
                    content: '\e66f';
                }
            }
        }
        &:nth-child(6) {
            .icon {
                &:before {
                    content: '\e671';
                }
            }
        }
        &:nth-child(7) {
            .icon {
                &:before {
                    content: '\e679';
                }
            }
        }
    }
}
.system-article {
    overflow: hidden;
}
.system-title-con {
    background-color: #fff;
    padding: 80px 0 60px;
}
.system-title {
    text-align: center;
    .t {
        font-size: 36px;
        color: #333;
    }
    .en {
        position: relative;
        text-align: center;
        &:before {
            position: absolute;
            width: 100%;
            height: 1px;
            display: block;
            top: 50%;
            left: 0;
            background-color: #e3e3e3;
            content: '';
        }
        span {
            display: inline-block;
            vertical-align: top;
            position: relative;
            z-index: 2;
            background-color: #fff;
            padding: 0 30px;
            font-family: Arial;
            font-size: 12px;
            color: #999;
            text-transform: uppercase;
            line-height: 20px;
        }
    }
}
.ele-section-1 {
    height: 777px;
    background-image: url(../images/sys_bg_1.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .img {
        position: absolute;
        right: 115px;
        bottom: 0;
        img {
            max-width: 100%;
        }
    }
    .info {
        position: absolute;
        top: 153px;
        width: 1108px;
        left: 50%;
        z-index: 2;
        margin-left: -750px;
        &.anim {
            .top,
            .box {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
    .top {
        text-align: center;
        font-size: 56px;
        color: #555;
        margin-bottom: 30px;
        opacity: 0;
        transform: translateY(40px);
        transition: all .4s ease;
        strong {
            color: #6899c2;
        }
    }
    .box {
        position: relative;
        opacity: 0;
        transform: translateY(40px);
        transition: all .4s .3s ease;
        &:after {
            position: absolute;
            display: block;
            width: 1171px;
            height: 154px;
            background: url(../images/sys_shadow_1.png) no-repeat center center;
            bottom: -60px;
            left: 50%;
            margin-left: -585px;
            content: '';
        }
    }
    .wrapper {
        position: relative;
        z-index: 2;
        background-color: #fff;
        font-size: 0;
        .item {
            display: inline-block;
            vertical-align: top;
            width: 33.33%;
            height: 330px;
            box-sizing: border-box;
            padding: 60px 26px 0;
            border-right: 1px solid #f2f4f6;
            text-align: center;
            perspective: 800px;
            &:nth-child(2) {
                background-image: url(../images/system_bg_2.jpg);
                background-position: center center;
                background-repeat: repeat-x;
                background-size: auto 100%;
            }
            &:hover {
                .icon {
                    transform: rotateY(360deg);
                }
            }
        }
        .icon {
            display: block;
            width: 100px;
            height: 100px;
            margin: 0 auto 15px;
            border: 1px solid #bdd2e3;
            text-align: center;
            line-height: 100px;
            border-radius: 100%;
            color: #6899c2;
            background-color: #fff;
            font-size: 44px;
            transition: all .6s linear;
        }
        .t {
            font-size: 25px;
            color: #333;
            margin-bottom: 10px;
        }
        .p {
            font-size: 15px;
            line-height: 30px;
            color: #666;
        }
    }
    @media screen and (max-width: 1440px) {
        .img {
            width: 380px;
        }
        .info {
            width: 900px;
            left: 30px;
            margin-left: 0;
        }
        .top {
            font-size: 48px;
        }
    }
    @media screen and (max-width: 1366px) {
        height: 577px;
        .img {
            width: 280px;
        }
        .info {
            top: 50px;
        }
        .top {
            font-size: 36px;
        }
        .wrapper {
            .item {
                padding-top: 40px;
            }
            .icon {
                width: 80px;
                height: 80px;
                line-height: 80px;
                font-size: 32px;
            }
            .t {
                font-size: 20px;
            }
        }
    }
    @media screen and (max-width: 1280px) {
        .img {
            right: 30px;
        }
    }
}
.ele-section-2 {
    position: relative;
    width: 100%;
    height: 778px;
    background-image: url(../images/sys_bg_2.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    .info {
        position: absolute;
        top: 180px;
        left: 50%;
        margin-left: 50px;
        width: 656px;
        &.anim {
            .top,
            .box {
                transform: translateX(0);
                opacity: 1;
            }
        }
    }
    .top {
        font-size: 57px;
        color: #6899c2;
        margin-bottom: 15px;
        opacity: 0;
        transform: translateX(40px);
        transition: all .4s ease;
    }
    .box {
        border: 1px solid #e0e6ea;
        opacity: 0;
        transform: translateX(40px);
        transition: all .4s .2s ease;
        .item {
            padding: 40px;
            background-color: rgba(255, 255, 255, .7);
            font-size: 18px;
            line-height: 30px;
            color: #666;
            span {
                font-family: Arial;
                font-size: 30px;
                line-height: 30px;
                color: #6899c2;
                display: inline-block;
                vertical-align: top;
                margin-right: 3px;
            }
            &:nth-child(2n) {
                background-color: rgba(245, 245, 245, .7);
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .info {
            margin-left: 0;
        }
        .top {
            font-size: 48px;
        }
        .box .item {
            padding: 30px;
        }
    }
    @media screen and (max-width: 1366px) {
        height: 577px;
        .info {
            top: 50px;
            margin-left: -50px;
        }
        .top {
            font-size: 36px;
        }
    }
}
.ele-section-3 {
    position: relative;
    width: 100%;
    height: 741px;
    background-image: url(../images/sys_bg_3.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    .info {
        position: absolute;
        top: 160px;
        left: 50%;
        margin-left: -550px;
        width: 900px;
        &.anim {
            .top,
            .item {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }
    .top {
        font-size: 57px;
        color: #6899c2;
        margin-bottom: 15px;
        text-align: center;
        opacity: 0;
        transform: translateY(40px);
        transition: all .4s ease;
    }
    .box {
        font-size: 0;
        text-align: center;
    }
    .item {
        display: inline-block;
        vertical-align: top;
        width: 33.33%;
        opacity: 0;
        transform: translateY(40px);
        transition: all .4s ease;
        perspective: 800px;
        &:hover {
            .icon {
                transform: rotateY(360deg);
            }
        }
        &:nth-child(1) {
            transition-delay: .4s;
        }
        &:nth-child(2) {
            transition-delay: .5s;
        }
        &:nth-child(3) {
            transition-delay: .6s;
        }
    }
    .icon {
        width: 134px;
        height: 134px;
        border-radius: 100%;
        background-color: #fff;
        text-align: center;
        line-height: 134px;
        margin: 0 auto 25px;
        border: 1px solid #bdd2e3;
        color: #6899c2;
        font-size: 60px;
        transition: all .6s linear;
    }
    .t {
        font-size: 25px;
        color: #333;
    }
    .p {
        font-size: 16px;
        color: #666;
    }
    @media screen and (max-width: 1440px) {
        height: 630px;
        .info {
            top: 70px;
        }
        .top {
            font-size: 52px;
        }
    }
    @media screen and (max-width: 1366px) {
        height: 577px;
        .info {
            top: 100px;
        }
        .top {
            font-size: 38px;
        }
        .icon {
            width: 94px;
            height: 94px;
            line-height: 94px;
            font-size: 48px;
        }
        .t {
            font-size: 20px;
        }
    }
}
.ele-section-4 {
    position: relative;
    width: 100%;
    height: 827px;
    background-image: url(../images/sys_bg_4.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    .info {
        top: 125px;
        position: absolute;
        z-index: 2;
        margin-left: -270px;
        width: 1080px;
        font-size: 0;
        left: 50%;
        &.anim {
            .title {
                transform: translateY(0);
                opacity: 1;
            }
            .item {
                transform: translateX(0);
                opacity: 1;
            }
        }
    }
    .title {
        font-size: 57px;
        color: #6899c2;
        margin-bottom: 25px;
        opacity: 0;
        transform: translateY(40px);
        transition: all .4s ease;
    }
    .item {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        box-sizing: border-box;
        padding-right: 50px;
        margin-bottom: 30px;
        opacity: 0;
        transition: all .6s .4s ease;
        &:nth-child(2n+1) {
            transform: translateX(30px);
        }
        &:nth-child(2n) {
            transform: translateX(-30px);
        }
    }
    .top {
        line-height: 96px;
        font-size: 30px;
        color: #333;
        img {
            display: inline-block;
            vertical-align: top;
            margin-right: 15px;
        }
    }
    .p {
        padding-left: 20px;
        li {
            padding-left: 12px;
            position: relative;
            margin-top: 10px;
            font-size: 16px;
            line-height: 24px;
            color: #666;
            &:before {
                position: absolute;
                left: 0;
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background-color: #6899c2;
                display: block;
                content: '';
                top: 8px;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .title {
            font-size: 52px;
        }
        .info {
            width: auto;
        }
    }
    @media screen and (max-width: 1366px) {
        height: 700px;
        .info {
            top: 80px;
        }
        .title {
            font-size: 38px;
        }
        .top {
            line-height: 78px;
            font-size: 24px;
            img {
                height: 78px;
            }
        }
    }
}
.answer-section {
    font-size: 0;
    .item {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        text-align: center;
        background-color: #f5f5f5;
        position: relative;
        padding-bottom: (604/1920) * 100%;
        overflow: hidden;
        &:nth-child(2),
        &:nth-child(3) {
            background-color: #efefef;
        }
        &:nth-child(2n+1) {
            .wrapper {
                transform: translateX(-100%);
            }
        }
        &:nth-child(2n) {
            .wrapper {
                transform: translateX(100%);
            }
        }
        .wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            transition: all .6s ease-in;
            opacity: 0;
            &.anim {
                transform: translateX(0);
                opacity: 1;
            }
        }
        img {
            display: block;
            width: 100%;
        }
        .t {
            font-weight: bold;
            font-size: 50px;
            color: #269b48;
            margin-bottom: 10px;
        }
        .p {
            padding: 0 70px;
            font-size: 18px;
            line-height: 30px;
            color: #666;
            margin-bottom: 15px;
            min-height: 60px;
        }
        @media screen and (max-width: 1440px) {
            .t {
                font-size: 40px;
            }
            .p {
                font-size: 16px;
                line-height: 24px;
                min-height: 48px;
            }
        }
        @media screen and (max-width: 1280px) {
            .t {
                font-size: 30px;
            }
            .p {
                font-size: 14px;
                line-height: 22px;
                min-height: 44px;
            }
        }
    }
}
.active-section {
    font-size: 0;
    .item {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        text-align: center;
        background-color: #f5f5f5;
        position: relative;
        padding-bottom: (570/1920) * 100%;
        overflow: hidden;
        &:nth-child(2n+1) {
            .wrapper {
                transform: translateX(-100%);
            }
        }
        &:nth-child(2n) {
            .wrapper {
                transform: translateX(100%);
            }
        }
        &:nth-child(1) {
            background-color: #e9f0ff;
        }
        &:nth-child(2) {
            background-color: #f8fcff;
        }
        &:nth-child(3) {
            background-color: #f8fcff;
        }
        &:nth-child(4) {
            background-color: #d0e7fc;
        }
        .wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all .6s ease-in;
            opacity: 0;
            &.anim {
                transform: translateX(0);
                opacity: 1;
            }
        }
        img {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
        .t {
            text-align: center;
            top: 60px;
            left: 0;
            z-index: 2;
            font-size: 52px;
            font-weight: bold;
            color: #34a5b4;
            position: absolute;
            width: 100%;
        }
        .p {
            position: absolute;
            text-align: center;
            top: 145px;
            left: 0;
            width: 100%;
            font-size: 18px;
            line-height: 28px;
            color: #333;
            z-index: 2;
        }
        @media screen and (max-width: 1440px) {
            .t {
                top: 30px;
                font-size: 42px;
            }
            .p {
                top: 95px;
            }
        }
        @media screen and (max-width: 1366px) {
            .t {
                font-size: 32px;
            }
            .p {
                top: 80px;
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
}
.meeting-section-1 {
    position: relative;
    height: 673px;
    background-image: url(../images/meeting_1.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 3;
    background-size: cover;
    .img {
        position: absolute;
        top: 100px;
        left: 50%;
        margin-left: 83px;
        opacity: 0;
        transform: translateY(100px);
        transition: all .6s ease-in;
    }
    .info {
        position: absolute;
        top: 240px;
        left: 50%;
        margin-left: -490px;
    }
    .t {
        font-size: 60px;
        font-weight: bold;
        color: #47a4bf;
        margin-bottom: 15px;
        opacity: 0;
        transform: translateX(-20px);
        transition: all .6s .4s ease-in;
    }
    .p {
        font-size: 28px;
        line-height: 36px;
        color: #666;
        opacity: 0;
        transform: translateX(-20px);
        transition: all .6s .5s ease-in;
    }
    &.anim {
        .img {
            opacity: 1;
            transform: translateY(0);
        }
        .t,
        .p {
            transform: translateX(0);
            opacity: 1;
        }
    }
    @media screen and (max-width: 1440px) {
        .t {
            font-size: 50px;
        }
        .p {
            font-size: 24px;
            line-height: 32px;
        }
        .img {
            margin-left: 0;
            width: 658px;
            top: 140px;
        }
    }
    @media screen and (max-width: 1366px) {
        height: 609px;
        .img {
            width: 590px;
            top: 132px;
        }
        .t {
            font-size: 40px;
        }
        .p {
            font-size: 20px;
        }
    }
}
.meeting-section-2 {
    position: relative;
    height: 700px;
    background-image: url(../images/meeting_2.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 2;
    background-size: cover;
    .img {
        position: absolute;
        top: 70px;
        left: 50%;
        margin-left: -486px;
        opacity: 0;
        transform: translateX(-50px);
        transition: all .4s ease-in;
    }
    .info {
        position: absolute;
        top: 250px;
        left: 50%;
        margin-left: 5px;
    }
    .t {
        font-size: 60px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 15px;
        opacity: 0;
        transform: translateX(-50px);
        transition: all .4s .2s ease-in;
    }
    .p {
        font-size: 28px;
        line-height: 42px;
        color: #fff;
        opacity: 0;
        transform: translateX(-50px);
        transition: all .4s .4s ease-in;
    }
    &.anim {
        .img,
        .t,
        .p {
            transform: translateX(0);
            opacity: 1;
        }
    }
    @media screen and (max-width: 1440px) {
        .t {
            font-size: 50px;
        }
        .p {
            font-size: 24px;
            line-height: 32px;
        }
    }
    @media screen and (max-width: 1366px) {
        height: 610px;
        .img {
            width: 320px;
        }
        .info {
            top: 210px;
        }
        .t {
            font-size: 40px;
        }
        .p {
            font-size: 20px;
        }
    }
}
.meeting-section-3 {
    position: relative;
    height: 888px;
    background-image: url(../images/meeting_3.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    .img {
        position: absolute;
        top: 143px;
        left: 50%;
        margin-left: 198px;
        opacity: 0;
        transform: translateY(-50px);
        transition: all .4s ease-in;
    }
    .info {
        position: absolute;
        top: 370px;
        left: 50%;
        margin-left: -460px;
    }
    .t {
        font-size: 60px;
        font-weight: bold;
        color: #47a4bf;
        margin-bottom: 15px;
        opacity: 0;
        transform: translateY(-50px);
        transition: all .4s .2s ease-in;
    }
    .p {
        font-size: 28px;
        line-height: 42px;
        color: #666;
        opacity: 0;
        transform: translateY(-50px);
        transition: all .4s .4s ease-in;
    }
    &.anim {
        .img,
        .t,
        .p {
            transform: translateY(0);
            opacity: 1;
        }
    }
    @media screen and (max-width: 1440px) {
        .t {
            font-size: 50px;
        }
        .p {
            font-size: 24px;
            line-height: 32px;
        }
    }
    @media screen and (max-width: 1366px) {
        height: 755px;
        .img {
            width: 300px;
            margin-left: 140px;
        }
        .t {
            font-size: 40px;
        }
        .p {
            font-size: 20px;
        }
        .info {
            top: 320px;
        }
    }
}
.video-section-1 {
    position: relative;
    height: 752px;
    background-image: url(../images/sys_video_1.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    .info {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        backface-visibility: hidden;
        margin-left: -555px;
        left: 50%;
    }
    .t {
        font-size: 48px;
        font-weight: bold;
        color: #5198c4;
        margin-bottom: 15px;
        transform: translateX(-50px);
        opacity: 0;
        transition: all .6s ease;
    }
    .p {
        font-size: 32px;
        line-height: 44px;
        color: #555;
        transform: translateX(-50px);
        opacity: 0;
        transition: all .6s .4s ease;
    }
    &.anim {
        .t,
        .p {
            opacity: 1;
            transform: translateX(0);
        }
    }
    @media screen and (max-width: 1440px) {
        height: 675px;
        .t {
            font-size: 40px;
        }
        .p {
            font-size: 26px;
            line-height: 38px;
        }
    }
}
.video-section-2 {
    position: relative;
    height: 748px;
    background-image: url(../images/sys_video_3.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
    background-size: cover;
    .img {
        position: absolute;
        top: 144px;
        left: 50%;
        margin-left: -417px;
        transform: translateY(50px);
        opacity: 0;
        transition: all .6s ease;
    }
    .info {
        position: absolute;
        top: 220px;
        backface-visibility: hidden;
        margin-left: 150px;
        left: 50%;
    }
    .t {
        font-size: 48px;
        font-weight: bold;
        color: #3e94c9;
        margin-bottom: 15px;
        transform: translateX(-50px);
        opacity: 0;
        transition: all .6s ease;
    }
    .p {
        font-size: 32px;
        line-height: 44px;
        color: #fff;
        transform: translateX(-50px);
        opacity: 0;
        transition: all .6s .4s ease;
    }
    &.anim {
        .t,
        .p {
            opacity: 1;
            transform: translateX(0);
        }
        .img {
            opacity: 1;
            transform: translateY(0);
        }
    }
    @media screen and (max-width: 1440px) {
        height: 680px;
        .img {
            top: auto;
            bottom: 0;
        }
        .t {
            font-size: 40px;
        }
        .p {
            font-size: 26px;
            line-height: 38px;
        }
        .info {
            margin-left: 90px;
        }
    }
}
.video-section-3 {
    position: relative;
    height: 708px;
    background-image: url(../images/sys_video_4.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    .info {
        position: absolute;
        top: 220px;
        backface-visibility: hidden;
        margin-left: -530px;
        left: 50%;
    }
    .t {
        font-size: 48px;
        font-weight: bold;
        color: #5198c4;
        transition: all .6s ease;
        margin-bottom: 15px;
        transform: translateX(-50px);
        opacity: 0;
    }
    .p {
        font-size: 32px;
        line-height: 44px;
        color: #555;
        transform: translateX(-50px);
        opacity: 0;
        transition: all .6s .4s ease;
    }
    &.anim {
        .t,
        .p {
            opacity: 1;
            transform: translateX(0);
        }
    }
    @media screen and (max-width: 1440px) {
        height: 620px;
        .info {
            top: 160px;
        }
        .t {
            font-size: 40px;
        }
        .p {
            font-size: 26px;
            line-height: 38px;
        }
    }
}
.duty-section-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
    .info {
        position: relative;
        margin-right: -50px;
        z-index: 5;
    }
    .t {
        font-size: 60px;
        font-weight: bold;
        color: #49bfb1;
        margin-bottom: 15px;
        opacity: 0;
        transform: translateX(40px);
        transition: all .4s .2s ease;
    }
    .p {
        font-size: 30px;
        line-height: 48px;
        color: #333;
        opacity: 0;
        transform: translateX(40px);
        transition: all .4s .4s ease;
    }
    .img {
        opacity: 0;
        transform: translateX(40px);
        transition: all .4s ease;
    }
    &.anim {
        .t,
        .p,
        .img {
            opacity: 1;
            transform: translateX(0);
        }
    }
    @media screen and (max-width: 1440px) {
        .img {
            width: 600px;
        }
        .t {
            font-size: 48px;
        }
        .p {
            font-size: 26px;
        }
    }
    @media screen and (max-width: 1366px) {
        .img {
            width: 520px;
        }
        .t {
            font-size: 40px;
        }
        .p {
            font-size: 20px;
            line-height: 32px;
        }
    }
}
.duty-section-2 {
    background-color: #f4f5f5;
    text-align: center;
    padding: 80px 0 95px;
    overflow: hidden;
    .t {
        color: #49bfb1;
        font-size: 60px;
        font-weight: bold;
        margin-bottom: 15px;
        opacity: 0;
        transform: translateY(50px);
        transition: all .4s ease;
    }
    .p {
        font-size: 28px;
        line-height: 48px;
        color: #333;
        margin-bottom: 70px;
        opacity: 0;
        transform: translateY(50px);
        transition: all .4s .2s ease;
    }
    .img {
        opacity: 0;
        transform: translateY(50px);
        transition: all .4s .4s ease;
    }
    &.anim {
        .t,
        .p,
        .img {
            opacity: 1;
            transform: translateY(0);
        }
    }
    @media screen and (max-width: 1440px) {
        .t {
            font-size: 48px;
        }
        .p {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 1366px) {
        .img {
            width: 640px;
        }
        .t {
            font-size: 40px;
        }
        .p {
            font-size: 20px;
            line-height: 32px;
        }
    }
}
.duty-section-3 {
    background-color: #faf9f8;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 160px 0 125px;
    .img {
        display: block;
        margin-right: 140px;
        transform: translateX(50px);
        opacity: 0;
        transition: all .4s ease;
    }
    .t {
        font-size: 60px;
        color: #49bfb1;
        margin-bottom: 15px;
        font-weight: bold;
        transform: translateX(-50px);
        opacity: 0;
        transition: all .4s ease;
    }
    .p {
        font-size: 30px;
        line-height: 48px;
        color: #333;
        transform: translateX(-50px);
        opacity: 0;
        transition: all .4s .2s ease;
    }
    &.anim {
        .img,
        .t,
        .p {
            opacity: 1;
            transform: translateX(0);
        }
    }
    @media screen and (max-width: 1440px) {
        padding: 120px 0 85px;
        .t {
            font-size: 48px;
        }
        .p {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 1366px) {
        padding: 80px 0 55px;
        .t {
            font-size: 40px;
        }
        .p {
            font-size: 20px;
            line-height: 32px;
        }
    }
}
.bus-section-1 {
    position: relative;
    height: 688px;
    background-image: url(../images/sys_bus_2.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    &.anim {
        .img {
            transform: translateY(0);
            opacity: 1;
        }
        .t,
        .p {
            transform: translateX(0);
            opacity: 1;
        }
    }
    .img {
        position: absolute;
        top: 77px;
        left: 50%;
        margin-left: 130px;
        opacity: 0;
        transform: translateY(50px);
        transition: all .4s ease;
    }
    .info {
        position: absolute;
        top: 260px;
        margin-left: -525px;
        left: 50%;
    }
    .t {
        font-size: 60px;
        color: #577cbb;
        font-weight: bold;
        margin-bottom: 25px;
        opacity: 0;
        transform: translateX(50px);
        transition: all .4s .2s ease;
    }
    .p {
        font-size: 28px;
        line-height: 44px;
        color: #666;
        opacity: 0;
        transform: translateX(50px);
        transition: all .4s .4s ease;
    }
    @media screen and (max-width: 1366px) {
        height: 610px;
        .t {
            font-size: 40px;
        }
        .p {
            font-size: 20px;
            line-height: 36px;
        }
        .info {
            margin-left: -430px;
            top: 210px;
        }
    }
}
.bus-section-2 {
    position: relative;
    height: 696px;
    background-image: url(../images/sys_bus_3.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
    background-size: cover;
    &.anim {
        .t,
        .p {
            transform: translateY(0);
            opacity: 1;
        }
    }
    .info {
        position: absolute;
        top: 210px;
        margin-left: 60px;
        left: 50%;
    }
    .t {
        font-size: 60px;
        color: #fff;
        font-weight: bold;
        margin-bottom: 20px;
        opacity: 0;
        transform: translateY(50px);
        transition: all .4s ease;
    }
    .p {
        font-size: 28px;
        line-height: 44px;
        color: #fff;
        width: 530px;
        opacity: 0;
        transform: translateY(50px);
        transition: all .4s .2s ease;
    }
    @media screen and (max-width: 1366px) {
        height: 610px;
        .t {
            font-size: 40px;
        }
        .p {
            font-size: 20px;
            line-height: 32px;
            width: 420px;
        }
    }
}
.bus-section-3 {
    position: relative;
    height: 892px;
    background-image: url(../images/sys_bus_4.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
    background-size: cover;
    &.anim {
        .p,
        .t {
            opacity: 1;
            transform: translateY(0);
        }
    }
    .info {
        position: absolute;
        top: 270px;
        margin-left: -625px;
        left: 50%;
    }
    .t {
        font-size: 60px;
        color: #577cbb;
        font-weight: bold;
        margin-bottom: 20px;
        opacity: 0;
        transform: translateY(50px);
        transition: all .4s ease;
    }
    .p {
        font-size: 28px;
        line-height: 44px;
        color: #666;
        width: 560px;
        opacity: 0;
        transform: translateY(50px);
        transition: all .4s .2s ease;
    }
    @media screen and (max-width: 1366px) {
        height: 610px;
        .t {
            font-size: 40px;
        }
        .p {
            font-size: 20px;
            line-height: 32px;
            width: 420px;
        }
        .info {
            top: 170px;
            margin-left: -480px;
        }
    }
}
.build-section {
    position: relative;
    overflow: hidden;
    background-color: #f5faff;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 580px;
    .info,
    .img {
        position: absolute;
        opacity: 0;
        transition: all .4s ease-in;
        left: 50%;
    }
    .t {
        font-size: 45px;
        font-weight: bold;
        color: #2163c2;
        margin-bottom: 20px;
    }
    .p {
        font-size: 18px;
        line-height: 35px;
        color: #333;
    }
    @media screen and (max-width: 1366px) {
        .t {
            font-size: 36px;
        }
        .p {
            font-size: 16px;
            line-height: 30px;
        }
    }
    &:nth-child(1) {
        .info {
            top: 160px;
            margin-left: -600px;
            transform: translateX(-50px);
        }
        .img {
            top: 100px;
            margin-left: 55px;
            transform: translateX(50px);
        }
    }
    &:nth-child(2) {
        .info {
            top: 170px;
            margin-left: 10px;
            transform: translateX(50px);
        }
        .img {
            top: 88px;
            margin-left: -570px;
            transform: translateX(-50px);
        }
    }
    &:nth-child(3) {
        .info {
            top: 160px;
            margin-left: -600px;
            transform: translateX(-50px);
        }
        .img {
            top: 78px;
            margin-left: 90px;
            transform: translateX(50px);
        }
    }
    &:nth-child(4) {
        .info {
            top: 160px;
            margin-left: 10px;
            transform: translateX(50px);
        }
        .img {
            top: 110px;
            margin-left: -600px;
            transform: translateX(-50px);
        }
    }
    &.anim {
        .info,
        .img {
            opacity: 1;
            transform: translateX(0);
        }
    }
}
.training-section-1 {
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 800px;
    &.anim {
        .bg,
        .info {
            transform: translateX(0);
            opacity: 1;
        }
    }
    .bg {
        position: absolute;
        display: block;
        top: 58px;
        left: 50%;
        margin-left: -200px;
        opacity: 0;
        transform: translateX(50px);
        transition: all .6s ease;
    }
    .info {
        position: absolute;
        z-index: 2;
        top: 200px;
        left: 50%;
        margin-left: -600px;
        opacity: 0;
        transform: translateX(-50px);
        transition: all .6s ease;
    }
    .t {
        font-size: 60px;
        font-weight: bold;
        color: #215fb4;
        margin-bottom: 20px;
    }
    .p {
        font-size: 26px;
        line-height: 50px;
        color: #888;
    }
    @media screen and (max-width: 1440px) {
        height: 700px;
        .t {
            font-size: 50px;
        }
        .p {
            font-size: 24px;
            line-height: 44px;
        }
    }
    @media screen and (max-width: 1366px) {
        height: 600px;
        .info {
            margin-left: -500px;
        }
        .t {
            font-size: 40px;
        }
        .p {
            font-size: 20px;
            line-height: 38px;
        }
    }
}
.training-section-2 {
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 820px;
    &.anim {
        .bg,
        .info {
            transform: translate(0);
            opacity: 1;
        }
    }
    .bg {
        position: absolute;
        display: block;
        top: 110px;
        left: 50%;
        margin-left: -670px;
        opacity: 0;
        transform: translateY(50px);
        transition: all .6s ease;
    }
    .info {
        position: absolute;
        z-index: 2;
        top: 260px;
        left: 50%;
        margin-left: -40px;
        opacity: 0;
        transform: translateX(-50px);
        transition: all .6s ease;
    }
    .t {
        font-size: 60px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 20px;
    }
    .p {
        font-size: 26px;
        line-height: 50px;
        color: #fff;
    }
    @media screen and (max-width: 1440px) {
        height: 720px;
        .bg {
            width: 400px;
            top: 150px;
            margin-left: -600px;
        }
        .t {
            font-size: 50px;
        }
        .p {
            font-size: 24px;
            line-height: 44px;
        }
    }
    @media screen and (max-width: 1366px) {
        height: 600px;
        .info {
            top: 200px;
        }
        .bg {
            width: 350px;
        }
        .t {
            font-size: 40px;
        }
        .p {
            font-size: 20px;
            line-height: 38px;
        }
    }
}
.training-section-3 {
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 820px;
    &.anim {
        .bg,
        .info {
            transform: translate(0);
            opacity: 1;
        }
    }
    .bg {
        position: absolute;
        display: block;
        bottom: 0;
        left: 50%;
        margin-left: -345px;
        opacity: 0;
        transform: translateY(50px);
        transition: all .6s .4s ease;
    }
    .info {
        position: absolute;
        z-index: 2;
        top: 100px;
        left: 0;
        width: 100%;
        opacity: 0;
        transform: translateY(50px);
        transition: all .6s ease;
        text-align: center;
    }
    .t {
        font-size: 60px;
        font-weight: bold;
        color: #215fb4;
        margin-bottom: 20px;
    }
    .p {
        font-size: 26px;
        line-height: 50px;
        color: #888;
    }
    @media screen and (max-width: 1440px) {
        height: 720px;
        .bg {
            width: 600px;
            margin-left: -300px;
        }
        .t {
            font-size: 50px;
        }
        .p {
            font-size: 24px;
            line-height: 44px;
        }
    }
    @media screen and (max-width: 1366px) {
        height: 600px;
        .bg {
            width: 500px;
            margin-left: -250px;
        }
        .info {
            top: 80px;
        }
        .t {
            font-size: 40px;
        }
        .p {
            font-size: 20px;
            line-height: 38px;
        }
    }
}