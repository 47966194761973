.about-banner {
    width: 100%;
    min-width: 1250px;
    height: 920px;
    background: #fff none no-repeat center center;
    background-size: cover;
    overflow: hidden;
    padding-left: 160px;
    padding-top: 85px;
    box-sizing: border-box;
}
.about-img-win {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 10000;
    .shadow {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .8);
    }
    &-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 992px;
        height: 670px;
        background: #fff;
        box-sizing: border-box;
        @media screen and (max-width: 1460px) {
            width: 760px;
            height: 500px;
        }
        .close {
            z-index: 100;
            position: absolute;
            right: 24px;
            top: 24px;
            font-size: 30px;
            line-height: 30px;
            cursor: pointer;
            color: #333;
            transition: all .3s ease;
            transform: rotate(0deg);
            @media screen and (max-width: 1460px) {
                font-size: 26px;
                line-height: 26px;
            }
            &:hover {
                transform: rotate(180deg);
            }
            &:after {
                content: "\e678";
            }
        }
    }
    .swiper-left-btn {
        display: none;
        color: #454545;
        cursor: pointer;
        position: absolute;
        left: 10px;
        top: 50%;
        line-height: 60px;
        font-size: 60px;
        transform: rotate(180deg);
        @media screen and (max-width: 1460px) {
            line-height: 50px;
            font-size: 50px;
        }
        &:hover {
            opacity: .7;
        }
        &:before {
            content: "\e641";
        }
    }
    .swiper-right-btn {
        display: none;
        color: #454545;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 50%;
        line-height: 60px;
        font-size: 60px;
        @media screen and (max-width: 1460px) {
            line-height: 50px;
            font-size: 50px;
        }
        &:hover {
            opacity: .7;
        }
        &:before {
            content: "\e641";
        }
    }
    .swiper-ul {
        display: table;
        position: absolute;
        bottom: 0px;
        left: 0px;
        height: 76px;
        background: #eee;
        width: 100%;
        border-top: 1px solid #dddcdc;
        @media screen and (max-width: 1460px) {
            height: 60px;
        }
        .li {
            cursor: pointer;
            text-align: center;
            display: table-cell;
            font-size: 16px;
            color: #333;
            padding: 0 10px;
            span {
                display: block;
                line-height: 24px;
                position: relative;
                padding-top: 26px;
                @media screen and (max-width: 1460px) {
                    padding-top: 20px;
                    font-size: 14px;
                }
            }
            &.on {
                color: #da4949;
                font-weight: bold;
            }
            + .li {
                box-sizing: border-box;
                border-left: 1px solid #dddcdc;
            }
        }
    }
    &-swiper {
        position: relative;
        .li {
            display: block;
            width: 100%;
            &.on {
                .about-img-swiper {
                    display: block;
                    .swiper-slide {
                        &.swiper-slide-active {
                            .text {
                                bottom: 0px;
                                opacity: 1;
                            }
                        }
                    }
                }
                .iconfont {
                    display: inline-block;
                }
            }
        }
        .about-img-swiper {
            display: none;
            margin: 0 auto;
            width: 824px;
            overflow: hidden;
            padding-top: 70px;
            @media screen and (max-width: 1460px) {
                width: 600px;
                padding-top: 60px;
            }
            .swiper-slide {
                position: relative;
                width: 100%;
                box-sizing: border-box;
                height: 465px;
                @media screen and (max-width: 1460px) {
                    padding-top: 60px;
                    height: 338px;
                }
                img {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                }
                .text {
                    position: absolute;
                    left: 0px;
                    bottom: -65px;
                    height: 65px;
                    background: rgba(0, 0, 0, .58);
                    width: 100%;
                    color: #fff;
                    font-size: 16px;
                    opacity: 0;
                    transition: all .7s ease;
                    @media screen and (max-width: 1460px) {
                        height: 50px;
                        bottom: -50px;
                        font-size: 14px;
                    }
                    span {
                        padding: 0px 24px;
                        box-sizing: border-box;
                        display: block;
                        position: relative;
                        top: 50%;
                        left: 0;
                        transform: translate(0, -50%);
                    }
                }
            }
        }
    }
}
.about-banner-box {
    width: 100%;
    z-index: 10;
    position: relative;
    .left {
        z-index: 10;
        display: inline-block;
        vertical-align: top;
        margin-top: 70px;
        width: 144*4px;
        height: 144*4px;
        overflow: hidden;
        .item {
            position: relative;
            z-index: 10;
            float: left;
            width: 144px;
            height: 144px;
            overflow: hidden;
            .more-img {
                cursor: pointer;
                position: relative;
                width: 100%;
                height: 100%;
                background: #656565;
                text-align: center;
                color: #fff;
                font-size: 14px;
                transition: all .3s ease;
                > div {
                    position: relative;
                    top: 50%;
                    transform: translate(0, -50%);
                    transition: all .4s ease;
                }
                .iconfont {
                    display: inline-block;
                    padding-bottom: 10px;
                    line-height: 40px;
                    font-size: 40px;
                    transition: all .4s ease;
                    &:after {
                        content: "\e642";
                    }
                }
                p {
                    line-height: 16px;
                    height: 16px;
                    overflow: hidden;
                    opacity: 1;
                    transition: all .3s ease;
                }
                &:hover {
                    opacity: .8;
                    > div {
                        padding-top: 20px;
                    }
                    .iconfont {
                        font-size: 46px;
                        padding-bottom: 0px;
                        @media screen and (max-width: 1460px) {
                            font-size: 36px;
                        }
                    }
                    p {
                        opacity: 0;
                    }
                }
                @media screen and (max-width: 1460px) {
                    font-size: 12px;
                    .iconfont {
                        font-size: 30px;
                        line-height: 30px;
                        padding-bottom: 6px;
                    }
                }
            }
            .img1 {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 1;
                z-index: 10;
                transition: all .6s ease-out;
                filter: grayscale(0%);
                transform: rotateX(0deg) rotateY(0deg);
                backface-visibility: hidden;
                transform-style: preserve-3d;
            }
            .img2 {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: all .6s ease-out;
                transform: rotateY(180deg);
            }
            .img1-on {
                width: 100%;
                height: 100%;
                opacity: 0;
                transform: rotateY(180deg);
                backface-visibility: hidden;
                transform-style: preserve-3d;
            }
            .img2-on {
                width: 100%;
                height: 100%;
                opacity: 1;
                transform: rotateX(0deg) rotateY(0deg);
                z-index: 20;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: auto !important;
            }
        }
    }
    .right {
        position: absolute;
        top: 0;
        left: 144*3px;
        width: 100%;
        background: #fff;
        height: 720px;
        padding-left: 250px;
        box-sizing: border-box;
        padding-top: 150px;
        &-box {
            width: 670px;
        }
        .title {
            font-size: 53px;
            color: #383838;
            font-family: "SimSun";
        }
        .text {
            position: relative;
            margin-top: 20px;
            width: 100%;
            font-size: 13px;
            color: #777;
            line-height: 26px;
            overflow: hidden;
        }
        .text-iscroll {
            position: relative;
            top: 0;
            transition: all .4s ease;
        }
        .btn {
            font-size: 0;
            margin-top: 35px;
            > div {
                transition: all .3s ease;
                &:hover {
                    opacity: .3;
                }
            }
            &-left {
                cursor: pointer;
                margin-right: 6px;
                display: inline-block;
                vertical-align: top;
                width: 48px;
                height: 48px;
                background: url(../images/banner_about_icon_0.png) no-repeat;
            }
            &-right {
                cursor: pointer;
                display: inline-block;
                vertical-align: top;
                width: 48px;
                height: 48px;
                background: url(../images/banner_about_icon_1.png) no-repeat;
            }
        }
    }
    .banner-text {
        position: relative;
        width: 100%;
        height: 312px;
        overflow: hidden;
        box-sizing: border-box;
        padding-right: 20px;
        .text-iscroll {
            position: relative;
            width: 100%;
            left: 0;
            top: 0;
        }
        .iScrollIndicator {
            cursor: grab;
        }
    }
}
.TheBrand {
    display: none;
}
.coustmer {
    width: 100%;
    min-width: 1250px;
    background: #f8f8f8;
    padding-top: 140px;
    padding-bottom: 110px;
    &-box {
        position: relative;
        top: 0;
        margin: 0 auto;
        border-radius: 5px;
        max-width: 1440px;
        min-width: 1200px;
        height: 600px;
        box-sizing: border-box;
        transition: all .3s ease;
        &:hover {
            top: -5px;
        }
        &:after {
            content: "";
            position: absolute;
            bottom: -40px;
            left: 0;
            transform: scale(1.02);
            width: 100%;
            padding-bottom: (639/1461) * 100%;
            background: url(../images/banner_about_icon_2.png) no-repeat;
            background-size: 100% 100%;
        }
    }
    &-box2 {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 10;
        border: 1px solid #c9c9c9;
        border-right: 0px;
        padding-left: (445/1440) * 100%;
        box-sizing: border-box;
        overflow: hidden;
    }
    .left {
        top: 0;
        left: 0;
        position: absolute;
        height: 100%;
        width: (445/1440) * 100%;
        background: #a1a9d4;
        > div {
            position: relative;
            top: 50%;
            width: 100%;
            text-align: center;
            transform: translate(0, -50%);
        }
        .title {
            font-size: 60px;
            font-family: "SimSun";
            line-height: 60px;
            color: #fff;
            text-align: center;
        }
        .text1 {
            margin-top: 7px;
            text-align: center;
            line-height: 28px;
            color: #fff;
            font-size: 16px;
        }
        .text2 {
            margin-top: 15px;
            font-size: 22px;
            line-height: 30px;
            color: #fff;
            text-align: center;
        }
        .btn {
            display: inline-block;
            cursor: pointer;
            width: 300px;
            margin-top: 45px;
            border-radius: 22px;
            height: 44px;
            background: #fff;
            font-size: 16px;
            color: #666;
            line-height: 44px;
            text-align: center;
            text-transform: uppercase;
            transition: all .3s ease;
            &:hover {
                box-shadow: 0 0 35px rgba(0, 0, 0, .2);
            }
        }
    }
    .right {
        background: #fff;
        position: relative;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        font-size: 0;
        border-left: 1px solid #c7c7c7;
        .swiper-slide {
            .order {
                display: inline-block;
                vertical-align: top;
                background: #fff;
                height: 20%;
                width: 20%;
                box-sizing: border-box;
                border-right: 1px solid #c7c7c7;
                border-bottom: 1px solid #c7c7c7;
            }
            a {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                cursor: pointer;
                &:hover {
                    .text {
                        opacity: 1;
                    }
                    .text h5 {
                        opacity: 1;
                        margin-top: 0;
                    }
                }
            }
            .text {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                background: rgba(0, 0, 0, .6);
                transition: all .4s ease-out;
                display: flex;
                justify-content: center;
                align-items: center;
                h5 {
                    text-align: center;
                    padding: 0 15px;
                    opacity: 0;
                    margin-top: 40px;
                    color: #fff;
                    font-size: 18px;
                    transition-delay: .1s;
                    transition-duration: .4s;
                }
            }
            img {
                max-width: 100%;
                max-height: 100%;
                transition: all .4s ease-out;
            }
        }
    }
    .pagination-about {
        position: relative;
        z-index: 100;
        margin-top: 30px;
        text-align: center;
        span {
            margin: 0 5px;
            opacity: 1;
            background: #3d3d3d;
        }
        .swiper-pagination-bullet-active {
            background: #d72f2f;
        }
    }
}
.Thehonor {
    display: none;
}
.honor {
    position: relative;
    width: 100%;
    min-width: 1250px;
    height: 925px;
    padding-left: 920px;
    overflow: hidden;
    box-sizing: border-box;
    .left {
        left: 0;
        top: 0;
        position: absolute;
        width: 920px;
        height: 100%;
        background: #fff no-repeat bottom right;
        background-size: auto 100%;
    }
    .right {
        box-shadow: 2px 3px 57px rgba(0, 0, 0, .03);
        position: relative;
        width: 100%;
        height: 100%;
        background: #fff;
        &-banner {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }
        .swiper-btn {
            position: absolute;
            z-index: 10;
            top: 50%;
            margin-top: -37px;
            left: -37px;
            width: 37px;
            > div {
                cursor: pointer;
                width: 37px;
                height: 37px;
                transition: all .3s ease;
            }
            > div:hover {
                background: url(../images/banner_about_icon_4.png);
            }
        }
        .prev-btn {
            background: url(../images/banner_about_icon_3.png);
            transform: rotate(180deg);
        }
        .next-btn {
            border-top: 1px solid #7d7d7d;
            background: url(../images/banner_about_icon_3.png);
        }
        .swiper-button-disabled {
            background: url(../images/banner_about_icon_4.png);
        }
        .swiper-slide {
            position: relative;
        }
        .order {
            top: 0;
            left: 0;
            height: 100%;
            position: absolute;
            width: 33.33%;
            text-align: center;
            box-sizing: border-box;
            user-select: none;
            border-top: 1px solid #e8e6e1;
            border-bottom: 1px solid #e8e6e1;
            h4 {
                font-size: 63px;
                color: #b6a592;
                line-height: 63px;
                font-family: "DB";
            }
            h5 {
                margin-top: 25px;
                color: #444;
                font-size: 16px;
                line-height: 16px;
            }
            h6 {
                margin-top: 5px;
                font-family: "Arial";
                color: #b6a592;
                font-size: 12px;
            }
            &:first-child {
                border-left: 1px solid #e8e8e8;
            }
            &:nth-child(-n + 3) {
                padding-top: 280px;
                box-sizing: border-box;
                border-right: 1px solid #e8e8e8;
                transition: all .3s ease;
                &:hover {
                    z-index: 100;
                    box-shadow: 0 15px 30px rgba(0, 0, 0, .1);
                }
            }
            &:nth-child(n + 4) {
                padding-top: 520px;
                transition: all .3s ease;
                &:hover {
                    z-index: 100;
                    box-shadow: 0 15px 30px rgba(0, 0, 0, .1);
                }
            }
            &:nth-child(1) {
                background: #fff;
            }
            &:nth-child(2) {
                background: #f7f7f7;
            }
            &:nth-child(3) {
                background: #fff;
            }
            &:nth-child(2),
            &:nth-child(5) {
                left: 33.33%;
            }
            &:nth-child(3),
            &:nth-child(6) {
                left: 66.66%;
            }
        }
    }
    .title {
        position: absolute;
        left: 360px;
        top: 160px;
        h5 {
            font-size: 58px;
            line-height: 58px;
        }
        h6 {
            margin-top: 15px;
            font-size: 20px;
            line-height: 20px;
            color: #aa8f70;
            text-transform: uppercase;
        }
        a {
            cursor: pointer;
            margin-top: 22px;
            padding: 0 20px;
            border-radius: 4px;
            line-height: 40px;
            height: 40px;
            background: #b6a592;
            font-size: 14px;
            color: #fff;
            display: inline-block;
            transition: all .3s ease;
            &:hover {
                background: #a78f74;
            }
        }
    }
}
.service {
    padding-top: 90px;
    padding-bottom: 110px;
    max-width: 1440px;
    min-width: 1250px;
    margin: 0 auto;
    font-size: 0;
    &-box {
        width: 100%;
        min-width: 1250px;
        background: #fff;
    }
    &-box2 {
        width: 100%;
        padding-top: 60px;
    }
    .title {
        text-align: center;
        font-family: "SimSun";
        font-size: 55px;
        line-height: 55px;
        color: #000;
    }
    .left {
        display: inline-block;
        vertical-align: top;
        position: relative;
        width: (810/1440) * 100%;
        height: 550px;
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            transform: translate(0, 40%);
            background: url(../images/about_icon_3.png);
            background-size: 100% 100%;
            padding-bottom: (97/665) * 100%;
        }
        &-box {
            position: relative;
            z-index: 10;
            border-left: 1px solid #e0e0e0;
            border-top: 1px solid #e0e0e0;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
        }
        .item {
            cursor: pointer;
            position: relative;
            float: left;
            width: 25%;
            height: 50%;
            border-right: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
            box-sizing: border-box;
            text-align: center;
            transition: all .5s ease;
            &:nth-child(odd) {
                background: #fff;
            }
            &:nth-child(even) {
                background: #f8f8f8;
            }
            > div {
                position: relative;
                top: 55%;
                transform: translate(0, -50%);
            }
            h5 {
                font-size: 40px;
                line-height: 40px;
                color: #a1896d;
            }
            h6 {
                margin-top: 45px;
                font-size: 17px;
                color: #666;
                line-height: 17px;
            }
            .right {
                display: none;
            }
        }
        .item-on {
            z-index: 10;
            box-shadow: -11px 18px 29px rgba(0, 0, 0, .1);
        }
    }
    .right {
        display: inline-block;
        position: relative;
        top: 0;
        margin-left: (10/1440) * 100%;
        width: (620/1440) * 100%;
        height: 550px;
        transition: all .5s ease;
        &:hover {
            top: -10px;
            &:after {
                transform: translate(0, 50%);
            }
        }
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            transform: translate(0, 40%);
            background: url(../images/about_icon_3.png);
            background-size: 100% 100%;
            padding-bottom: (97/665) * 100%;
            transition: all .5s ease;
        }
        &-box {
            position: relative;
            z-index: 10;
            width: 100%;
            height: 100%;
            background: #7ec9c9;
            box-sizing: border-box;
            border: 1px solid #e0e0e0;
            padding-top: 135px;
            padding-left: (120/620) * 100%;
            padding-right: (110/620) * 100%;
            font-size: 14px;
            line-height: 30px;
            color: #fff;
            h5 {
                color: #fff;
                font-size: 36px;
                line-height: 36px;
                padding-bottom: 18px;
            }
            h6 {
                padding-bottom: 35px;
                font-size: 34px;
                color: #fff;
                line-height: 34px;
                font-family: "DB";
                text-transform: uppercase;
            }
            .text {
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 6;
                overflow: hidden;
                height: 6*30px;
            }
        }
    }
}
@keyframes mymove {
    0% {bottom: -70px;}
    50% {bottom: -60px;}
    100% {bottom: -70px;}
}
.contact-us {
    width: 100%;
    min-width: 1250px;
    overflow: hidden;
    height: 910px;
    background: url(../images/about_icon_2.png) no-repeat bottom center;
    background-size: cover;
    &-box {
        position: relative;
        height: 100%;
        margin: 0 auto;
        min-width: 1250px;
        max-width: 1440px;
        .contact-content {
            opacity: 0;
            z-index: 200;
            position: absolute;
            bottom: 222px;
            left: 90px;
            width: 690px;
            // height: 400px;
            background: rgba(255, 255, 255, .9);
            border-radius: 9px;
            padding: 60px 70px 60px 95px;
            box-sizing: border-box;
            font-size: 16px;
            line-height: 28px;
            color: #555;
            transition: all .8s ease;
            .address {
                margin-bottom: 40px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .other {
                z-index: -1;
                opacity: 0;
                text-align: center;
                position: absolute;
                left: -90px;
                height: 240px;
                width: 90px;
                top: 55%;
                transform: translate(0, -50%);
                transition: all .8s ease .4s;
                .pointer {
                    left: -50px;
                    bottom: -70px;
                    position: absolute;
                    width: 79px;
                    height: 114px;
                    background: url(../images/about_icon_1.png);
                    animation: mymove .8s infinite;
                }
                a {
                    // cursor: pointer;
                    display: block;
                    width: 100%;
                    height: 100%;
                    padding: 0 20px;
                    box-sizing: border-box;
                    background: #26af94;
                    transition: all .4s ease;
                    &:hover {
                        background: #1f907b;
                    }
                    > div {
                        font-weight: bold;
                        font-size: 30px;
                        line-height: 34px;
                        color: #fff;
                        position: relative;
                        top: 50%;
                        transform: translate(0, -50%);
                    }
                }
            }
            &-on {
                opacity: 1;
                bottom: 272px;
                .other {
                    opacity: 1;
                    top: 50%;
                }
            }
            .text {
                margin-top: 22px;
            }
            h5 {
                line-height: 38px;
                font-size: 32px;
                vertical-align: middle;
                span {
                    padding-left: 5px;
                    font-family: "Arial";
                    font-size: 38px;
                    line-height: 38px;
                    color: #1aa286;
                }
            }
        }
        .cloud1 {
            position: absolute;
            z-index: 100;
            top: 300px;
            left: -30%;
            width: 81px;
            height: 57px;
            background: url(../images/about_icon_4.png);
            &-on {
                animation: cloud1 24s linear infinite;
            }
        }
        @keyframes cloud1 {
            0% {left: 800px; opacity: 1;}
            40% {left: 120%; opacity: 1;}
            41% {left: 120%; opacity: 0;}
            42% {left: -30%; opacity: 0;}
            43% {left: -30%; opacity: 1;}
            100% {left: 800px; opacity: 1;}
        }
        .cloud2 {
            position: absolute;
            z-index: 100;
            top: 100px;
            left: -30%;
            width: 123px;
            height: 54px;
            background: url(../images/about_icon_5.png);
            &-on {
                animation: cloud2 24s linear infinite;
            }
        }
        @keyframes cloud2 {
            0% {left: 1200px; opacity: 1;}
            31% {left: 120%; opacity: 1;}
            32% {left: 120%; opacity: 0;}
            33% {left: -30%; opacity: 0;}
            34% {left: -30%; opacity: 1;}
            100% {left: 1200px; opacity: 1;}
        }
        .cloud3 {
            position: absolute;
            z-index: 100;
            top: 200px;
            left: 200px;
            width: 123px;
            height: 54px;
            background: url(../images/about_icon_5.png);
            &-on {
                animation: cloud3 38s linear infinite;
            }
        }
        @keyframes cloud3 {
            0% {left: 200px; opacity: 1;}
            70% {left: 120%; opacity: 1;}
            71% {left: 120%; opacity: 0;}
            72% {left: -30%; opacity: 0;}
            73% {left: -30%; opacity: 1;}
            100% {left: 200px; opacity: 1;}
        }
        .bike {
            opacity: 0;
            z-index: 300;
            position: absolute;
            bottom: 0;
            left: 550px;
            &-on {
                animation: bikeanimation 6s linear infinite;
            }
            > div {
                background: url(../images/about_icon_0.png);
                background-size: 100% 100%;
                width: 535px;
                height: 427px;
            }
        }
    }
}
.about-new {
    background: #f7f7f7;
    min-width: 1250px;
    width: 100%;
    padding-top: 90px;
    padding-bottom: 110px;
    .title {
        text-align: center;
        font-family: "SimSun";
        font-size: 55px;
        line-height: 55px;
        color: #000;
    }
    .title-ul {
        padding-top: 20px;
        width: 100%;
        text-align: center;
        li {
            display: inline-block;
            text-align: center;
            padding-left: 20px;
            padding-right: 12px;
            position: relative;
            span {
                font-size: 16px;
                line-height: 16px;
                cursor: pointer;
                color: #666;
                &:hover {
                    color: #b41515;
                }
            }
        }
        .on span {
            color: #b41515;
        }
        li + li {
            &:before {
                position: absolute;
                content: "";
                top: 50%;
                left: 0;
                width: 13px;
                height: 1px;
                background: #000;
            }
        }
    }
    .about-new-content {
        padding-top: 25px;
        position: relative;
        max-width: 1400px;
        min-width: 1250px;
        margin: 0 auto;
        font-size: 0;
        display: none;
        opacity: 0;
        .read-more {
            margin-top: 55px;
            width: 100%;
            a {
                border: 1px solid #eaeaea;
                box-sizing: border-box;
                margin: 0 auto;
                display: block;
                text-align: center;
                width: 365px;
                height: 55px;
                line-height: 55px;
                background: #fff;
                font-size: 15px;
                color: #666;
                transition: all .3s ease;
                &:hover {
                    background: #eaeaea;
                    color: #666;
                }
            }
        }
        .about-new-li-box {
            width: 25%;
            box-sizing: border-box;
            padding-left: 8px;
            padding-right: 8px;
            display: inline-block;
            vertical-align: top;
            &:nth-child(1) {
                margin-bottom: 40px;
                width: 100%;
                .about-new-li {
                    &:after {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        content: '';
                        width: 100%;
                        transform: translate(0, 30%);
                        padding-bottom: (120/1426) * 100%;
                        background: url(../images/new_icon_6.png) no-repeat;
                        background-size: 100% 100%;
                    }
                    .text {
                        padding-top: 85px;
                        padding-left: 155px;
                        @media screen and (max-width: 1480px) {
                            padding-top: 70px;
                        }
                        @media screen and (max-width: 1480px) {
                            padding-top: 50px;
                        }
                    }
                }
                .img {
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    width: 490px;
                    height: 285px;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100% !important;
                        display: block;
                        object-fit: cover;
                    }
                    @media screen and (max-width: 1480px) {
                        width: 430px;
                        height: 250px;
                    }
                    @media screen and (max-width: 1460px) {
                        width: 390px;
                        height: 226px;
                    }
                }
                .text {
                    position: relative;
                    width: 890px;
                    display: inline-block;
                    vertical-align: top;
                    .title-li {
                        font-size: 22px;
                        line-height: 22px;
                        color: #444;
                    }
                    .content {
                        -webkit-line-clamp: 3;
                        line-height: 28px;
                        max-height: 28*3px;
                        padding-right: 70px;
                        box-sizing: border-box;
                    }
                    .time {
                        text-align: center;
                        position: absolute;
                        padding-top: 50px;
                        left: 50px;
                        top: 105px;
                        font-size: 14px;
                        color: #333;
                        font-family: "Arial";
                        width: 60px;
                        &:after {
                            content: '';
                            position: absolute;
                            top: -20px;
                            right: -25px;
                            height: 116px;
                            width: 1px;
                            background: #ccc;
                        }
                        span {
                            color: #da4949;
                            position: absolute;
                            left: 50%;
                            top: 0;
                            transform: translate(-50%, 0);
                            font-size: 48px;
                            line-height: 48px;
                            font-family: "Vonnes Condensed";
                            &:before {
                                display: none;
                            }
                        }
                    }
                    @media screen and (max-width: 1480px) {
                        width: 775px;
                        .time {
                            top: 85px;
                        }
                    }
                    @media screen and (max-width: 1460px) {
                        width: 640px;
                        .time {
                            top: 65px;
                        }
                    }
                }
            }
        }
        .about-new-li {
            position: relative;
            display: block;
            width: 100%;
            min-height: 120px;
            transition: .3s ease-in-out;
            &:after {
                position: absolute;
                bottom: 0;
                left: 0;
                content: '';
                width: 100%;
                transform: translate(0, 40%);
                padding-bottom: (75/356) * 100%;
                background: url(../images/new_icon_5.png) no-repeat;
                background-size: 100% 100%;
            }
            &-a {
                border: 1px solid #eaeaea;
                position: relative;
                z-index: 10;
                width: 100%;
                height: 100%;
                padding: 20px;
                box-sizing: border-box;
                background-color: #fff;
            }
            &:hover {
                transform: translate(0px, -5px);
                .text .title-li {
                    color: #d12322;
                }
            }
            .img {
                width: 100%;
                img {
                    width: 100%;
                    height: auto !important;
                }
            }
            .text {
                padding: 40px 10px 0px;
                box-sizing: border-box;
                text-align: left;
                .title-li {
                    line-height: 19px;
                    font-size: 19px;
                    color: #000;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    transition: .3s ease-in-out;
                }
                .content {
                    margin-top: 14px;
                    font-size: 13px;
                    line-height: 26px;
                    max-height: 52px;
                    color: #888;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
                .time {
                    padding-top: 20px;
                    color: #888;
                    font-size: 12px;
                    font-family: "Arial";
                    span {
                        &:before {
                            content: '-';
                        }
                    }
                }
            }
        }
    }
}
@keyframes bikeanimation {
    0% {left: 550px; opacity: 1;}
    80% {left: 120%; opacity: 1;}
    85% {left: 120%; opacity: 0;}
    90% {left: 550px; opacity: 0;}
    100% {left: 550px; opacity: 1;}
}
@media screen and (max-width: 1600px) {
    .honor {
        padding-left: 600px;
    }
    .honor .title {
        left: 100px;
        top: 120px;
    }
    .honor .left {
        width: 600px;
    }
    .contact-us-box .contact-content {
        left: 150px;
    }
}
@media screen and (max-width: 1480px) {
    .coustmer-box:after {
        transform: scale(1);
    }
    .about-banner {
        padding-left: 80px;
    }
    .about-banner-box .right-box {
        width: 600px;
    }
}
@media screen and (max-width: 1460px) {
    .coustmer-box:after {
        transform: scale(1);
    }
    .about-banner {
        height: 700px;
        padding-left: 0px;
    }
    .about-banner-box .right .title {
        font-size: 40px;
    }
    .about-banner-box .right .text {
        margin-top: 10px;
    }
    .about-banner-box .right-box {
        width: 500px;
    }
    .about-banner-box .left {
        width: 400px;
        height: 400px;
        margin-left: 131px;
        margin-top: 75px;
    }
    .about-banner-box .left .item {
        width: 100px;
        height: 100px;
    }
    .about-banner-box .right {
        height: 550px;
        padding-top: 70px;
        padding-left: 180px;
    }
    .coustmer {
        padding-top: 80px;
        padding-bottom: 60px;
    }
    .coustmer-box {
        width: 1160px;
        height: 480px;
        min-width: 0;
        max-width: none;
    }
    .coustmer .left .title {
        font-size: 40px;
        line-height: 40px;
    }
    .coustmer .left .text2 {
        font-size: 22px;
        line-height: 30px;
    }
    .coustmer .left .btn {
        margin-top: 30px;
        height: 40px;
        line-height: 40px;
    }
    .honor {
        padding-left: 480px;
        height: 700px;
    }
    .honor .title h5 {
        font-size: 40px;
        line-height: 40px;
    }
    .honor .title h6 {
        font-size: 16px;
        line-height: 16px;
    }
    .honor .left {
        width: 480px;
    }
    .honor .right .order:nth-child(-n+3) {
        padding-top: 140px;
    }
    .honor .right .order:nth-child(n+4) {
        padding-top: 420px;
    }
    .honor .right .order h4 {
        font-size: 50px;
        line-height: 50px;
    }
    .honor .right .order h5 {
        margin-top: 15px;
    }
    .service {
        width: 1160px;
        min-width: 0;
        padding: 0;
        max-width: none;
        padding-top: 60px;
        padding-bottom: 80px;
    }
    .service .title {
        font-size: 40px;
        line-height: 40px;
    }
    .about-new .title {
        font-size: 40px;
        line-height: 40px;
    }
    .service .right-box {
        padding-top: 80px;
        padding-left: 10%;
        padding-right: 10%;
    }
    .service .left {
        height: 450px;
    }
    .service .right {
        height: 450px;
    }
    .service-box2 {
        padding-top: 40px;
    }
    .service .right-box h4 {
        font-size: 32px;
        line-height: 32px;
    }
    .service .right-box h5 {
        font-size: 28px;
        line-height: 28px;
        padding-bottom: 15px;
    }
    .about-new-ul {
        margin-left: -8px;
        margin-right: -8px;
    }
    .about-new {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .about-new .about-new-content {
        max-width: none;
        min-width: 0;
        padding-top: 35px;
        width: 1160px;
    }
    .about-new .about-new-content .about-new-li-box:nth-child(1) {
        margin-bottom: 20px;
    }
    .about-new .about-new-content .read-more {
        margin-top: 35px;
    }
    .contact-us {
        height: 690px;
    }
    .contact-us-box .contact-content {
        bottom: 60px;
        width: 620px;
        // height: 350px;
        padding: 60px 50px;
    }
    // .contact-us-box .contact-content-on {
    // 	bottom: 200px;
    // 	padding: 60px 38px 0px;
    // }
    .contact-us-box .contact-content .other {
        height: 170px;
        width: 60px;
        left: -60px;
    }
    .contact-us-box .contact-content .other .pointer {
        left: -60px;
        bottom: -80px;
    }
    .contact-us-box .contact-content .other a > div {
        font-size: 24px;
        line-height: 28px;
    }
    .contact-us-box .bike > div {
        width: 400px;
        height: 320px;
    }
    @keyframes mymove {
        0% {bottom: -80px;}
        50% {bottom: -70px;}
        100% {bottom: -80px;}
    }
    .contact-us-box .cloud1 {
        top: 150px;
    }
}
@media screen and (max-width: 1340px) {
    .coustmer-box:after {
        transform: scale(1.02);
    }
}