.moblie {
    position: relative;
    width: 100%;
    background: #eaf2fd;
    min-width: 1250px;
    &-fix {
        z-index: 600;
        position: absolute;
        right: 0px;
        top: 0px;
        width: 285px;
        height: 100%;
        box-shadow: 3px 4px 27px rgba(19, 96, 188, .4);
        &-on {
            position: fixed;
        }
        &-pob {
            position: absolute;
            bottom: 0px;
            top: auto;
        }
        > div {
            cursor: pointer;
            text-align: center;
            position: relative;
            width: 100%;
            height: 33.33%;
            transition: all .3s ease;
            &:hover {
                background: #206cbd;
            }
            > div {
                position: relative;
                top: 50%;
                transform: translate(0, -50%);
            }
            h5 {
                margin-top: 20px;
                font-size: 22px;
                color: #fff;
                line-height: 22px;
            }
            h6 {
                margin-top: 13px;
                font-size: 15px;
                color: #fff;
                font-family: "Arial";
                line-height: 15px;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
        &-one {
            background: #4da2ff;
        }
        &-two {
            background: #348ceb;
        }
        &-three {
            background: #2d7bcf;
        }
    }
    &-left {
        width: 100%;
        padding-right: 285px;
        box-sizing: border-box;
    }
    &-one {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 939px;
        background: url(../images/moblie_banner_0.png) no-repeat top right;
        background-size: cover;
        &-bottom {
            position: absolute;
            z-index: 50;
            bottom: 0px;
            left: 0px;
            padding-top: (180/1635) * 100%;
            width: 100%;
            background: url(../images/moblie_banner_1.png) no-repeat bottom center;
            background-size: auto 100%;
        }
        &-bottom2 {
            position: absolute;
            z-index: 20;
            bottom: 0px;
            left: 0px;
            padding-top: (170/1635) * 100%;
            width: 100%;
            background: url(../images/moblie_icon_1.png) left bottom;
            background-size: 100% 100%;
            transform: translate(0%, 50%);
            opacity: 0;
            transition: all 1s ease 1s;
        }
        &-bottom3 {
            position: absolute;
            z-index: 15;
            bottom: 0px;
            left: 0px;
            padding-top: (170/1635) * 100%;
            width: 100%;
            background: url(../images/moblie_icon_2.png) left bottom;
            background-size: 100% 100%;
            transform: translate(0%, 50%);
            opacity: 0;
            transition: all 1s ease .9s;
        }
        &-phone {
            position: absolute;
            z-index: 10;
            bottom: 0px;
            left: 50%;
            transform: translate(-50%, 50%);
            opacity: 0;
            width: 100%;
            height: 100%;
            background: url(../images/moblie_icon_5.png) no-repeat center bottom;
            transition: all 1s ease .8s;
        }
        &-phone1 {
            position: absolute;
            z-index: 17;
            bottom: 0px;
            left: 50%;
            transform: translate(-50%, 40%);
            opacity: 0;
            width: 100%;
            height: 100%;
            background: url(../images/moblie_icon_6.png) no-repeat center bottom;
            transition: all 1s ease .6s;
        }
        &-text {
            width: 100%;
            position: relative;
            z-index: 20;
            top: 110px;
            text-align: center;
            opacity: 0;
            transform: scale(1.2);
            transition: all 1s ease .6s;
            .h1 {
                font-size: 55px;
                line-height: 55px;
                color: #fff;
                font-weight: bold;
                padding-bottom: 50px;
            }
            .h2 {
                font-size: 22px;
                line-height: 22px;
                color: #fff;
                font-weight: bold;
                padding-bottom: 14px;
            }
            .h3 {
                font-size: 14px;
                line-height: 24px;
                color: #fff;
            }
            a {
                cursor: pointer;
                margin: 0 auto;
                margin-top: 35px;
                display: block;
                width: 230px;
                height: 40px;
                border-radius: 20px;
                border: 1px solid #fff;
                line-height: 40px;
                box-sizing: border-box;
                color: #fff;
                font-size: 15px;
                transition: all .3s ease;
                span {
                    position: relative;
                    left: 0px;
                    transition: all .3s ease;
                }
                &:hover {
                    span {
                        left: 5px;
                    }
                }
            }
        }
        &-on {
            .moblie-one-text {
                transform: scale(1);
                opacity: 1;
            }
            .moblie-one-bottom2,
            .moblie-one-bottom3 {
                transform: translate(0%, 0%);
                opacity: 1;
            }
            .moblie-one-phone1,
            .moblie-one-phone {
                transform: translate(-50%, 0%);
                opacity: 1;
            }
            .moblie-one-radius {
                opacity: 1;
                transform: translate(0, 0);
            }
        }
        &-radius {
            opacity: 0;
            transform: translate(0, -20%);
            transition: all .8s ease .4s;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            .radius-one {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 357px;
                height: 357px;
                margin-top: -500px;
                margin-left: -525px;
                background: url(../images/moblie_icon_16.png) no-repeat center center;
                animation: radiusAnimationOne 5s ease-in-out infinite;
            }
            .radius-two {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 137px;
                height: 136px;
                margin-top: -90px;
                margin-left: -228px;
                background: url(../images/moblie_icon_17.png) no-repeat center center;
                animation: radiusAnimationTwo 3s ease-in-out infinite;
            }
            .radius-three {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 89px;
                height: 89px;
                margin-top: -102px;
                margin-left: 382px;
                background: url(../images/moblie_icon_18.png) no-repeat center center;
                animation: radiusAnimationThree 4s ease-in-out infinite;
            }
            .radius-four {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 84px;
                height: 84px;
                margin-top: 114px;
                margin-left: -652px;
                background: url(../images/moblie_icon_19.png) no-repeat center center;
                animation: radiusAnimationFour 3.5s ease-in-out infinite;
            }
        }
        .mouse {
            position: absolute;
            top: (100/180) * 100%;
            left: 50%;
            transform: translate(-50%, 0);
            cursor: pointer;
            width: 30px;
            height: 48px;
            background: url(../images/moblie_icon_0.png) no-repeat;
            background-size: 100% 100%;
            transition: all .3s ease;
            &:hover {
                transform: translate(-50%, 20%);
            }
        }
    }
    &-two {
        position: relative;
        width: 100%;
        height: 715px;
        &-text {
            opacity: 0;
            transform: translate(-50%, 0);
            width: 475px;
            position: relative;
            left: (160/1635) * 100%;
            z-index: 20;
            top: 220px;
            text-align: left;
            transition: all .6s ease .4s;
            .h1 {
                font-size: 55px;
                line-height: 55px;
                color: #333;
                font-weight: bold;
                padding-bottom: 30px;
            }
            .h2 {
                font-size: 20px;
                line-height: 36px;
                color: #666;
                font-weight: bold;
                padding-bottom: 18px;
            }
            .h3 {
                font-size: 14px;
                line-height: 24px;
                color: #666;
            }
            a {
                cursor: pointer;
                margin-left: 10px;
                margin-top: 45px;
                display: block;
                width: 230px;
                height: 40px;
                border-radius: 20px;
                background: #186bc6;
                line-height: 40px;
                box-sizing: border-box;
                color: #fff;
                font-size: 15px;
                transition: all .3s ease;
                text-align: center;
                span {
                    position: relative;
                    left: 0px;
                    transition: all .3s ease;
                }
                &:hover {
                    span {
                        left: 5px;
                    }
                }
            }
        }
        &-img {
            z-index: 50;
            position: absolute;
            top: 130px;
            right: 100px;
            opacity: 0;
            transform: scale(0);
            transition: all .8s ease .2s;
            .radius-one {
                position: relative;
                width: 640px;
                height: 640px;
                background: rgba(24, 107, 198, .25);
                border-radius: 50%;
            }
            .radius-two {
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 460px;
                height: 460px;
                background: rgba(24, 107, 198, .25);
                border-radius: 50%;
            }
            .radius-three {
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 300px;
                height: 300px;
                background: rgba(24, 107, 198, .25);
                border-radius: 50%;
            }
        }
        .radius-img {
            opacity: 0;
            transform: scale(1.1);
            z-index: 100;
            position: absolute;
            top: 40px;
            right: 60px;
            transition: all .8s ease .8s;
            .theIcon {
                position: absolute;
                top: 172px;
                left: 40px;
            }
        }
        &-on {
            .moblie-two-text {
                opacity: 1;
                transform: translate(0, 0);
            }
            .radius-img,
            .moblie-two-img {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
    &-three {
        box-sizing: border-box;
        position: relative;
        padding-top: (438/1635) * 100%;
        z-index: 200;
        width: 100%;
        overflow: hidden;
        height: 1128px;
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            width: 100%;
            padding-bottom: (438/1635) * 100%;
            background: url(../images/moblie_icon_10.png);
            background-size: 100% 100%;
        }
        &-box {
            position: relative;
            width: 100%;
            height: 100%;
            background: url(../images/moblie_icon_11.png) no-repeat top left;
            background-size: 100% 100%;
            &-on {
                .moblie-three-phone {
                    opacity: 1;
                    transform: translate(0, 0);
                    .icon,
                    .icon1 {
                        transform: translate(0%, 0%);
                        opacity: 1;
                    }
                }
                .moblie-three-text {
                    opacity: 1;
                    transform: translate(0, 0);
                }
                .moblie-three-bottom {
                    opacity: 1;
                    transform: translate(0, 0);
                }
            }
        }
        &-phone {
            position: absolute;
            bottom: 0;
            left: (230/1635) * 100%;
            width: 454px;
            height: 689px;
            opacity: 0;
            transform: translate(0, 50%);
            transition: all .8s ease .4s;
            .icon-phone {
                z-index: 10;
                position: relative;
                width: 100%;
                height: 100%;
                background: url(../images/moblie_icon_12.png) no-repeat;
                background-size: 100% 100%;
            }
            .icon {
                position: absolute;
                top: -70px;
                right: 10px;
                width: 177px;
                height: 177px;
                background: url(../images/moblie_icon_14.png) no-repeat;
                background-size: 100% 100%;
                transform: translate(0%, 50%);
                transition: all .8s ease .6s;
            }
            .icon1 {
                transform: translate(0%, 50%);
                position: absolute;
                top: 257px;
                left: -100px;
                width: 304px;
                height: 304px;
                background: url(../images/moblie_icon_13.png) no-repeat;
                background-size: 100% 100%;
                transition: all .8s ease .6s;
            }
        }
        &-text {
            opacity: 0;
            transform: translate(50%, 0%);
            position: absolute;
            bottom: 250px;
            left: 800px;
            transition: all .8s ease .4s;
            h5 {
                font-size: 55px;
                line-height: 55px;
                color: #fff;
                font-weight: bold;
            }
            .text-content {
                margin-top: 35px;
                font-size: 20px;
                color: #fff;
                line-height: 36px;
                width: 465px;
                p {
                    font-weight: bold;
                }
                .text-case {
                    margin-top: 30px;
                    font-size: 14px;
                    color: #fff;
                    font-weight: normal;
                }
            }
            a {
                text-align: center;
                cursor: pointer;
                margin-top: 35px;
                display: block;
                width: 230px;
                height: 40px;
                border-radius: 20px;
                border: 1px solid #fff;
                line-height: 40px;
                box-sizing: border-box;
                color: #fff;
                font-size: 15px;
                transition: all .3s ease;
                span {
                    position: relative;
                    left: 0px;
                    transition: all .3s ease;
                }
                &:hover {
                    span {
                        left: 5px;
                    }
                }
            }
        }
        &-bottom {
            position: absolute;
            bottom: 0;
            right: 0;
            width: (742/1618) * 100%;
            opacity: 0;
            transform: translate(0%, 100%);
            transition: all .8s ease .6s;
            &-img {
                position: relative;
                width: 100%;
                padding-top: (264/742) * 100%;
                background: url(../images/moblie_icon_15.png) no-repeat top left;
                background-size: 100% 100%;
            }
        }
    }
}
@media screen and (max-width: 1730px) {
    .moblie-two-text {
        left: 20px;
    }
    .moblie-two-img {
        right: 50px;
    }
    .moblie-two .radius-img {
        right: 10px;
    }
}
@media screen and (max-width: 1550px) {
    .moblie-two-text {
        width: 450px;
        top: 300px;
    }
    .moblie-two-text .h1 {
        font-size: 36px;
        line-height: 36px;
        padding-bottom: 15px;
    }
    .moblie-two-text .h2 {
        font-size: 16px;
        line-height: 30px;
        padding-bottom: 5px;
    }
    .moblie-two-text a {
        margin-left: 5px;
        margin-top: 30px;
    }
    .moblie-two .radius-img {
        width: 650px;
        img {
            max-width: 100%;
        }
    }
    .moblie-two .radius-img .theIcon {
        width: 250px;
    }
    .moblie-two-img .radius-one {
        width: 450px;
        height: 450px;
    }
    .moblie-two-img .radius-two {
        width: 350px;
        height: 350px;
    }
    .moblie-two-img .radius-three {
        width: 250px;
        height: 250px;
    }
    .moblie-left {
        padding-right: 200px;
    }
    .moblie-fix {
        width: 200px;
    }
    .moblie-fix > div h5 {
        font-size: 20px;
        line-height: 20px;
    }
    .moblie-fix > div h6 {
        font-size: 12px;
        line-height: 12px;
    }
}
@media screen and (max-width: 1490px) {
    .moblie-three-phone {
        left: 100px;
    }
    .moblie-three-text {
        left: 600px;
    }
}
@media screen and (max-width: 1460px) {
    .moblie-left {
        padding-right: 180px;
    }
    .moblie-one-text {
        top: 95px;
        .h1 {
            font-size: 30px;
            line-height: 30px;
            padding-bottom: 20px;
        }
        .h2 {
            font-size: 18px;
            line-height: 18px;
            padding-bottom: 10px;
        }
        a {
            width: 200px;
            font-size: 14px;
            height: 35px;
            line-height: 35px;
            margin-top: 20px;
        }
    }
    .moblie-one {
        height: 800px;
    }
    .moblie-one-phone {
        background-size: 70%;
    }
    .moblie-one-phone1 {
        background-size: 70%;
    }
    .moblie-one-bottom3 {
        padding-top: 6%;
    }
    .moblie-one-bottom2 {
        padding-top: 6%;
    }
    .moblie-two {
        height: 600px;
    }
    .moblie-two-text {
        top: 200px;
        left: 50px;
        width: 430px;
    }
    .moblie-two .radius-img {
        width: 550px;
        right: 20px;
    }
    .moblie-two .radius-img .theIcon {
        width: 200px;
    }
    .moblie-two-text .h3 {
        font-size: 12px;
    }
    .moblie-two-text .h2 {
        font-size: 14px;
    }
    .moblie-two-text .h1 {
        font-size: 30px;
        line-height: 30px;
    }
    .moblie-two-text a {
        width: 200px;
        font-size: 14px;
    }
    .moblie-three:before {
        padding-bottom: 20%;
    }
    .moblie-three {
        height: 750px;
        padding-top: 20%;
    }
    .moblie-three-phone {
        width: 350px;
        height: 530px;
    }
    .moblie-three-phone .icon {
        width: 150px;
        height: 150px;
        top: -30px;
    }
    .moblie-three-phone .icon1 {
        width: 200px;
        height: 200px;
        left: -60px;
    }
    .moblie-three-bottom {
        width: 40%;
    }
    .moblie-three-text {
        bottom: 170px;
        left: 600px;
    }
    .moblie-three-text h5 {
        font-size: 30px;
        line-height: 30px;
    }
    .moblie-three-text .text-content {
        margin-top: 15px;
        font-size: 14px;
        line-height: 30px;
        width: 440px;
    }
    .moblie-three-text .text-content .text-case {
        margin-top: 10px;
    }
    .moblie-three-text a {
        margin-top: 20px;
        width: 170px;
        font-size: 14px;
        line-height: 35px;
        height: 35px;
    }
    .moblie-fix {
        width: 180px;
    }
    .moblie-fix img {
        width: 40px;
    }
    .moblie-fix > div h5 {
        margin-top: 10px;
        font-size: 16px;
        line-height: 16px;
    }
    .moblie-fix > div h6 {
        margin-top: 10px;
        font-size: 10px;
    }
}