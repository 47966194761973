.customizing {
    &-banner {
        overflow: hidden;
        position: relative;
        width: 100%;
        min-width: 1250px;
        height: 852px;
        background: #fff none no-repeat center center;
        background-image: url(../images/customizing_icon_0.png);
        background-size: 100% 100%;
        background-position-y: -20px;
        opacity: 0;
        transition: all .6s ease;
        &-text {
            position: absolute;
            top: 120px;
            left: 200px;
            opacity: 0;
            transition: all .6s ease .3s;
            h5 {
                font-size: 36px;
                line-height: 36px;
                color: #fff;
                font-weight: bold;
            }
            h6 {
                margin-top: 10px;
                font-size: 14px;
                font-family: "Arial";
                line-height: 20px;
                margin-bottom: 30px;
                color: rgba(255, 255, 255, .8);
                text-transform: uppercase;
                &:first-letter {
                    font-size: 20px;
                }
            }
            .text {
                width: 600px;
                font-size: 14px;
                line-height: 30px;
                color: #fff;
                li {
                    opacity: 0;
                    position: relative;
                    padding-left: 25px;
                    box-sizing: border-box;
                    margin-bottom: 15px;
                    left: -60px;
                    &:nth-child(1) {
                        transition: all .5s ease .4s;
                    }
                    &:nth-child(2) {
                        transition: all .5s ease .6s;
                    }
                    &:nth-child(3) {
                        transition: all .5s ease .8s;
                    }
                    &:nth-child(4) {
                        transition: all .5s ease 1s;
                    }
                    &:nth-child(5) {
                        transition: all .5s ease 1.2s;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        left: 0px;
                        top: 10px;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background: #fff;
                    }
                }
            }
        }
        &-img {
            position: absolute;
            bottom: 0px;
            right: 0px;
            width: 858px;
            height: 666px;
            transition: all .4s ease-out;
            &:hover {
                bottom: 10px;
            }
            &-box {
                width: 100%;
                height: 100%;
                position: absolute;
                right: -200px;
                opacity: 0;
                background: url(../images/customizing_icon_1.png);
                background-size: 100% 100%;
                transition: all .7s ease-out 1s;
            }
        }
        &-on {
            opacity: 1;
            background-position-y: 0px;
            .customizing-banner-text {
                opacity: 1;
                top: 145px;
                .text {
                    li {
                        opacity: 1;
                        left: 0px;
                    }
                }
            }
            .customizing-banner-img-box {
                right: 0;
                opacity: 1;
            }
        }
    }
    &-two {
        position: relative;
        width: 100%;
        min-width: 1250px;
        height: 655px;
        background: url(../images/customizing_icon_2.png) #fff no-repeat bottom right;
        background-size: (767/1920) * 100%;
        padding-top: 70px;
        /* .swiper-slide {
            opacity: 0 !important;
        }
        .swiper-slide-active {
            opacity: 1 !important;
        } */
        .swiper-pagination-bullets {
            z-index: 10;
            position: absolute;
            bottom: 70px;
            left: 756px;
        }
        .swiper-pagination-bullet {
            width: 23px;
            height: 23px;
            background: #7d7d7d;
            opacity: 1;
            font-family: "Arial";
            font-size: 14px;
            line-height: 23px;
            text-align: center;
            color: #fff;
            transition: all .3s ease;
        }
        .swiper-pagination-bullet-active {
            background: #7b6ae7;
        }
        &-box1 {
            position: relative;
            overflow: hidden;
            max-width: 1400px;
            min-width: 1250px;
            margin: 0 auto;
            &-on {
                .customizing-two-box {
                    .img {
                        opacity: 1;
                        left: 0px;
                    }
                    .text {
                        .h1,
                        .h2 {
                            opacity: 1;
                            padding-top: 0px;
                        }
                    }
                }
                .swiper-slide-active {
                    .customizing-two-box {
                        .text {
                            .h3,
                            .text-content {
                                opacity: 1;
                                padding-top: 0px;
                            }
                        }
                    }
                }
            }
        }
        &-box {
            box-sizing: border-box;
            .img {
                opacity: 0;
                left: -45px;
                transition: all .5s ease .3s;
                position: relative;
                display: inline-block;
                vertical-align: top;
                width: 626px;
                height: 506px;
                img {
                    max-width: 100%;
                    position: relative;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .text {
                margin-left: 130px;
                position: relative;
                display: inline-block;
                vertical-align: top;
                width: 450px;
                margin-top: 120px;
                .h1 {
                    opacity: 0;
                    padding-top: 5px;
                    font-size: 36px;
                    line-height: 36px;
                    font-weight: bold;
                    color: #1a1a1a;
                    transition: all .5s ease .3s;
                }
                .h2 {
                    opacity: 0;
                    padding-top: 5px;
                    text-transform: uppercase;
                    font-size: 12px;
                    margin-top: 10px;
                    color: rgba(0, 0, 0, .42);
                    transition: all .5s ease .4s;
                    &:first-letter {
                        font-size: 16px;
                    }
                }
                .h3 {
                    opacity: 0;
                    padding-top: 5px;
                    margin-top: 30px;
                    color: #333;
                    line-height: 26px;
                    font-size: 20px;
                    font-weight: bold;
                    transition: all .5s ease .5s;
                }
                .text-content {
                    opacity: 0;
                    padding-top: 5px;
                    transition: all .5s ease .6s;
                    margin-top: 20px;
                    color: #666;
                    font-size: 14px;
                    line-height: 30px;
                    height: 30*3px;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
                    -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
                    -webkit-line-clamp: 3; /** 显示的行数 **/
                    overflow: hidden; /** 隐藏超出的内容 **/
                    p {
                        word-wrap: break-word;
                    }
                }
            }
        }
    }
    &-two-btn {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0%);
        width: 178px;
        height: 59px;
        background: url(../images/customizing_icon_3.png) no-repeat;
        background-size: 100% 100%;
    }
    &-three {
        position: relative;
        width: 100%;
        padding-top: 75px;
        box-sizing: border-box;
        min-width: 1250px;
        height: 760px;
        background: #f4f8ff;
        font-size: 0;
        .customizing-three-pagination {
            text-align: center;
            z-index: 10;
            margin-top: 110px;
        }
        .swiper-pagination-bullet {
            margin: 0 10px;
            width: 23px;
            height: 23px;
            background: #7d7d7d;
            opacity: 1;
            font-family: "Arial";
            font-size: 14px;
            line-height: 23px;
            text-align: center;
            color: #fff;
            transition: all .3s ease;
        }
        .swiper-pagination-bullet-active {
            background: #7b6ae7;
        }
        &-btn {
            cursor: pointer;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0%);
            width: 178px;
            height: 59px;
            background: url(../images/customizing_icon_4.png) no-repeat;
            background-size: 100% 100%;
        }
        &-box {
            position: relative;
            max-width: 1600px;
            min-width: 1250px;
            overflow: hidden;
            margin: 0 auto;
        }
        &-swiper {
            overflow: hidden;
            padding-top: 120px;
            box-sizing: border-box;
            width: 100%;
        }
        .swiper-slide {
            position: relative;
            height: 225px;
            width: 33.33%;
            display: inline-block;
            vertical-align: top;
            padding: 0 70px;
            box-sizing: border-box;
            &-box {
                position: relative;
                width: 100%;
                height: 100%;
                padding-left: 75px;
                box-sizing: border-box;
                transition: all .3s ease;
                &:hover {
                    transform: translate(0px, -5px);
                    .swiper-num,
                    .swiper-btn {
                        opacity: .7;
                    }
                }
            }
        }
        .swiper-num {
            position: absolute;
            top: 0;
            left: 0;
            width: 53px;
            height: 53px;
            border-radius: 50%;
            background: #7b6ae7;
            font-family: "Impact";
            font-size: 22px;
            line-height: 53px;
            text-align: center;
            color: #fff;
            transition: all .3s ease;
        }
        .swiper-text {
            width: 100%;
            .swiper-title {
                margin-top: 5px;
                font-size: 26px;
                line-height: 26px;
                font-weight: bold;
                color: #3f536b;
            }
            .swiper-en {
                margin-top: 10px;
                font-size: 14px;
                color: #8693a2;
                line-height: 14px;
            }
            .swiper-content {
                margin-top: 15px;
                color: #666;
                font-size: 14px;
                line-height: 30px;
                height: 90px;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
                -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
                -webkit-line-clamp: 3; /** 显示的行数 **/
                overflow: hidden; /** 隐藏超出的内容 **/
            }
            .swiper-btn {
                width: 115px;
                height: 30px;
                background: #7b6ae7;
                border-radius: 5px;
                margin-top: 20px;
                font-size: 14px;
                line-height: 30px;
                color: #fff;
                text-align: center;
                transition: all .3s ease;
            }
        }
        .title {
            h5 {
                text-align: center;
                font-size: 40px;
                line-height: 40px;
                font-weight: bold;
                color: #1a1a1a;
            }
            h6 {
                text-align: center;
                text-transform: uppercase;
                font-size: 12px;
                margin-top: 10px;
                color: rgba(0, 0, 0, .42);
                &:first-letter {
                    font-size: 16px;
                }
            }
        }
    }
    &-four {
        padding-top: 75px;
        box-sizing: border-box;
        font-size: 0;
        position: relative;
        width: 100%;
        min-width: 1250px;
        height: 760px;
        background: url(../images/customizing_icon_5.png) no-repeat bottom center;
        background-size: 100%;
        background-color: #3b3141;
        .title {
            text-align: center;
            h5 {
                font-size: 40px;
                font-weight: bold;
                line-height: 40px;
                color: #fff;
            }
            h6 {
                margin-top: 15px;
                font-size: 14px;
                line-height: 14px;
                color: rgba(255, 255, 255, .5);
            }
        }
        &-box {
            position: relative;
            padding-top: 45px;
            max-width: 1440px;
            min-width: 1250px;
            box-sizing: border-box;
            margin: 0 auto;
        }
        &-swiper {
            overflow: hidden;
            position: relative;
            width: 100%;
        }
        &-line-box {
            height: 176px;
            position: relative;
            max-width: 1300px;
            min-width: 1250px;
            margin: 0 auto;
            overflow: hidden;
        }
        &-line {
            position: absolute;
            bottom: 0px;
            left: 0%;
            font-size: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .change-line {
                display: inline-block;
                vertical-align: bottom;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .line {
                display: inline-block;
                vertical-align: bottom;
                margin-left: 30px;
                width: 2px;
                height: 16px;
                background: #d4e5f9;
                &:nth-child(17) {
                    margin-left: 30 * 7px;
                }
                &-one {
                    height: 30px;
                    background: #13b5b1;
                }
            }
        }
        .swiper-slide {
            cursor: pointer;
            transition: all .4s ease;
            padding-top: 125px;
            padding-left: 70px;
            box-sizing: border-box;
            display: inline-block;
            vertical-align: top;
            width: 33.33%;
            .img {
                transition: all .4s ease;
                position: absolute;
                top: -30px;
                left: 40px;
                background: #cfccd2;
                border-radius: 4px;
                width: 84px;
                height: 84px;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .swiper-slide-box {
                transition: all .4s ease;
                padding-top: 75px;
                padding-left: 40px;
                box-sizing: border-box;
                position: relative;
                width: 100%;
                height: 270px;
                background: #322b38;
                h5 {
                    height: 25px;
                    transition: all .4s ease;
                    width: 310px;
                    font-size: 20px;
                    color: #999;
                    font-weight: bold;
                    line-height: 20px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .text {
                    transition: all .4s ease;
                    height: 90px;
                    margin-top: 20px;
                    width: 310px;
                    color: #999;
                    font-size: 14px;
                    line-height: 30px;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
                    -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
                    -webkit-line-clamp: 3; /** 显示的行数 **/
                    overflow: hidden; /** 隐藏超出的内容 **/
                }
            }
        }
        .swiper-slide-active {
            cursor: auto;
            padding-left: 0;
            position: relative;
            padding-top: 50px;
            box-sizing: border-box;
            .img {
                top: -45px;
                left: 45px;
                background: #e6f0fb;
                width: 100px;
                height: 100px;
                overflow: hidden;
            }
            .swiper-slide-box {
                padding-top: 100px;
                padding-left: 40px;
                box-sizing: border-box;
                position: relative;
                width: 100%;
                height: 330px;
                background: #fff;
                h5 {
                    width: 340px;
                    color: #333;
                }
                .text {
                    width: 340px;
                    color: #666;
                }
            }
        }
        &-pb {
            z-index: 50;
            bottom: 30px;
            position: absolute;
            left: 320px;
            font-size: 0;
            width: 100px;
            > div {
                display: inline-block;
                width: 50%;
                height: 40px;
                cursor: pointer;
                &:hover {
                    background-image: url(../images/customizing_icon_11.png);
                }
            }
            .swiper-button-disabled {
                background-image: url(../images/customizing_icon_11.png);
            }
        }
        &-prev {
            background: url(../images/customizing_icon_12.png) no-repeat center left;
        }
        &-next {
            background: url(../images/customizing_icon_12.png) no-repeat center left;
            transform: rotate(180deg);
        }
    }
    &-four-btn {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0%);
        width: 178px;
        height: 59px;
        background: url(../images/customizing_icon_6.png) no-repeat;
        background-size: 100% 100%;
    }
    &-five {
        position: relative;
        width: 100%;
        min-width: 1250px;
        height: 820px;
        background: url(../images/customizing_icon_10.png) #fff no-repeat top left;
        background-color: #fff;
        background-size: (665/1920) * 100%;
        .customizing-five-box {
            .customizing-five-swiper {
                overflow: hidden;
                width: 100%;
                &-on {
                    .img {
                        left: 0px;
                        opacity: 1;
                    }
                    .text {
                        .h1,
                        .h2 {
                            opacity: 1;
                            padding-top: 0px;
                        }
                    }
                }
                &-on .swiper-slide-active {
                    .text {
                        .h3,
                        .text-content {
                            opacity: 1;
                            padding-top: 0px;
                        }
                    }
                }
            }
        }
        .swiper-slide {
            opacity: 0 !important;
        }
        .customizing-five-pagination {
            z-index: 100;
            position: absolute;
            bottom: 223px;
        }
        .swiper-slide-active {
            opacity: 1 !important;
        }
        .swiper-pagination-bullet {
            cursor: pointer;
            margin: 0 10px;
            width: 23px;
            height: 23px;
            background: #7d7d7d;
            opacity: 1;
            font-family: "Microsoft YaHei";
            font-size: 14px;
            line-height: 23px;
            text-align: center;
            color: #fff;
            transition: all .3s ease;
            overflow: hidden;
            padding-left: 8px;
            border-radius: 11.5px;
            box-sizing: border-box;
            em {
                font-style: normal;
                padding-left: 7px;
                padding-right: 20px;
            }
        }
        .swiper-pagination-bullet-active {
            background: #7b6ae7;
            width: auto;
            em {
                padding-left: 5px;
            }
        }
        &-box {
            padding-top: 200px;
            box-sizing: border-box;
            max-width: 1400px;
            min-width: 1250px;
            margin: 0 auto;
            font-size: 0;
            .text {
                padding-right: (110/1400) * 100%;
                padding-top: 65px;
                box-sizing: border-box;
                display: inline-block;
                vertical-align: top;
                width: 50%;
                .h1 {
                    opacity: 0;
                    padding-top: 5px;
                    font-size: 40px;
                    font-weight: bold;
                    color: #1a1a1a;
                    line-height: 40px;
                    transition: all .5s ease .4s;
                }
                .h2 {
                    opacity: 0;
                    padding-top: 5px;
                    margin-top: 15px;
                    font-size: 14px;
                    color: rgba(0, 0, 0, .42);
                    line-height: 14px;
                    letter-spacing: 1px;
                    transition: all .5s ease .6s;
                }
                .h3 {
                    opacity: 0;
                    padding-top: 5px;
                    margin-top: 40px;
                    letter-spacing: 2px;
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 30px;
                    color: #333;
                    transition: all .5s ease .8s;
                    img {
                        width: 30px;
                    }
                    span {
                        padding-left: 20px;
                    }
                }
                .text-content {
                    opacity: 0;
                    padding-top: 5px;
                    margin-top: 10px;
                    width: 500px;
                    height: 90px;
                    font-size: 14px;
                    color: #666;
                    line-height: 30px;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
                    -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
                    -webkit-line-clamp: 3; /** 显示的行数 **/
                    overflow: hidden; /** 隐藏超出的内容 **/
                    transition: all .5s ease 1s;
                }
            }
            .img {
                transition: all .8s ease .5s;
                left: 100px;
                opacity: 0;
                position: relative;
                display: inline-block;
                vertical-align: top;
                width: 50%;
                text-align: center;
                img {
                    max-width: 100%;
                }
            }
        }
    }
    &-six {
        position: relative;
        width: 100%;
        min-width: 1250px;
        height: 760px;
        background: url(../images/customizing_icon_15.png) top left;
        background-size: auto 100%;
        &-box {
            position: relative;
            width: 100%;
            height: 100%;
            background: url(../images/customizing_icon_14.png) no-repeat top left;
            background-size: auto 100%;
            overflow: hidden;
            box-sizing: border-box;
            padding-left: (260px);
        }
        &-box2 {
            margin-top: 80px;
            height: 370px;
            font-size: 0px;
            white-space: nowrap;
            overflow: hidden;
        }
        &-swiper {
            position: relative;
            left: 0px;
            height: 100%;
        }
        &-pb {
            position: relative;
            margin-top: 10px;
            z-index: 50;
            font-size: 0;
            width: 100px;
            > div {
                display: inline-block;
                width: 50%;
                height: 40px;
                cursor: pointer;
                &:hover {
                    background-image: url(../images/customizing_icon_11.png);
                }
            }
            .swiper-button-disabled {
                background-image: url(../images/customizing_icon_11.png);
            }
        }
        &-prev {
            background: url(../images/customizing_icon_12.png) no-repeat center left;
        }
        &-next {
            background: url(../images/customizing_icon_12.png) no-repeat center left;
            transform: rotate(180deg);
        }
        &-content {
            position: relative;
            cursor: pointer;
            white-space: normal;
            margin-right: 90px;
            display: inline-block;
            vertical-align: top;
            overflow: hidden;
            border-radius: 5px;
            height: 100%;
            width: 595px;
            padding-top: 77px;
            padding-left: 50px;
            padding-right: 42px;
            box-sizing: border-box;
            background: url(../images/customizing_icon_20.png);
            background-size: 100% 100%;
            transition: all .5s ease;
            .img {
                display: inline-block;
                vertical-align: top;
                width: 40px;
                height: 40px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .content-title {
                display: inline-block;
                vertical-align: top;
                margin-left: 15px;
                h5 {
                    font-size: 20px;
                    line-height: 20px;
                    font-weight: bold;
                    color: #333;
                    opacity: .8;
                    transition: all .5s ease;
                }
                h6 {
                    margin-top: 7px;
                    font-size: 14px;
                    line-height: 14px;
                    color: #999;
                }
            }
            .text {
                transition: all .5s ease;
                position: absolute;
                top: 170px;
                left: 120px;
                letter-spacing: 1px;
                width: 360px;
                font-size: 14px;
                color: #999;
                line-height: 30px;
                height: 120px;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
                -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
                -webkit-line-clamp: 4; /** 显示的行数 **/
                overflow: hidden;
            }
        }
        .on {
            background-image: url(../images/customizing_icon_19.png);
            cursor: auto;
            h5 {
                opacity: 1;
            }
            .text {
                color: #666;
            }
        }
        .title {
            margin-top: 100px;
            h5 {
                font-size: 40px;
                font-weight: bold;
                color: #1a1a1a;
                line-height: 40px;
            }
            h6 {
                margin-top: 15px;
                font-size: 16px;
                line-height: 16px;
                color: rgba(0, 0, 0, .42);
            }
        }
    }
    &-seven {
        width: 100%;
        min-width: 1250px;
        height: 900px;
        padding-top: 75px;
        box-sizing: border-box;
        background: url(../images/customizing_icon_22.png) #fff no-repeat bottom left;
        background-size: 100%;
        .title {
            h5 {
                text-align: center;
                font-size: 40px;
                line-height: 40px;
                font-weight: bold;
                color: #1a1a1a;
            }
            h6 {
                letter-spacing: 1px;
                text-align: center;
                text-transform: uppercase;
                font-size: 16px;
                margin-top: 10px;
                color: rgba(0, 0, 0, .42);
            }
        }
        &-box {
            height: 360px;
            max-width: 1400px;
            min-width: 1250px;
            margin: 0 auto;
            margin-top: 120px;
            font-size: 0;
            opacity: 0;
            transition: all .5s ease .2s;
            &-on {
                margin-top: 80px;
                opacity: 1;
                .customizing-seven-left {
                    left: 0;
                    opacity: 1;
                }
            }
        }
        &-left {
            position: relative;
            opacity: 0;
            left: (200/1400) * 100%;
            border: 1px solid #ebebf6;
            box-sizing: border-box;
            display: inline-block;
            vertical-align: top;
            width: (830/1400) * 100%;
            height: 100%;
            background: url(../images/customizing_icon_21.png) #7070b9 no-repeat bottom left;
            background-size: 100%;
            padding: 70px 115px 0px 80px;
            transition: all .7s ease .5s;
            &-box {
                position: relative;
                width: 100%;
                height: 100%;
                transition: all .4s ease;
                &:hover {
                    transform: translate3d(0, -5px, 0);
                    box-shadow: 0 15px 30px rgba(0, 0, 0, .1);
                }
            }
            .cn {
                letter-spacing: 2px;
                font-size: 26px;
                line-height: 26px;
                color: #fff;
                font-weight: bold;
            }
            .en {
                margin-top: 12px;
                font-size: 14px;
                line-height: 14px;
                color: rgba(255, 255, 255, .6);
            }
            .text {
                letter-spacing: 1px;
                margin-top: 30px;
                font-size: 14px;
                color: #fff;
                line-height: 30px;
            }
        }
        &-right {
            position: relative;
            z-index: 100;
            border: 1px solid #ebebf6;
            box-sizing: border-box;
            display: inline-block;
            vertical-align: top;
            width: (560/1400) * 100%;
            height: 100%;
            margin-left: (10/1400) * 100%;
            li {
                box-sizing: border-box;
                cursor: pointer;
                display: inline-block;
                vertical-align: top;
                width: 50%;
                height: 50%;
                border-right: 1px solid #ebebf6;
                border-bottom: 1px solid #ebebf6;
                background: #f3f8ff;
                text-align: center;
                transition: all .4s ease;
                .num {
                    margin-top: 40px;
                    font-size: 50px;
                    line-height: 50px;
                    font-family: "DB";
                    color: #6460b2;
                    transition: all .4s ease;
                }
                .cn {
                    margin-top: 20px;
                    font-size: 18px;
                    line-height: 18px;
                    font-weight: bold;
                    color: #000;
                    transition: all .4s ease;
                }
                .en {
                    margin-top: 8px;
                    line-height: 12px;
                    font-size: 12px;
                    color: rgba(153, 153, 153, .8);
                    transition: all .4s ease;
                }
                .text {
                    display: none;
                }
                &:hover {
                    background: #6f6eb7;
                    .num {
                        color: #fff;
                    }
                    .cn {
                        color: #fff;
                    }
                    .en {
                        color: rgba(255, 255, 255, .6);
                    }
                }
            }
            .on {
                cursor: auto;
                background: #6f6eb7;
                .num {
                    color: #fff;
                }
                .cn {
                    color: #fff;
                }
                .en {
                    color: rgba(255, 255, 255, .6);
                }
            }
        }
    }
}
@media screen and (max-width: 1640px) {
    .customizing-banner {
        height: 800px;
    }
    .customizing-banner-text {
        left: 50px;
    }
    .customizing-banner-img {
        width: 600px;
        height: 465px;
        bottom: 80px;
        &:hover {
            bottom: 90px;
        }
    }
}
@media screen and (max-width: 1460px) {
    .customizing-banner {
        height: 665px;
    }
    .customizing-banner-on .customizing-banner-text {
        top: 120px;
    }
    .customizing-banner-text h5 {
        font-size: 28px;
        line-height: 28px;
    }
    .customizing-banner-text h6 {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 20px;
        &:first-letter {
            font-size: 16px;
        }
    }
    .customizing-banner-text .text {
        line-height: 26px;
    }
    .customizing-banner-text .text li {
        margin-bottom: 10px;
    }
    .customizing-two {
        height: 520px;
        padding-top: 50px;
    }
    .customizing-two-box .img {
        width: 500px;
        height: 404px;
    }
    .customizing-two-box1 {
        max-width: none;
        min-width: 0;
        width: 1100px;
    }
    .customizing-two-box .text {
        margin-top: 80px;
    }
    .customizing-two-box .text .h1 {
        font-size: 28px;
        line-height: 30px;
    }
    .customizing-two-box .text .h3 {
        margin-top: 20px;
        font-size: 18px;
        line-height: 18px;
    }
    .customizing-two-box .text .text-content {
        line-height: 26px;
    }
    .customizing-two .swiper-pagination-bullets {
        bottom: 40px;
    }
    .customizing-three {
        padding-top: 60px;
    }
    .customizing-three .title h5 {
        font-size: 28px;
        line-height: 30px;
    }
    .customizing-four .title h6 {
        font-size: 28px;
        line-height: 30px;
    }
    .customizing-three {
        height: 630px;
    }
    .customizing-three-box {
        max-width: none;
        min-width: 0;
        width: 1160px;
    }
    .customizing-three-swiper {
        padding-top: 80px;
    }
    .customizing-three .swiper-slide {
        padding: 0 20px;
    }
    .customizing-three .swiper-num {
        width: 40px;
        height: 40px;
        line-height: 40px;
        margin-left: 25px;
    }
    .customizing-three .swiper-text .swiper-title {
        font-size: 20px;
        line-height: 20px;
    }
    .customizing-three .swiper-text .swiper-en {
        font-size: 13px;
        line-height: 13px;
    }
    .customizing-three .swiper-text .swiper-content {
        line-height: 26px;
        height: 26*3px;
    }
    .customizing-three .customizing-three-pagination {
        margin-top: 50px;
    }
    .customizing-four {
        padding-top: 60px;
        height: 650px;
    }
    .customizing-four-box {
        max-width: none;
        min-width: 0;
        width: 1160px;
        padding-top: 10px;
    }
    .customizing-four-line-box {
        height: 157px;
    }
    .customizing-four-pb {
        bottom: 30px;
        left: 260px;
    }
    .customizing-four .swiper-slide {
        padding-left: 30px;
    }
    .customizing-four .swiper-slide .img {
        left: 30px;
    }
    .customizing-four .swiper-slide .swiper-slide-box {
        height: 240px;
        padding-left: 30px;
    }
    .customizing-four .swiper-slide .swiper-slide-box h1 {
        font-size: 18px;
    }
    .customizing-four .swiper-slide .swiper-slide-box .text {
        width: 300px;
        margin-top: 10px;
    }
    .customizing-four .swiper-slide-active .swiper-slide-box {
        height: 300px;
        padding-top: 90px;
        padding-left: 25px;
    }
    .customizing-four .swiper-slide-active .swiper-slide-box h1 {
        font-size: 20px;
    }
    .customizing-four .swiper-slide-active .img {
        left: 25px;
    }
    .customizing-four .swiper-slide-active .swiper-slide-box .text {
        width: 300px;
        margin-top: 20px;
    }
    .customizing-five {
        height: 660px;
    }
    .customizing-five-box {
        padding-top: 100px;
        width: 1160px;
        min-width: 0;
        max-width: none;
    }
    .customizing-five-box .text .h1 {
        font-size: 28px;
        line-height: 28px;
    }
    .customizing-five-box .text .h2 {
        letter-spacing: 0px;
        font-size: 12px;
    }
    .customizing-five-box .text .h3 {
        margin-top: 20px;
        font-size: 18px;
    }
    .customizing-five-box .text .text-content {
        margin-top: 15px;
    }
    .customizing-five-box .text .h3 span {
        padding-left: 10px;
    }
    .customizing-five .swiper-pagination-bullet {
        font-size: 14px;
    }
    .customizing-six {
        height: 670px;
    }
    .customizing-six .title {
        margin-top: 75px;
    }
    .customizing-six .title h5 {
        font-size: 28px;
        line-height: 28px;
    }
    .customizing-six .title h6 {
        font-size: 13px;
        line-height: 13px;
    }
    .customizing-six-box2 {
        margin-top: 50px;
    }
    .customizing-six-content {
        padding-top: 70px;
        height: 340px;
    }
    .customizing-six-pb {
        margin-top: 0px;
    }
    .customizing-seven {
        height: 670px;
    }
    .customizing-seven-box-on {
        margin-top: 40px;
    }
    .customizing-seven .title h5 {
        font-size: 28px;
        line-height: 28px;
    }
    .customizing-seven-box {
        max-width: none;
        min-width: 0;
        width: 1160px;
    }
    .customizing-seven-left .cn {
        font-size: 24px;
        line-height: 24px;
    }
    .customizing-seven-right li .num {
        font-size: 40px;
        line-height: 40px;
        margin-top: 30px;
    }
    .customizing-seven-right li .cn {
        font-size: 16px;
        line-height: 16px;
    }
}