.header {
    position: relative;
    z-index: 1000;
    width: 100%;
    height: 110px;
    min-width: 1250px;
    background: #fff;
    .wp1600 {
        max-width: 1600px;
        margin: 0 auto;
    }
    .pr {
        position: relative;
    }
    .identifier {
        line-height: 34px;
        font-size: 12px;
        position: absolute;
        right: 20px;
        top: 0;
        color: #787878;
        text-align: right;
        span {
            display: inline-block;
            vertical-align: top;
            margin-left: 5px;
            &.b {
                color: #333;
                font-size: 16px;
                font-weight: bold;
            }
        }
    }
    .logo {
        display: block;
        float: left;
        padding-top: 25px;
        a {
            display: inline-block;
            vertical-align: top;
        }
        > div {
            display: inline-block;
            vertical-align: top;
        }
    }
    .logo-year {
        margin-top: 20px;
        border-radius: 5px;
        width: 33px;
        height: 16px;
        background: #989898;
        line-height: 16px;
        text-align: center;
        color: #fff;
        font-size: 12px;
    }
    .logo-text {
        margin-top: 2px;
        color: #787878;
        font-size: 13px;
    }
    .nav {
        padding-top: 55px;
        float: right;
        ul {
            display: inline-block;
            vertical-align: top;
            li {
                display: inline-block;
                vertical-align: top;
                padding: 0px 30px 35px 20px;
                background: url(../images/nav-line.png) no-repeat center right;
                background-position-y: 5px;
                @media screen and (max-width: 1400px) {
                    padding: 10px 22px 25px 16px;
                    background-position-y: 15px;
                }
                .nav-a {
                    line-height: 19px;
                    font-size: 16px;
                    color: #000;
                    transition: all .2s ease;
                }
                &:hover {
                    .nav-a {
                        color: #b41515;
                    }
                }
                .on {
                    color: #b41515;
                }
                .nav2 {
                    display: none;
                    position: absolute;
                    top: 50px;
                    left: 0px;
                    width: 197px;
                    padding-top: 7px;
                    background: url(../images/head_icon_0.png) no-repeat center top;
                    a {
                        display: block;
                        width: 100%;
                        background: #fff;
                        font-size: 16px;
                        color: #777;
                        text-align: center;
                        padding: 15px 5px;
                        box-sizing: border-box;
                        line-height: 28px;
                        transition: all .3s ease;
                        &:hover {
                            background: #f6f6f6;
                        }
                        &:nth-child(1) {
                            padding-top: 25px;
                        }
                        &:last-child {
                            padding-bottom: 35px;
                            border-bottom-left-radius: 5px;
                            border-bottom-right-radius: 5px;
                        }
                    }
                    a + a {
                        border-top: 1px dashed #e0e0e0;
                    }
                }
            }
        }
        .search {
            position: relative;
            margin-left: 20px;
            margin-right: 20px;
            display: inline-block;
            vertical-align: top;
            width: 19px;
            height: 19px;
            @media screen and (max-width: 1400px) {
                padding-top: 10px;
            }
            .head-search-icon {
                position: relative;
                z-index: 15;
                width: 100%;
                height: 100%;
                cursor: pointer;
                background: url(../images/search.png) no-repeat;
            }
            .form {
                display: none;
                position: absolute;
                right: -20px;
                top: 0;
                height: 85px;
                z-index: 10;
                width: 360px;
            }
            form {
                position: absolute;
                top: 45px;
                right: 0px;
                width: 360px;
                height: 40px;
                overflow: hidden;
                border-radius: 7px;
                font-size: 0px;
                background: #efefef;
                .input {
                    display: inline-block;
                    vertical-align: top;
                    padding: 0 20px;
                    box-sizing: border-box;
                    width: 280px;
                    height: 40px;
                    background: #efefef;
                    input {
                        width: 100%;
                        height: 100%;
                        font-size: 15px;
                        border: 0px;
                        background: #efefef;
                    }
                }
                button {
                    cursor: pointer;
                    border: 0px;
                    display: inline-block;
                    vertical-align: top;
                    width: 80px;
                    height: 40px;
                    background: #b60a00;
                    transition: all .3s ease;
                    font-size: 15px;
                    color: #fff;
                    &:hover {
                        background: #da0c00;
                    }
                }
            }
        }
    }
    //iconfont
    .nav ul {
        li {
            &:nth-child(1) {
                i:before {
                    content: "\e628";
                }
            }
            &:nth-child(2) {
                a {
                    &:nth-child(1) {
                        i:before {
                            content: "\e603";
                        }
                    }
                    &:nth-child(2) {
                        i:before {
                            content: "\e63d";
                        }
                    }
                    &:nth-child(3) {
                        i:before {
                            content: "\e6b4";
                        }
                    }
                    &:nth-child(4) {
                        i:before {
                            content: "\e6a2";
                        }
                    }
                    &:nth-child(5) {
                        i:before {
                            content: "\e663";
                        }
                    }
                    &:nth-child(6) {
                        i:before {
                            content: "\e802";
                        }
                    }
                    &:nth-child(7) {
                        i:before {
                            content: "\e67c";
                        }
                    }
                    &:nth-child(8) {
                        i:before {
                            content: "\e67d";
                        }
                    }
                    &:nth-child(9) {
                        i:before {
                            content: "\e63e";
                        }
                    }
                }
            }
            &:nth-child(3) {
                .nav-2 {
                    a {
                        &:nth-child(1) {
                            i:before {
                                content: "\e63c";
                            }
                        }
                        &:nth-child(2) {
                            i:before {
                                content: "\e69a";
                            }
                        }
                        &:nth-child(3) {
                            i:before {
                                content: "\e639";
                            }
                        }
                    }
                }
            }
            &:nth-child(4) {
                .nav-2 {
                    a {
                        &:nth-child(1) {
                            i:before {
                                content: "\e689";
                            }
                        }
                        &:nth-child(2) {
                            i:before {
                                content: "\e696";
                            }
                        }
                        &:nth-child(3) {
                            i:before {
                                content: "\e680";
                            }
                        }
                    }
                }
            }
            //&:nth-child(5) {
            //    .nav-2 {
            //        a {
            //            &:nth-child(1) {
            //                i:before {
            //                    content: "\e637";
            //                }
            //            }
            //            &:nth-child(2) {
            //                i:before {
            //                    content: "\e656";
            //                }
            //            }
            //            &:nth-child(3) {
            //                i:before {
            //                    content: "\e63a";
            //                }
            //            }
            //        }
            //    }
            //}
            &:nth-child(5) {
                i:before {
                    content: "\e628";
                }
            }
            &:nth-child(8) {
                .nav-2 {
                    a {
                        &:nth-child(1) {
                            i:before {
                                content: "\e664";
                            }
                        }
                        &:nth-child(2) {
                            i:before {
                                content: "\e676";
                            }
                        }
                        &:nth-child(3) {
                            i:before {
                                content: "\e6e7";
                            }
                        }
                        &:nth-child(4) {
                            i:before {
                                content: "\e666";
                            }
                        }
                        &:nth-child(5) {
                            i:before {
                                content: "\e660";
                            }
                        }
                    }
                }
            }
            &:nth-child(9) {
                .nav-2 {
                    a {
                        &:nth-child(1) {
                            i:before {
                                content: "\e665";
                            }
                        }
                        &:nth-child(2) {
                            i:before {
                                content: "\e636";
                            }
                        }
                        &:nth-child(3) {
                            i:before {
                                content: "\e633";
                            }
                        }
                        &:nth-child(4) {
                            i:before {
                                content: "\e61b";
                            }
                        }
                        &:nth-child(5) {
                            i:before {
                                content: "\e634";
                            }
                        }
                        &:nth-child(6) {
                            i:before {
                                content: "\e65c";
                            }
                        }
                        &:nth-child(7) {
                            i:before {
                                content: "\e628";
                            }
                        }
                        &:nth-child(8) {
                            i:before {
                                content: "\e63b";
                            }
                        }
                        &:nth-child(9) {
                            i:before {
                                content: "\e627";
                            }
                        }
                        &:nth-child(10) {
                            i:before {
                                content: "\e616";
                            }
                        }
                    }
                }
            }
        }
    }
    .nav-2 {
        display: none;
        position: absolute;
        left: 0;
        top: 110px;
        width: 100%;
        background: #f7f7f7;
        border-top: 1px solid #efefef;
        border-bottom: 1px solid #efefef;
        text-align: center;
        font-size: 0px;
        a {
            box-sizing: border-box;
            border-left: 1px solid #efefef;
            background: #f7f7f7;
            position: relative;
            height: 132px;
            width: 10%;
            display: inline-block;
            vertical-align: top;
            text-align: center;
            transition: all .3s ease;
            .box {

                position: relative;
                top: 50%;
                transform: translate(0, -50%);
            }
            .iconfont {
                font-size: 37px;
                color: #555;
                transition: all .3s ease;
            }
            p {
                font-size: 13px;
                color: #888;
                transition: all .3s ease;
            }
            &:hover {
                z-index: 10;
                box-shadow: 0 15px 30px rgba(0, 0, 0, .1);
                .iconfont,
                p {
                    color: #b41515;
                }
            }
            &:last-child {
                border-right: 1px solid #efefef;
            }
            &:nth-child(n + 11) {
                border-top: 1px solid #efefef;
            }
        }
    }

    .nav-2-fixed {
        display: none !important;
        position: fixed;
        z-index: 99;
        width: 100%;
        top: 0 !important;
        left: 0 !important;
        height: 75px !important;
        opacity: 1 !important;

        @media screen and (max-width: 1400px) {
            height: 100px !important;
        }
        a {
            height: 75px;
            background-color: #fff;
            @media screen and (max-width: 1400px) {
                height: 100px;
            }
            .box {
                display: flex;
                align-items: center;
                justify-content: center;
                @media screen and (max-width: 1400px) {
                    display: block;
                }
                .iconfont {
                    font-size: 22px;
                }
                p {
                    font-size: 16px;
                    margin-left: 5px;
                    @media screen and (max-width: 1400px) {
                        font-size: 13px;
                        margin-left: 0;
                    }
                }
            }
        }
    }
    .fixed-on {
        display: block !important;
    }

}
@media screen and (max-width: 1400px) {
    .header {
        height: 90px;
        .logo {
            padding-top: 10px;
        }
        .nav {
            padding-top: 35px;
        }
        .nav-2 {
            top: 90px;
        }
    }
}
.right-fix-nav-small {
    position: fixed;
    z-index: 10000;
    bottom: 1px;
    right: -50px;
    border: 1px solid #d2d2d2;
    opacity: 0;
    transition: all .5s ease;
    &-on {
        right: 0px;
        opacity: 1;
    }
    .right-fix-nav-li {
        width: 50px;
        height: 50px;
    }
    .right-fix-show {
        .iconfont-box {
            transform: rotate(-90deg);
        }
    }
    .return {
        background: #b60a00;
        &:hover {
            background: #950c04;
        }
        .text i {
            color: #fff;
        }
    }
}
.right-fix-all {
    display: none;
}
.right-fix-nav {
    position: fixed;
    z-index: 10000;
    // bottom: 317px;
    // right: 0px;
    bottom: 30px;
    right: 55px;
    // border: 1px solid #d2d2d2;
    transition: all .5s ease;
    &-on {
        right: -73px;
        opacity: 0;
    }
    &-li {
        // width: 73px;
        // height: 73px;
        width: 50px;
        height: 50px;
        display: block;
        background: #fff;
        text-align: center;
        &:hover {
            background: #f6f6f6;
        }
        a,
        > div:nth-child(1) {
            cursor: pointer;
            display: block;
            width: 100%;
            height: 100%;
        }
        .text {
            position: relative;
            top: 50%;
            transform: translate(0, -50%);
            color: #fff;
            // font-size: 13px;
            font-size: 12px;
            i {
                color: #000;
                position: relative;
                // font-size: 30px;
                // line-height: 36px;
                font-size: 20px;
                line-height: 20px;
            }
            p {
                // font-size: 13px;
                font-size: 12px;
                color: #333;
            }
        }
        + .right-fix-nav-li {
            border-top: 1px solid #d2d2d2;
        }
        .fix-qq {
            display: none;
            position: absolute;
            top: 60px;
            right: 73px;
            width: 226px;
            height: 105px;
            background: #393939;
            border: 1px solid #5d5d5d;
            border-radius: 4px;
            box-sizing: broder-box;
            a {
                display: block;
                width: 100%;
                height: 100%;
                &:hover {
                    .button {
                        background: #dadada;
                    }
                }
            }
            &-box {
                margin: 0 auto;
                width: 160px;
                position: relative;
                top: 50%;
                transform: translate(0, -50%);
            }
            .p {
                text-align: left;
                color: #fff;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 14px;
            }
            .button {
                background: #fff;
                border-radius: 4px;
                width: 100%;
                height: 32px;
                line-height: 32px;
                font-size: 15px;
                color: #666;
            }
        }
        .fix-problem {
            display: none;
            position: absolute;
            bottom: -1px;
            right: 73px;
            height: 367px;
            width: 300px;
            background: #393939;
            padding-top: 20px;
            border-radius: 4px;
            box-sizing: broder-box;
            h1 {
                font-size: 24px;
                color: #fff;
                line-height: 34px;
                i {
                    margin-right: 5px;
                    font-size: 34px;
                    line-height: 34px;
                }
            }
            ul {
                margin-top: 10px;
                width: 100%;
                li {
                    width: 100%;
                    height: 56px;
                    a {
                        text-align: left;
                        display: block;
                        padding-left: 36px;
                        color: #c4c4c4;
                        font-size: 13px;
                        line-height: 56px;
                        &:hover {
                            opacity: .7;
                        }
                    }
                    + li {
                        border-top: 1px dashed #454545;
                    }
                }
            }
        }
        .cell {
            display: none;
            position: absolute;
            bottom: 37px;
            right: 73px;
            width: 276px;
            height: 328px;
            background: #4c4c4c;
            border-radius: 4px;
            border: 1px solid #5d5d5d;
            box-sizing: broder-box;
            &-box {
                height: 50%;
                width: 100%;
                .cell-box2 {
                    position: relative;
                    top: 50%;
                    transform: translate(0, -50%);
                }
                .left {
                    display: inline-block;
                    vertical-align: top;
                    i {
                        font-size: 36px;
                        color: #fff;
                    }
                }
                .right {
                    text-align: left;
                    display: inline-block;
                    vertical-align: top;
                    margin-left: 15px;
                    width: 120px;
                    color: #fff;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
            .gz {
                background: #393939;
            }
            .sz {
                border-top: 1px solid #5d5d5d;
                box-sizing: border-box;
                background: #4c4c4c;
            }
        }
        .erma {
            display: none;
            position: absolute;
            bottom: -1px;
            right: 73px;
            height: 367px;
            width: 184px;
            background: #fff;
            border-radius: 4px;
            border: 1px solid #ccc;
            box-sizing: border-box;
            color: #393939;
            font-size: 15px;
            &-box {
                width: 100%;
                height: 50%;
                + .erma-box {
                    border-top: 1px solid #e7e7e7;
                    box-sizing: border-box;
                }
                .img {
                    width: 86px;
                    height: 86px;
                    margin: 0 auto;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            &-box2 {
                position: relative;
                top: 50%;
                transform: translate(0, -50%);
            }
        }
        &.wx-li {
            position: relative;
            &.on,
            &:hover {
                background: #f6f6f6;
                .fix-wx {
                    display: block;
                }
            }
        }
        .fix-wx {
            position: absolute;
            right: 73px;
            top: -40px;
            padding: 25px;
            background: #393939;
            text-align: center;
            border-radius: 5px;
            display: none;
            img {
                display: block;
                width: 175px;
                margin-bottom: 12px;
            }
            p {
                color: #fff;
                font-size: 14px;
            }
        }
    }
    .hide {
        display: none;
        border-top: 1px solid #d2d2d2;
        .iconfont-box {
            transition: all .3s ease;
            transform: rotate(90deg);
        }
        p {
            color: #333;
            font-size: 13px;
        }
    }
    .return {
        border-top: none;
        background: #b60a00;
        &:hover {
            background: #950c04;
        }
        i {
            color: #fff;
        }
        .text {
            p {
                color: #fff;
            }
        }
    }
}