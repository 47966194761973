.uweb-search {
    padding-top: 44px;
    padding-bottom: 70px;
    background: #f1f1f1;
    .wp1280 {
        background: #fff;
        margin: 0 auto;
        max-width: 1320px;
        box-sizing: border-box;
        padding: 50px 60px 60px;
    }
    //搜索框
    &-top {
        position: relative;
        margin-bottom: 40px;
        height: 44px;
        width: 100%;
        &-text {
            float: left;
            width: 80px;
            font-size: 14px;
            color: #666;
            line-height: 44px;
        }
        &-input {
            margin: 0px 190px 0px 80px;
            height: 44px;
            border: 1px solid #e9e9e9;
            input {
                width: 100%;
                height: 100%;
                border: 0px;
                font-size: 14px;
                padding: 0 20px;
                box-sizing: border-box;
            }
        }
        &-button {
            position: absolute;
            top: 0;
            right: 0;
            width: 160px;
            height: 44px;
            background: #c7000b;
            cursor: pointer;
            color: #fff;
            font-size: 14px;
            line-height: 44px;
            text-align: center;
            border: 0px;
            &:hover {
                opacity: .7;
            }
        }
        //搜索框 搜索类型
        &-input2 {
            margin-right: 30px;
            width: 490px;
            float: left;
            height: 44px;
            border: 1px solid #e9e9e9;
            input {
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                border: 0px;
                font-size: 14px;
                padding: 0 20px;
            }
        }
        &-text1 {
            float: left;
            width: 70px;
            font-size: 14px;
            color: #666;
            line-height: 44px;
        }
        &-select {
            position: relative;
            width: 340px;
            float: left;
            height: 44px;
            border: 1px solid #e9e9e9;
            font-size: 14px;
            color: #999;
            @media screen and (max-width: 1280px) {
                width: 305px;
            }
            &:after {
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translate(0, -50%);
                content: "";
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 6px solid #999;
            }
            select {
                position: relative;
                z-index: 10;
                width: 100%;
                box-sizing: border-box;
                height: 100% !important;
                border: 0px;
                padding: 0 22px;
            }
            .customSelect {
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                line-height: 42px;
                padding: 0 22px;
            }
        }
    }
    //搜索内容
    &-content {
        margin: 0 -12px 20px;
        font-size: 0;
        li {
            display: inline-block;
            vertical-align: top;
            padding: 0 12px;
            box-sizing: border-box;
            width: 50%;
            height: 156px;
            margin-bottom: 30px;
            a {
                display: block;
                width: 100%;
                height: 100%;
                border: 1px solid #e9e9e9;
                box-sizing: border-box;
                padding: 25px 40px 30px;
                transition: all .3s ease;
                &:hover {
                    background: #f1f1f1;
                    .text {
                        color: #c7000b;
                    }
                    .btn {
                        background: #c7000b;
                    }
                }
                .text {
                    font-size: 16px;
                    font-weight: bold;
                    color: #666;
                    transition: all .3s ease;
                }
                .time {
                    margin-top: 5px;
                    font-size: 14px;
                    font-family: "Arial";
                    color: #999;
                }
                .btn {
                    margin-top: 20px;
                    width: 95px;
                    height: 28px;
                    background: #999;
                    font-size: 14px;
                    color: #fff;
                    line-height: 28px;
                    text-align: center;
                    transition: all .3s ease;
                }
            }
        }
    }
}