.wp1200 {
    margin: 0 auto;
    width: 1202px;
}
.wx-box-4 {
    .wx-banner {
        width: 100%;
        height: 829px;
        background-image: url(../images/bg24.jpg);
        background-position: center center;
        background-size: cover;
        .wp1200 {
            position: relative;
            height: 100%;
            .img {
                visibility: hidden;
                position: absolute;
                right: 32px;
                top: 155px;
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
            .left {
                padding-top: 305px;
                padding-left: 7px;
                h1 {
                    visibility: hidden;
                    font-size: 44px;
                    color: #fff;
                    margin-bottom: 25px;
                    font-weight: bold;
                }
                .p {
                    color: #fff;
                    font-size: 15px;
                    line-height: 36px;
                    p {
                        visibility: hidden;
                        padding-left: 16px;
                        position: relative;
                        &:after {
                            content: "";
                            top: 15px;
                            left: 0;
                            position: absolute;
                            width: 7px;
                            height: 7px;
                            background: #fff;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
    .wx-small-program {
        width: 100%;
        height: 911px;
        background-image: url(../images/bg14.jpg);
        background-position: center center;
        background-size: cover;
        h1 {
            font-size: 40px;
            color: #333;
            padding-top: 98px;
            text-align: center;
        }
        h2 {
            text-align: center;
            font-size: 13px;
            color: #999;
            margin-bottom: 50px;
        }
        ul {
            li {
                width: 25%;
                float: left;
                padding: 0 5px;
                box-sizing: border-box;
                height: 504px;
                text-align: center;
                .img {
                    width: 100%;
                    padding-bottom: 76.28%;
                    position: relative;
                    z-index: 2;
                    margin-bottom: 36px;
                    img {
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                    }
                }
                h4 {
                    color: #333;
                    font-size: 18px;
                    position: relative;
                    z-index: 2;
                }
                .nr {
                    background: #fff;
                    width: 100%;
                    height: 100%;
                    position: relative;
                    &:after {
                        content: "";
                        position: absolute;
                        left: -100px;
                        top: -98px;
                        width: 495px;
                        height: 689px;
                        background-image: url(../images/y-4.png);
                        background-size: 100% 100%;
                        background-position: center center;
                        background-repeat: no-repeat;
                    }
                }
                .icon {
                    width: 30px;
                    position: relative;
                    z-index: 2;
                    height: 1px;
                    background: #d8d8d8;
                    display: inline-block;
                    vertical-align: top;
                    margin: 18px auto 24px;
                }
                .p {
                    position: relative;
                    z-index: 2;
                    padding: 0 34px;
                    font-size: 14px;
                    color: #999;
                    line-height: 26px;
                }
            }
        }
    }
    .wx-small-program-1 {
        width: 100%;
        height: 920px;
        background-image: url(../images/bg15.jpg);
        background-position: center center;
        background-size: cover;
        h1 {
            font-size: 40px;
            color: #fff;
            padding-top: 100px;
            text-align: center;
            margin-bottom: 10px;
        }
        h2 {
            text-align: center;
            font-size: 16px;
            color: #fff;
            margin-bottom: 50px;
        }
        ul {
            li {
                width: 20%;
                float: left;
                padding: 0 10px;
                box-sizing: border-box;
                height: 220px;
                text-align: center;
                margin-bottom: 20px;
            }
            .nr {
                width: 100%;
                background: #fff;
                height: 100%;
                text-align: center;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    left: -30px;
                    top: -30px;
                    width: 280px;
                    height: 280px;
                    background-image: url(../images/y-5.png);
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }
            .img {
                width: 100%;
                height: 160px;
                line-height: 160px;
                position: relative;
                z-index: 2;
                img {
                    margin-top: 32px;
                    vertical-align: top;
                }
            }
            .p {
                position: relative;
                z-index: 2;
                height: 60px;
                line-height: 60px;
                width: 100%;
                background: #ddecf9;
                font-size: 18px;
                color: #333;
                font-weight: bold;
            }
        }
    }
    .wx-small-program-case {
        width: 100%;
        height: 912px;
        background-image: url(../images/bg16.jpg);
        background-position: center center;
        background-size: cover;
        text-align: center;
        h1 {
            font-size: 40px;
            color: #333;
            padding-top: 100px;
            margin-bottom: 5px;
        }
        h2 {
            font-size: 16px;
            color: #999;
            margin-bottom: 50px;
        }
        ul {
            font-size: 0;
            margin-bottom: 23px;
            li {
                display: inline-block;
                vertical-align: top;
                margin-left: -30px;
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
        .p {
            font-size: 24px;
            color: #333;
        }
    }
}
.animated {
    animation-duration: 1.5s;
    animation-fill-mode: both;
    visibility: visible !important;
}
.animated.infinite {
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}
.animated-delay-0 {
    animation-delay: .4s;
}
.animated-delay-1 {
    animation-delay: .8s;
}
.animated-delay-2 {
    animation-delay: 1s;
}
.animated-delay-3 {
    animation-delay: .2s;
}
.animated-delay-4 {
    animation-delay: 1.2s;
}
@keyframes fadeIn-left {
    0% {
        transform: translate(-100px, 0);
        opacity: 0;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}
.fadeIn-left,
.anim .fadein-left {
    animation-name: fadeIn-left;
}
@keyframes fadeIn-right {
    0% {
        transform: translate(100px, 0);
        opacity: 0;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}
.fadeIn-right {
    animation-name: fadeIn-right;
}
@keyframes fadeIn-top {
    0% {
        transform: translate(0, -50px);
        opacity: 0;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}
.fadeIn-top,
.anim .fadein-top {
    animation-name: fadeIn-top;
}
@keyframes fadeIn-bottom {
    0% {
        transform: translate(0, 50px);
        opacity: 0;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}
.anim .fadein-bottom {
    animation-name: fadeIn-bottom;
}
@keyframes fadeIn-roll {
    0% {
        transform: translate(50px, 0) rotate(90deg);
        opacity: 0;
    }
    100% {
        transform: translate(0, 0) rotate(0);
        opacity: 1;
    }
}
.anim .fadein-roll {
    animation-name: fadeIn-roll;
}
@keyframes fadeIn-s {
    0% {
        transform: translate(50px, 0);
        opacity: 0;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}
.fadeIn-s,
.anim .fadein-s {
    animation-name: fadeIn-s;
}
@keyframes fadeIn-ss {
    0% {
        transform: translate(-100px, 100px);
        opacity: 0;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}
.fadeIn-ss {
    animation-name: fadeIn-ss;
}
.wx-bottom-entry-con {
    background-color: #cb132d;
}
.wx-bottom-entry {
    margin: 0 auto;
    width: 460px * 3;
}
.wx-bottom-entry-item {
    float: left;
    width: 460px;
    position: relative;
    height: 112px;
    display: block;
    padding-top: 33px;
    .arrow {
        display: none;
        position: absolute;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 10px solid #549be5;
        top: -10px;
        left: 50%;
        margin-left: -15px;
    }
    &:before {
        position: absolute;
        display: block;
        width: 1px;
        height: 42px;
        left: 0;
        top: 50%;
        margin-top: -21px;
        background-color: #a90a22;
        content: '';
    }
    &:after {
        position: absolute;
        display: block;
        width: 1px;
        height: 42px;
        left: 1px;
        top: 50%;
        margin-top: -21px;
        background-color: #d13047;
        content: '';
    }
    &:first-child {
        &:before,
        &:after {
            display: none;
        }
    }
    &:hover {
        .wx-bottom-entry-icon {
            transform: translateY(-5px);
            &:after {
                transform: translateY(5px);
            }
        }
    }
    &.on {
        background-color: #549be5;
        &:before,
        &:after {
            display: none;
        }
        .wx-bottom-entry-icon {
            background-color: #3075c1;
        }
        .arrow {
            display: block;
        }
    }
}
.wx-bottom-entry-icon {
    float: left;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: relative;
    background-color: #a00018;
    margin-left: 60px;
    transition: all .5s;
    img {
        display: block;
        width: 100%;
        height: 100%;
    }
    &:after {
        width: 64px;
        height: 10px;
        border-radius: 50%;
        background: url(../images/shadow_1.png);
        position: absolute;
        left: 50%;
        margin-left: -32px;
        bottom: -12px;
        content: '';
        opacity: .5;
        transition: all .5s;
    }
}
.wx-bottom-entry-info {
    margin-left: 160px;
    width: 255px;
    padding-top: 15px;
    h6 {
        font-size: 24px;
        color: #fff;
        margin-bottom: 7px;
    }
    p {
        font-family: "Arial";
        font-size: 13px;
        color: rgba(255, 255, 255, .6);
        text-transform: uppercase;
    }
}
.weixin-fixed {
    position: fixed;
    width: 100%;
    height: 95px;
    background: url(../images/tuiguang_bg2.jpg) no-repeat center center;
    background-size: cover;
    z-index: 80;
    left: 0;
    bottom: 0;
    ~ .footer2 {
        padding-bottom: 95px;
    }
    .wp {
        display: flex;
        height: 95px;
        align-items: center;
        width: 1000px;
        margin: 0 auto;
    }
    form {
        display: flex;
        align-items: center;
        width: 100%;
        .des {
            flex: 2;
            overflow: hidden;
        }
        .phone {
            flex: 1;
            overflow: hidden;
            margin-left: 20px;
        }
        input {
            display: block;
            width: 100%;
            padding: 0 10px;
            box-sizing: border-box;
            line-height: 50px;
            font-size: 16px;
            border-radius: 8px;
            border: none;
            background: #fff;
        }
        button {
            width: 180px;
            margin-left: 20px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            border: none;
            font-size: 20px;
            color: #fff;
            background: #4e84f0;
            transition: all .2s;
            cursor: pointer;
            appearance: none;
            border-radius: 8px;
            &:hover {
                background: lighten(#4e84f0, 5%);
            }
        }
    }
}