.case-scroll {
    position: relative;
    width: 100%;
    min-width: 1250px;
    overflow: hidden;
}
.case-button {
    position: absolute;
    z-index: 100;
    left: 150px;
    top: 380px;
    width: 122px;
    height: 57px;
    border: 1px solid rgba(255, 255, 255, .5);
    box-sizing: border-box;
    font-size: 0px;
    @media screen and (max-width: 1700px) {
        top: 330px;
    }
    > div {
        width: 50%;
        height: 100%;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        background: url(../images/case_icon_2.png) no-repeat center center;
        transition: all .3s ease;
        &:hover {
            opacity: .5;
        }
    }
    .case-button-next {
        box-sizing: border-box;
        transform: rotate(180deg);
    }
}
.case-button:before {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    background-color: rgba(255, 255, 255, .5);
    content: '';
}
.case {
    &-banner {
        display: block;
        position: relative;
        width: 100%;
        min-width: 1250px;
        padding-bottom: (520/1920) * 100%;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .info {
            position: absolute;
            top: 125px;
            left: 150px;
            width: auto;
            @media screen and (max-width: 1700px) {
                top: 80px;
            }
            @media screen and (max-width: 1500px) {
                left: 30px;
                top: 60px;
            }
            .title {
                font-size: 40px;
                line-height: 50px;
                height: 100px;
                @include multiEllipsis(2);
                font-weight: bold;
                color: #fff;
                position: relative;
                padding-bottom: 25px;
                max-width: 470px;
                @media screen and (max-width: 1600px) {
                    font-size: 40px;
                    line-height: 50px;
                }
            }
            .title:before {
                position: absolute;
                content: '';
                left: 0;
                bottom: 0;
                width: 45px;
                height: 4px;
                background-color: #fff;
            }
            .dsc {
                font-size: 20px;
                line-height: 26px;
                height: 52px;
                overflow: hidden;
                @include multiEllipsis(2);
                color: #fff;
                margin-top: 40px;
                max-width: 450px;
            }
        }
        //&-left {
        //    position: absolute;
        //    left: 0;
        //    top: 0;
        //    background: #282a2e;
        //    display: inline-block;
        //    height: 100%;
        //    width: (600/1920) * 100%;
        //    .en {
        //        position: absolute;
        //        left: 60px;
        //        top: 70px;
        //        font-size: 20px;
        //        line-height: 25px;
        //        color: rgba(255, 255, 255, .2);
        //        text-transform: uppercase;
        //    }
        //    .num {
        //        font-family: "Arial";
        //        font-size: 28px;
        //        line-height: 28px;
        //        position: absolute;
        //        right: 60px;
        //        top: 70px;
        //        color: rgba(255, 255, 255, .2);
        //    }
        //    .text {
        //        position: absolute;
        //        top: (420/880) * 100%;
        //        left: 0%;
        //        width: 100%;
        //        padding-left: 70px;
        //        padding-right: 75px;
        //        box-sizing: border-box;
        //        transition: all .3s ease;
        //        &:hover {
        //            margin-top: -10px;
        //        }
        //        .title {
        //            height: 96px;
        //            transition: all .2s ease;
        //            margin-bottom: 30px;
        //            font-size: 48px;
        //            line-height: 48px;
        //            color: #fff;
        //            font-weight: bold;
        //            font-family: "SimHei";
        //            word-break: break-all;
        //            text-overflow: ellipsis;
        //            display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
        //            -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
        //            -webkit-line-clamp: 2; /** 显示的行数 **/
        //            overflow: hidden; /** 隐藏超出的内容 **/
        //        }
        //        p {
        //            font-family: "SimHei";
        //            font-size: 14px;
        //            line-height: 30px;
        //            color: rgba(255, 255, 255, .7);
        //            word-break: break-all;
        //            text-overflow: ellipsis;
        //            display: -webkit-box;
        //            -webkit-box-orient: vertical;
        //            -webkit-line-clamp: 2;
        //            overflow: hidden;
        //        }
        //    }
        //}
        //&-right {
        //    position: absolute;
        //    left: (600/1920) * 100%;
        //    top: 0;
        //    width: (1320/1920) * 100%;
        //    height: 100%;
        //    font-size: 0px;
        //    img {
        //        transition: all .5s ease;
        //        &:hover {
        //            transform: scale(1.03);
        //        }
        //    }
        //    .img-one {
        //        display: inline-block;
        //        vertical-align: top;
        //        position: relative;
        //        width: (600/1320) * 100%;
        //        &-box {
        //            display: block;
        //            overflow: hidden;
        //            position: relative;
        //            width: 100%;
        //            padding-bottom: (440/600) * 100%;
        //            img {
        //                position: absolute;
        //                top: 0;
        //                left: 0;
        //                width: 100%;
        //                height: 100%;
        //            }
        //        }
        //    }
        //    .img-two {
        //        display: inline-block;
        //        vertical-align: top;
        //        position: relative;
        //        width: (720/1320) * 100%;
        //        &-box {
        //            display: block;
        //            position: relative;
        //            width: 100%;
        //            padding-bottom: (440/720) * 100%;
        //            overflow: hidden;
        //            img {
        //                position: absolute;
        //                top: 0;
        //                left: 0;
        //                width: 100%;
        //                height: 100%;
        //            }
        //        }
        //    }
        //    .img-three {
        //        display: inline-block;
        //        vertical-align: top;
        //        position: relative;
        //        width: 100%;
        //        padding-bottom: (440/1320) * 100%;
        //        overflow: hidden;
        //        img {
        //            position: absolute;
        //            top: 0;
        //            left: 0;
        //            width: 100%;
        //            height: 100%;
        //        }
        //    }
        //}
    }
    &-nav-box {
        min-width: 1250px;
        width: 100%;
        height: 116px;
    }
    &-nav {
        z-index: 500;
        position: relative;
        min-width: 1250px;
        width: 100%;
        background: #f3f3f3;
        font-size: 0;
        border-bottom: 1px solid #ececec;
        &-on {
            position: fixed;
            top: 0;
            left: 0;
        }
        .case-nav-left {
            display: inline-block;
            vertical-align: top;
            width: 50%;
            height: 100%;
            text-align: right;
            border-right: 1px solid #ececec;
            box-sizing: border-box;
            /*background: #F7F7F7;*/
            &-box {
                height: 100%;
                display: inline-block;
                width: 840px;
            }
            &-box2 {
                position: relative;
                height: 100%;
                width: 100%;
                text-align: left;
            }
        }
        .case-nav-right {
            display: inline-block;
            vertical-align: top;
            width: 50%;
            height: 100%;
            text-align: left;
            &-box {
                height: 100%;
                display: inline-block;
                width: 850px;
            }
            &-box2 {
                position: relative;
                height: 100%;
                width: 100%;
            }
        }
        .case-nav-F7 {
            background: #f7f7f7;
            .case-nav-icon .icon {
                background: url(../images/case_icon_3_on.png) no-repeat center right;
            }
        }
        .title {
            float: left;
            display: inline-block;
            padding: 24px 15px 20px 30px;
            font-size: 17px;
            font-weight: bold;
            vertical-align: top;
            cursor: pointer;
            &:hover {
                color: #b60a00;
            }
        }
        .content {
            overflow: hidden;
            padding: 16px 0px 20px;
            > div {
                display: inline-block;
                vertical-align: top;
                padding-left: 14px;
                padding-right: 14px;
                padding-top: 10px;
                box-sizing: border-box;
                text-align: center;
            }
            a {
                font-size: 14px;
                color: #77777b;
                font-family: "Microsoft JhengHei";
                &:hover {
                    color: #b60a00;
                }
                &.on {
                    color: #b60a00;
                }
            }
        }
        .case-nav-icon {
            position: absolute;
            top: 50%;
            right: 20px;
            margin-top: -14px;
            display: inline-block;
            vertical-align: top;
            width: 140px;
            height: 28px;
            border: 1px solid #999;
            border-radius: 6px;
            cursor: pointer;
            padding: 0px 10px 0px 15px;
            box-sizing: border-box;
            background: #fff;
            .icon {
                position: relative;
                height: 100%;
                font-size: 14px;
                color: #666;
                line-height: 26px;
                background: url(../images/case_icon_3.png) no-repeat center right;
            }
        }
        .case-nav-show {
            display: none;
            position: absolute;
            box-shadow: 12px 20px 18px rgba(0, 0, 0, .06);
            z-index: 100;
            top: 100px;
            left: 0;
            background: #f7f7f7;
            width: 100%;
            &-box {
                max-width: 1500px;
                padding-top: 50px;
                padding-bottom: 20px;
                margin: 0 auto;
                ul {
                    width: 100%;
                    text-align: left;
                    li {
                        display: inline-block;
                        vertical-align: top;
                        padding-right: 40px;
                        padding-bottom: 26px;
                        a {
                            font-size: 14px;
                            color: #77777b;
                            font-family: "Microsoft JhengHei";
                            &:hover {
                                color: #b60a00;
                            }
                        }
                        .on {
                            color: #b60a00;
                        }
                    }
                }
            }
        }
    }
    &-content {
        max-width: 1740px;
        box-sizing: border-box;
        padding: 35px 20px 70px;
        min-width: 1250px;
        margin: 0 auto;
        &-box {
            position: relative;
            width: 100%;
            margin-top: 20px;
            font-size: 0px;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                transform: translate(-50%, -50%);
                transition: all .4s ease;
            }
            .case-content-li {
                &:hover {
                    .case-content-li-box {
                        img {
                            transform: translate(-50%, -50%) scale(1.05);
                        }
                    }
                    .text {
                        background: rgba(0, 0, 0, .4);
                    }
                }
            }
            .text {
                box-sizing: border-box;
                transition: all .4s ease;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                background: rgba(0, 0, 0, .15);
                padding: 0 45px;
                height: 80px;
                color: #fff;
                font-size: 18px;
                h5 {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 18px;
                    margin-top: 12px;
                    margin-bottom: 5px;
                }
                h6 {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            &:nth-child(odd) {
                .case-content-li {
                    display: inline-block;
                    vertical-align: top;
                    &:nth-child(1) {
                        width: (840/1700) * 100%;
                        .case-content-li-box {
                            position: relative;
                            width: 100%;
                            padding-bottom: 100%;
                            overflow: hidden;
                        }
                        img {
                            height: 100%;
                        }
                    }
                    &:nth-child(2),
                    &:nth-child(3) {
                        width: (410/1700) * 100%;
                        margin-left: (20/1700) * 100%;
                        .case-content-li-box {
                            position: relative;
                            width: 100%;
                            padding-bottom: 100%;
                            overflow: hidden;
                        }
                        img {
                            height: 100%;
                        }
                    }
                    &:nth-child(4) {
                        position: absolute;
                        top: (420/820) * 100%;
                        left: (860/1700) * 100%;
                        width: (840/1700) * 100%;
                        .case-content-li-box {
                            position: relative;
                            width: 100%;
                            padding-bottom: (400/820) * 100%;
                            overflow: hidden;
                        }
                        img {
                            width: 100%;
                        }
                    }
                }
            }
            &:nth-child(even) {
                .case-content-li {
                    display: inline-block;
                    vertical-align: top;
                    &:nth-child(4) {
                        width: (840/1700) * 100%;
                        .case-content-li-box {
                            position: relative;
                            width: 100%;
                            padding-bottom: 100%;
                            overflow: hidden;
                        }
                        img {
                            height: 100%;
                        }
                    }
                    &:nth-child(1),
                    &:nth-child(2) {
                        width: (410/1700) * 100%;
                        margin-right: (20/1700) * 100%;
                        .case-content-li-box {
                            position: relative;
                            width: 100%;
                            padding-bottom: 100%;
                            overflow: hidden;
                        }
                        img {
                            height: 100%;
                        }
                    }
                    &:nth-child(3) {
                        position: absolute;
                        top: (420/820) * 100%;
                        left: 0px;
                        width: (840/1700) * 100%;
                        .case-content-li-box {
                            position: relative;
                            width: 100%;
                            padding-bottom: (400/820) * 100%;
                            overflow: hidden;
                        }
                    }
                    &:last-child {
                        position: relative;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1700px) {
    .case-nav .case-nav-left-box {
        width: 750px;
    }
    .case-nav .case-nav-right-box {
        width: 750px;
    }
    .case-nav .title {
        padding-left: 20px;
        padding-right: 10px;
    }
    .case-nav .case-nav-icon {
        width: 120px;
        padding: 0 8px;
    }
    .case-nav .case-nav-show-box {
        max-width: 1500px;
    }
    .case-nav .case-nav-show-box ul {
        padding: 0 20px;
        box-sizing: border-box;
    }
}
@media screen and (max-width: 1640px) {
    .case-banner-left .text {
        top: 40%;
    }
}
@media screen and (max-width: 1500px) {
    .case-banner-left .text .title {
        font-size: 42px;
        line-height: 42px;
        height: 84px;
        margin-bottom: 20px;
    }
    .case-banner-left .text {
        padding-left: 30px;
        padding-right: 30px;
    }
    .case-button {
        left: 30px;
        top: 75%;
    }
    .case-nav .content > div {
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .case-nav .case-nav-left-box {
        width: 100%;
    }
    .case-nav .case-nav-right-box {
        width: 100%;
    }
    .case-nav .title {
        padding-left: 10px;
    }
    .case-content {
        min-width: 0;
        padding-left: 0px;
        padding-right: 0px;
        max-width: none;
        width: 1160px;
        padding-bottom: 40px;
    }
    .case-content-box .text {
        line-height: 40px;
        font-size: 14px;
    }
    .case-content-box .case-content-li:hover .text {
        line-height: 60px;
    }
    .case-nav .case-nav-show {
        top: 70px;
    }
    .case-nav .title {
        padding-top: 20px;
    }
    .case-nav .content {
        padding-top: 12px;
    }
}
@media screen and (max-width: 1460px) {
    .case-banner-left .text .title {
        font-size: 28px;
        line-height: 28px;
        height: 56px;
    }
}
@media screen and (max-width: 1600px) {
    .case-banner {
        height: 400px;
        padding-bottom: 0px;
    }
}