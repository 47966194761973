.problem-banner {
    width: 100%;
    height: 407px;
    min-width: 1250px;
    background: #fff none no-repeat center center;
    background-size: cover 100%;
    .title {
        color: #fff;
        h5 {
            font-size: 45px;
            line-height: 45px;
            font-family: "SimSun";
        }
    }
}
.problem {
    min-width: 1250px;
    background: #f1f1f1;
    width: 100%;
    padding-bottom: 84px;
    &-box {
        max-width: 1442px;
        min-width: 1250px;
        margin: 0 auto;
    }
    &-box2 {
        width: 100%;
        font-size: 0;
        &-left {
            display: inline-block;
            width: 212px;
            a {
                transition: all .3s ease;
                position: relative;
                background: #535353;
                display: block;
                width: 100%;
                height: 80px;
                margin-bottom: 1px;
                .icon {
                    position: absolute;
                    top: 20px;
                    left: 25px;
                }
                .text {
                    padding-left: 80px;
                    padding-top: 20px;
                    h5 {
                        color: #fff;
                        font-size: 16px;
                        font-weight: bold;
                        letter-spacing: 2px;
                    }
                    h6 {
                        color: #fff;
                        font-size: 11px;
                        font-family: "Arial";
                        text-transform: uppercase;
                    }
                }
                &:hover {
                    background: #d12322;
                }
            }
            .on {
                background: #d12322;
            }
        }
        &-right {
            display: inline-block;
            vertical-align: top;
            width: 1230px;
            padding: 50px 60px 90px;
            box-sizing: border-box;
            border-top: 2px solid #d12322;
            background: #fff;
        }
        &-content {
            margin-top: 60px;
            border-top: 1px solid #e5e5e5;
            padding-bottom: 75px;
            .item {
                width: 100%;
                border: 1px solid #e5e5e5;
                border-top: 0px;
                &:nth-child(even) {
                    background: #f7f7f7;
                }
                &-content {
                    display: none;
                    box-shadow: 0 5px 32px rgba(0, 0, 0, .05);
                    padding: 0px 30px 80px;
                    box-sizing: border-box;
                    .border {
                        width: 100%;
                        height: 2px;
                        background: url(../images/problem_banner_10.png);
                    }
                    &-box {
                        padding-top: 33px;
                        width: 100%;
                        line-height: 30px;
                        font-size: 14px;
                        color: #999;
                    }
                }
            }
            .on {
                .title {
                    &:after {
                        background-image: url(../images/problem_icon_7.png);
                    }
                    &:before {
                        background-image: url(../images/problem_icon_9.png);
                    }
                }
            }
            .title {
                cursor: pointer;
                position: relative;
                width: 100%;
                height: 80px;
                padding-left: 80px;
                padding-right: 86px;
                box-sizing: border-box;
                line-height: 80px;
                font-size: 14px;
                color: #444;
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 80px;
                    height: 80px;
                    background: url(../images/problem_icon_6.png) no-repeat center center;
                    transition: all .3s ease;
                }
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 80px;
                    height: 80px;
                    background: url(../images/problem_icon_8.png) no-repeat center center;
                    transition: all .3s ease;
                }
            }
        }
    }
    .search {
        position: relative;
        width: 100%;
        background: #f3f3f3;
        height: 60px;
        border: 1px solid #e5e5e5;
        border-radius: 7px;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 145px;
        overflow: hidden;
        input[type='text'] {
            border: 0px;
            background: #f3f3f3;
            height: 100%;
            font-size: 14px;
            color: #333;
            width: 100%;
            padding-right: 20px;
            box-sizing: border-box;
        }
        button {
            cursor: pointer;
            height: 100%;
            background: #535353;
            width: 145px;
            position: absolute;
            right: 0;
            top: 0;
            border: 0px;
            color: #fff;
            font-size: 16px;
            letter-spacing: 3px;
            transition: all .4s ease;
            &:hover {
                background: #d12322;
            }
        }
    }
}
.address-top {
    height: 64px;
    line-height: 64px;
    color: #666;
    font-size: 12px;
    background: url(../images/new_icon_2.png) no-repeat center left;
    padding-left: 26px;
    a {
        color: #666;
        font-size: 12px;
        &:hover {
            color: #d12322;
        }
    }
}
@media screen and (max-width: 1460px) {
    .problem-box {
        max-width: 1342px;
    }
    .problem-box2-right {
        padding: 50px 40px 70px;
        width: 1130px;
    }
    .problem-box2-right {
        width: 900px;
        padding: 50px 40px;
    }
    .problem-box2-left {
        width: 200px;
    }
    .problem-box {
        width: 1100px;
        max-width: auto;
        min-width: 0;
    }
    .problem-box2-left a .icon {
        left: 15px;
    }
    .problem-box2-left a .text {
        padding-left: 65px;
    }
    .problem-box2-content {
        margin-top: 30px;
        padding-bottom: 45px;
    }
    .problem {
        padding-bottom: 60px;
    }
    .recommend-case {
        padding-top: 60px;
    }
}