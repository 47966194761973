@font-face {
    font-family: "CG";
    src: url('../font/CenturyGothic.ttf');
}
@font-face {
    font-family: "DB";
    src: url('../font/DIDOTBOLD.TTF');
}
@font-face {
    font-family: "BT";
    src: url('../font/AvantGardeITCbyBTBook.ttf');
}
@font-face {
    font-family: "iconfont";
    src: url('../font/iconfont.eot');
    src: url('../font/iconfont.eot#iefix') format('embedded-opentype'), url('../font/iconfont.woff') format('woff'), url('../font/iconfont.ttf') format('truetype'), url('../font/iconfont.svg#iconfont') format('svg');
}
@font-face {
    font-family: 'BodoniBookBT';
    src: url('../font/BodoniBookBT.eot');
    src: local('☺'), url('../font/BodoniBookBT.woff') format('woff'), url('../font/BodoniBookBT.ttf') format('truetype'), url('../font/BodoniBookBT.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
.iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: .2px;
    -moz-osx-font-smoothing: grayscale;
}
.wp1200 {
    width: 1200px;
    margin: 0 auto;
}
.wp1440 {
    max-width: 1440px;
    min-width: 1250px;
    margin: 0 auto;
}
.wp1620 {
    max-width: 1620px;
    min-width: 1250px;
    margin: 0 auto;
}
.wp1500 {
    max-width: 1500px;
    min-width: 1250px;
    margin: 0 auto;
}
.wp1520 {
    max-width: 1520px;
    min-width: 1250px;
    margin: 0 auto;
}
body {
    background: #fff;
}
.gray {
    background: #f9f7f6;
}
.recommend {
    &-title {
        text-align: center;
        h5 {
            font-size: 36px;
            line-height: 36px;
            color: #333;
        }
        h6 {
            font-size: 15px;
            line-height: 50px;
            color: #999;
        }
    }
    /*推荐案例*/
    &-case {
        padding-top: 85px;
        background: #ebebeb;
        min-width: 1250px;
        &-box {
            margin: 0 auto;
            max-width: 1467px;
            min-width: 1250px;
        }
        &-swiper {
            padding-top: 40px;
            padding-bottom: 40px;
            position: relative;
            width: 100%;
            height: 480px;
            overflow: hidden;
            .swiper-slide {
                width: 33.33%;
                .img {
                    position: relative;
                    width: 100%;
                    height: 259px;
                    overflow: hidden;
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 100%;
                        height: auto !important;
                        transition: all .4s ease;
                    }
                }
                .text {
                    padding-left: 16px;
                    box-sizing: border-box;
                    width: 100%;
                    h3 {
                        transition: all .2s ease;
                        color: #444;
                        margin-top: 22px;
                        font-size: 16px;
                        line-height: 16px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        @media screen and (max-width: 1460px) {
                            margin-top: 32px;
                        }
                    }
                    h6 {
                        overflow: hidden;
                        max-height: 48px;
                        margin-top: 8px;
                        color: #a3a3a3;
                        line-height: 24px;
                        font-size: 12px;
                    }
                }
            }
            .recommend-case-prev {
                cursor: pointer;
                position: absolute;
                z-index: 10;
                top: 156px;
                left: 40px;
                width: 35px;
                height: 71px;
                background: url(../images/recommend_icon_0.png) rgba(0, 0, 0, .85) no-repeat;
                background-position: 9px 26px;
                transition: all .2s ease;
                &:hover {
                    background-color: rgba(0, 0, 0, .6);
                }
            }
            .recommend-case-next {
                cursor: pointer;
                position: absolute;
                z-index: 10;
                top: 156px;
                right: 40px;
                width: 35px;
                height: 71px;
                transform: rotate(180deg);
                background: url(../images/recommend_icon_0.png) rgba(0, 0, 0, .85) no-repeat;
                background-position: 9px 26px;
                transition: all .2s ease;
                &:hover {
                    background-color: rgba(0, 0, 0, .6);
                }
            }
        }
        &-box2 {
            display: block;
            width: 100%;
            height: 400px;
            box-sizing: border-box;
            padding: 0 12px;
        }
        &-box3 {
            transition: all .3s ease;
            display: block;
            width: 100%;
            height: 100%;
            background: #fff;
            border: 1px solid #dcdcdc;
            box-sizing: border-box;
            padding: 24px 26px 0px;
            &:hover {
                box-shadow: 0 15px 30px rgba(0, 0, 0, .1);
                transform: translate3d(0, -5px, 0);
                .text h5 {
                    color: #b41515;
                }
            }
        }
    }
    /*业务展示*/
    &-business {
        min-width: 1250px;
        background: #f1f1f1;
        padding: 70px 12px 105px;
        box-sizing: border-box;
        &-box {
            margin: 0 auto;
            margin-top: 50px;
            max-width: 1483px;
            min-width: 1276px;
            text-align: left;
            height: 560px;
            font-size: 0;
        }
        &-li + &-li {
            border-left: 1px solid #f6f6f6;
            box-sizing: border-box;
        }
        &-li {
            position: relative;
            display: inline-block;
            vertical-align: top;
            text-align: center;
            width: 20%;
            height: 100%;
            background: #fff;
            transition: all .3s ease;
            &:hover {
                z-index: 10;
                box-shadow: 0 0 59px rgba(0, 0, 0, .07);
            }
            .img {
                position: absolute;
                width: 100%;
                top: 0;
                margin-top: 40px;
                display: block;
                text-align: center;
                &:hover {
                    img {
                        width: 100px;
                    }
                }
                img {
                    width: 92px;
                    transition: all .3s ease;
                }
                h5 {
                    font-size: 24px;
                    color: #000;
                    font-family: "SimHei";
                    line-height: 24px;
                    margin-top: 20px;
                }
                h6 {
                    margin-top: 12px;
                    font-size: 15px;
                    color: #ddd;
                    line-height: 15px;
                    font-family: "BT";
                }
            }
        }
        ul {
            margin-top: 245px;
            display: inline-block;
            vertical-align: top;
            text-align: left;
            li {
                a {
                    margin-left: -12px;
                    position: relative;
                    font-size: 13px;
                    line-height: 34px;
                    color: #585858;
                    padding-left: 12px;
                    &:hover {
                        opacity: .7;
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        top: 8px;
                        left: 0;
                        width: 3px;
                        height: 3px;
                        background-color: #888;
                    }
                }
            }
        }
    }
    &-keyword {
        padding-top: 40px;
        padding-bottom: 50px;
        width: 100%;
        min-width: 1250px;
        background: #f1f1f1;
        &-box {
            min-width: 1000px;
            max-width: 1440px;
            margin: 0 auto;
            font-size: 0;
            text-align: center;
            @media screen and (max-width: 1540px) {
                margin: 0 50px;
            }
            li {
                display: inline-block;
                vertical-align: top;
                a {
                    font-size: 12px;
                    color: #90939a;
                    line-height: 28px;
                    &:hover {
                        color: #b9b9b9;
                    }
                }
                &:after {
                    display: inline-block;
                    vertical-align: top;
                    font-size: 12px;
                    line-height: 28px;
                    padding-left: 15px;
                    padding-right: 15px;
                    content: '|';
                    color: #e0e0e0;
                }
            }
        }
    }
    &-project {
        min-width: 1250px;
        background: #e5e5e5;
        padding: 70px 12px 105px;
        box-sizing: border-box;
        .recommend-project-box {
            max-width: 1400px;
            margin: 0 auto;
            font-size: 0;
        }
        .recommend-project-swiper {
            padding-top: 40px;
            .swiper-slide {
                width: 337px;
            }
        }
        .project-swiper-prev {
            cursor: pointer;
            position: absolute;
            z-index: 10;
            top: 156px;
            left: 20px;
            width: 35px;
            height: 71px;
            background: url(../images/recommend_icon_0.png) rgba(0, 0, 0, .85) no-repeat;
            background-position: 9px 26px;
            transition: all .2s ease;
            &:hover {
                background-color: rgba(0, 0, 0, .6);
            }
            @media screen and (max-width: 1460px) {
                left: 10px;
                top: 126px;
            }
        }
        .project-swiper-next {
            cursor: pointer;
            position: absolute;
            z-index: 10;
            top: 156px;
            right: 20px;
            width: 35px;
            height: 71px;
            transform: rotate(180deg);
            background: url(../images/recommend_icon_0.png) rgba(0, 0, 0, .85) no-repeat;
            background-position: 9px 26px;
            transition: all .2s ease;
            &:hover {
                background-color: rgba(0, 0, 0, .6);
            }
            @media screen and (max-width: 1460px) {
                right: 10px;
                top: 126px;
            }
        }
        .recommend-project-li-box {
            width: 25%;
            box-sizing: border-box;
            padding-left: 8px;
            padding-right: 8px;
            display: inline-block;
            vertical-align: top;
        }
        .recommend-project-li {
            position: relative;
            display: block;
            width: 100%;
            min-height: 120px;
            transition: .3s ease-in-out;
            &-a {
                border: 1px solid #eaeaea;
                position: relative;
                z-index: 10;
                width: 100%;
                height: 100%;
                padding: 20px;
                padding-bottom: 35px;
                box-sizing: border-box;
                background-color: #fff;
            }
            &:hover {
                transform: translate(0px, -5px);
                .text .title {
                    color: #d12322;
                }
                .text .title-li {
                    color: #b41515;
                }
            }
            .img {
                width: 100%;
                img {
                    width: 100%;
                    height: auto !important;
                }
            }
            .text {
                padding: 40px 10px 0px;
                box-sizing: border-box;
                text-align: left;
                .title-li {
                    line-height: 19px;
                    font-size: 19px;
                    color: #000;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    transition: .3s ease-in-out;
                }
                .content {
                    margin-top: 14px;
                    font-size: 13px;
                    line-height: 26px;
                    max-height: 52px;
                    color: #888;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
                .time {
                    padding-top: 20px;
                    color: #888;
                    font-size: 12px;
                    font-family: "Arial";
                    span {
                        &:before {
                            content: '-';
                        }
                    }
                }
            }
        }
    }
}
.padg {
    text-align: center;
    padding-top: 0px;
    padding-bottom: 13px;
    span,
    a {
        display: inline-block;
        height: 42px;
        color: #646464;
        border: 1px solid #dedede;
        line-height: 42px;
        box-sizing: border-box;
        background: #fff;
        margin: 0 8px;
        padding: 0 16px;
        border-radius: 5px;
        font-size: 13px;
        transition: all .2s ease;
    }
    a:hover,
    .padg-now {
        background: #d12322;
        border-color: #d12322;
        color: #fff;
    }
    .padg-list {
        display: inline;
    }
}
@media screen and (max-width: 1460px) {
    .recommend-title h5 {
        font-size: 28px;
        line-height: 28px;
    }
    .recommend-title h6 {
        font-size: 13px;
        line-height: 40px;
    }
    .recommend-case {
        padding-bottom: 30px;
    }
    .recommend-case-box {
        max-width: auto;
        min-width: 0;
        width: 1120px;
    }
    .recommend-case-box2 {
        padding: 0 6px;
    }
    .recommend-case-swiper {
        height: 420px;
        padding-top: 20px;
        padding-bottom: 0px;
    }
    .recommend-keyword {
        padding-top: 35px;
        padding-bottom: 35px;
    }
    .recommend-case .img {
        height: 200px;
    }
    .recommend-case .text {
        padding-left: 0;
    }
    .recommend-case .recommend-case-prev {
        left: 33px;
    }
    .recommend-case .recommend-case-next {
        right: 33px;
    }
    .recommend-business {
        padding: 60px 12px 50px;
    }
    .recommend-business-box {
        margin-top: 20px;
        height: 470px;
        max-width: none;
        min-width: 0;
        width: 1160px;
    }
    .recommend-business-li .img h5 {
        margin-top: 10px;
        font-size: 20px;
        line-height: 20px;
    }
    .recommend-business-li .img h6 {
        margin-top: 10px;
        font-size: 14px;
    }
    .recommend-business ul {
        margin-top: 220px;
    }
    .recommend-project {
        padding-bottom: 50px;
        padding-top: 50px;
    }
    .recommend-project .recommend-project-box {
        max-width: none;
        width: 1120px;
        margin-top: 20px;
    }
    .recommend-project .recommend-project-li-a {
        padding: 10px;
    }
    .recommend-project .recommend-project-li .text {
        padding-top: 20px;
        padding-bottom: 10px;
    }
}
.articleBottom .keyword {
    padding-top: 0px !important;
}
.caseInfoart {
    .custom-article {
        font-size: 13px;
        line-height: 26px;
        color: #777;
        width: 1200px;
        margin: 0 auto;
        padding: 40px 0;
        img {
            max-width: 100%;
        }
        p {
            margin-bottom: 20px;
        }
    }
}
.visuallyhidden {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(0px 0px 99.9% 99.9%);
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0;
}
.xmp-con {
    position: fixed;
    left: 0;
    bottom: 110px;
    z-index: 10000;
    .box {
        position: absolute;
        left: 0;
        bottom: 0;
        background: #fff;
        border-radius: 16px;
        overflow: hidden;
        text-align: center;
        width: 150px;
        padding: 10px 0;
        font-size: 14px;
        color: #333;
        z-index: 2;
        display: none;
        img {
            margin-bottom: 10px;
            width: 110px;
        }
    }
    .icon {
        margin-left: 1px;
    }
    &:hover {
        .box {
            display: block;
        }
        .icon {
            display: none;
        }
    }
}
.wp-1440 {
    max-width: 1440px;
    min-width: 1250px;
    margin: 0 auto;
}
.crumbs {
    color: #fff;
    text-align: right;
    font-size: 14px;
    margin-top: 50px;
    a {
        color: #fff;
        transition: .3s;
        display: inline-block;
        vertical-align: bottom;
        &:hover {
            color: #b60a00;
        }
    }
    span {
        display: inline-block;
        max-width: 12em;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        vertical-align: bottom;
    }
}

.chat-53ai-toggle-btn {
    position: fixed;
    right: 0;
    top: 38%;
    z-index: 999;
    cursor: pointer;
    .chat-btn-img {
        width: 90px;
        height: 90px;
        margin: 0 auto -30px;
        border-radius: 90px;
        overflow: hidden;
        position: relative;
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
    .chat-btn-txt {
        width: 136px;
        height: 42px;
        line-height: 42px;
        padding: 0 10px;
        box-sizing: border-box;
        border-radius: 42px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 4px  rgba(37, 99, 235, .6);
        position: relative;
        z-index: 2;
        font-size: 18px;
        font-weight: 500;
        color: rgba(37, 99, 235, 1);
    }
}

.chat-53ai-content {
    display: none;
    position: fixed;
    box-shadow: rgba(150, 150, 150, .2) 0px 10px 30px 0px, rgba(150, 150, 150, .2) 0px 0px 0px 1px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    width: 440px;
    height: 100%; 
    z-index: 2147483647;
    background-color: rgb(243, 244, 246);
    .chat-53ai-close {
        position: absolute;
        top: 8px;
        right: 30px;
        z-index: 1000;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        i {
            font-size: 12px;
            color: rgb(46, 53, 70);
        }
    }
}