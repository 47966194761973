.service-dialog-box {
    // position: absolute;
    position: relative;
    // top: 50%;
    // left: 50%;
    z-index: 2;
    width: 580px;
    height: 230px;
    // margin: -115px 0 0 -255px;
    background: linear-gradient(-45deg, #4ecdf6, #7f72f9);
    border-radius: 12px;
    overflow: hidden;
    box-sizing: border-box;
    padding: 40px;
    display: flex;
    align-items: center;
}

.service-dialog-box .ewm {
    width: 140px;
    height: 140px;
    background: #fff;
    padding: 8px;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, .4);
    box-sizing: content-box;
}

.service-dialog-box .ewm img {
    width: 100%;
    height: 100%;
}

.service-dialog-box .info {
    flex: 1;
    overflow: hidden;
    margin-left: 30px;
}

.service-dialog-box .info .s {
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    line-height: normal;
}

.service-dialog-box .info .p {
    color: #fff;
    font-size: 16px;
}

.service-dialog-box .close {
    position: absolute;
    color: #fff;
    z-index: 5;
    cursor: pointer;
    font-size: 30px;
    top: 10px;
    right: 20px;
    opacity: 1;
    font-weight: normal;
    text-shadow: none;
}