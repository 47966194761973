/*晃动*/
@keyframes seconddiv {
    0% {transform: rotate(0deg);}
    25% {transform: rotate(10deg);}
    50% {transform: rotate(0deg);}
    75% {transform: rotate(-10deg);}
    100% {transform: rotate(0deg);}
}
.clickHere {
    display: block;
    width: 100%;
    height: 180px;
    text-align: center;
    p {
        display: inline-block;
        vertical-align: top;
        line-height: 180px;
        text-align: center;
        font-size: 40px;
        color: #fff;
        font-weight: bold;
    }
    .icon1 {
        position: relative;
        margin-top: 40px;
        width: 190px;
        height: 102px;
        display: inline-block;
        vertical-align: top;
        background: url(../images/clickHere_1.png) no-repeat;
        background-size: 100% 100%;
        .eye1 {
            position: absolute;
            left: 9px;
            top: 15px;
            width: 72px;
            height: 72px;
            border-radius: 50%;
            &-1 {
                position: absolute;
                top: 18px;
                left: 18px;
                width: 36px;
                height: 36px;
                transition: all 1s ease;
                background: url(../images/clickHere_2.png) no-repeat;
            }
        }
        .eye2 {
            position: absolute;
            left: 104px;
            top: 15px;
            width: 72px;
            height: 72px;
            border-radius: 50%;
            &-1 {
                position: absolute;
                top: 18px;
                left: 18px;
                width: 36px;
                height: 36px;
                transition: all 1s ease;
                background: url(../images/clickHere_2.png) no-repeat;
            }
        }
    }
    .icon2 {
        margin-left: 20px;
        margin-top: 65px;
        display: inline-block;
        vertical-align: top;
        width: 75px;
        height: 75px;
        background: url(../images/clickHere_0.png) no-repeat;
        background-size: 100% 100%;
    }
}
/*底部*/
.footer {
    box-shadow: 2px 3px 73px rgba(2, 2, 2, .6);
    width: 100%;
    min-width: 1250px;
    background: #000 !important;
    //height: 304px;
    padding-top: 70px;
    box-sizing: border-box;
    &-left {
        float: left;
        width: 436px;
    }
    &-right {
        float: right;
        width: 790px;
    }
    &-box {
        padding: 0 20px;
        margin: 0 auto;
        max-width: 1483px;
        min-width: 1250px;
        box-sizing: border-box;
        .t {
            color: #fff;
            font-size: 16px;
        }
        .info {
            font-size: 14px;
            color: rgba(#a6a6a6, .5);
            margin-top: 20px;
        }
        .address {
            width: 435px;
            &:hover {
                .icon {
                    animation: seconddiv .3s;
                }
            }
            .icon {
                display: inline-block;
                vertical-align: top;
                width: 57px;
                height: 57px;
                border-radius: 50%;
                text-align: center;
                background: #c91200 none no-repeat center center;
            }
            .text {
                padding-left: 20px;
                display: inline-block;
                vertical-align: top;
                .phone {
                    padding-top: 3px;
                    line-height: 30px;
                    font-size: 30px;
                    color: #efefef;
                }
                .address-text {
                    opacity: .5;
                    padding-top: 7px;
                    font-size: 14px;
                    color: #a6a6a6;
                }
            }
        }
        .address + .address {
            margin-top: 42px;
        }
        .footer-ul {
            padding-bottom: 16px;
            border-bottom: 1px dashed rgba(124, 124, 124, .4);
            li {
                padding-left: 22px;
                padding-right: 22px;
                display: inline-block;
                vertical-align: top;
                background: url(../images/footer_2.png) no-repeat center right;
                &:last-child {
                    background: none;
                    padding-right: 0;
                }
                &:first-child {
                    padding-left: 0;
                }
            }
            a {
                color: #f4f4f4;
                font-size: 13.04px;
                transition: all .2s ease;
                &:hover {
                    color: #b41515;
                }
            }
        }
        .footer-ul1 {
            padding-top: 10px;
            span {
                display: inline-block;
                vertical-align: top;
                color: #7c7c7c;
                line-height: 36px;
                font-size: 12.04px;
            }
            ul {
                display: inline-block;
                vertical-align: top;
            }
            li {
                display: inline-block;
                vertical-align: top;
                background: url(../images/footer_2.png) no-repeat center right;
                padding-right: 12px;
                .a {
                    line-height: 36px;
                    color: #7c7c7c;
                    font-size: 12.04px;
                    transition: all .2s ease;
                    &:hover {
                        color: #fff;
                    }
                }
                &:first-child {
                    background: none;
                    padding: 0;
                }
            }
        }
        .share {
            padding-top: 22px;
            a {
                background-position: 0px;
                margin-right: 16px;
            }
            .bds_qzone {
                background: url(../images/footer_3.png) no-repeat center center;
            }
            .bds_weixin {
                background: url(../images/footer_4.png) no-repeat center center;
            }
            .bds_tsina {
                background: url(../images/footer_5.png) no-repeat center center;
            }
        }
    }
}
.footer2 {
    background: #000;
    width: 100%;
    min-width: 1250px;
    text-align: center;
    span {
        color: #a9a9a9;
        font-size: 14px;
        line-height: 86px;
    }
    a {
        color: #a9a9a9;
        &:hover {
            text-decoration: underline;
            color: #fff;
        }
    }
}
.adviser-popup {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background: linear-gradient(90deg, #fff, #f0f0f0);
    border-radius: 8px;
    overflow: hidden;
    z-index: 1003;
    &-mask {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .6);
        z-index: 1002;
    }
    &-close {
        position: absolute;
        top: 26px;
        right: 30px;
        font-size: 18px;
        font-weight: bold;
        color: #909399;
        cursor: pointer;
        transition: all .3s;
        z-index: 3;
        &:hover {
            color: $wescrmColor2;
        }
    }
    &-top {
        padding: 25px 30px 32px;
    }
    &-box {
        display: flex;
        align-items: center;
    }
    .title {
        margin-bottom: 36px;
        font-size: 18px;
        color: #000;
    }
    .avatar {
        margin-right: 22px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        overflow: hidden;
        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .info {
        flex: 1;
        min-width: 0;
        &-top {
            line-height: 30px;
            color: #000;
            span {
                display: inline-block;
                vertical-align: top;
            }
            &-name {
                font-size: 24px;
            }
            &-tag {
                padding-top: 2px;
                font-size: 14px;
                &:before {
                    content: '| ';
                }
            }
        }
        &-tel {
            display: block;
            margin-top: 6px;
            font-size: 18px;
            color: #666;
            i {
                margin-right: 2px;
                color: $wescrmColor2;
            }
        }
    }
    &-bottom {
        position: relative;
        display: flex;
        padding: 25px 30px 50px;
        background-color: $wescrmColor2;
        &:before {
            content: '';
            position: absolute;
            bottom: 100%;
            left: 124px;
            border-width: 0 9px 9px;
            border-color: transparent transparent $wescrmColor2;
            border-style: solid;
            z-index: 5;
        }
    }
    .qrcode {
        margin-top: -75px;
        padding: 12px;
        width: 104px;
        height: 104px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 2px 2px 4px rgba(7, 116, 208, .3);
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
    .text {
        position: relative;
        flex: 1;
        min-width: 0;
        margin-right: 20px;
        padding-left: 16px;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
        &:before {
            content: '“';
            position: absolute;
            top: 0;
            left: 0;
            font-size: 40px;
            line-height: 1;
            color: #fff;
            font-family: Arial;
        }
        &:after {
            content: '”';
            display: inline-block;
            vertical-align: top;
            margin-left: 4px;
            height: 24px;
            line-height: 1.4;
            font-size: 40px;
            color: rgba(255, 255, 255, .4);
            font-family: Arial;
        }
    }
}
.footer-form {
    position: relative;
    min-width: 1250px;
    &:before {
        position: absolute;
        width: 50%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #1e2a47;
        content: '';
        display: block;
    }
    &:after {
        position: absolute;
        width: 50%;
        height: 100%;
        top: 0;
        right: 0;
        background-color: #bd2206;
        content: '';
        display: block;
    }
    .wp1600 {
        margin: 0 auto;
        display: flex;
        max-width: 1600px;
        min-width: 1250px;
        position: relative;
        z-index: 2;
        .item {
            flex: 1;
            overflow: hidden;
        }
    }
}
.footer-form-btn {
    height: 180px;
    text-align: center;
    cursor: pointer;
    p {
        display: inline-block;
        vertical-align: top;
        line-height: 180px;
        text-align: center;
        font-size: 40px;
        color: #fff;
        font-weight: bold;
    }
    .icon2 {
        margin-left: 20px;
        margin-top: 65px;
        display: inline-block;
        vertical-align: top;
        width: 75px;
        height: 75px;
        background: url(../images/clickHere_0.png) no-repeat;
        background-size: 100% 100%;
    }
}
.footer-form-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10001;
    backface-visibility: hidden;
    display: none;
    .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(#000, .5);
    }
    .box {
        width: 680px;
        //min-height: 600px;
        max-height: 90%;
        top: 50%;
        left: 50%;
        background-color: #fff;
        transform: translate(-50%, -50%);
        z-index: 5;
        backface-visibility: hidden;
        box-sizing: border-box;
        padding: 60px;
        position: absolute;
    }
    .close {
        position: absolute;
        color: #000;
        font-size: 30px;
        top: 20px;
        right: 20px;
        z-index: 4;
        cursor: pointer;
        transition: all 1s;
        &:hover {
            transform: rotate(360deg);
        }
    }
    .top {
        position: relative;
        text-align: center;
        margin-bottom: 40px;
        padding-bottom: 15px;
        &:after {
            position: absolute;
            left: 50%;
            bottom: 0;
            content: '';
            width: 70px;
            height: 3px;
            margin-left: -35px;
            background-color: #ca1f0f;
        }
        .b {
            font-size: 38px;
            font-weight: bold;
            color: #000;
        }
        .p {
            font-size: 18px;
            color: #666;
        }
    }
    .footer-form-cell {
        width: 500px;
        max-width: 100%;
        margin: 0 auto 30px;
        display: flex;
        border-radius: 4px;
        overflow: hidden;
        background: #eee;
        .word {
            padding-left: 17px;
            font-size: 16px;
            color: #666;
            line-height: 50px;
        }
        .input {
            flex: 1;
            overflow: hidden;
            margin-left: 10px;
            input {
                display: block;
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 16px;
                color: #666;
                appearance: none;
                border: none;
                background: none;
            }
        }
        .ver-img {
            margin-left: 10px;
            background-color: #fff;
            img {
                height: 50px;
            }
        }
    }
    button {
        cursor: pointer;
        border: none;
        appearance: none;
        color: #fff;
        transition: all .2s;
        width: 300px;
        height: 50px;
        line-height: 50px;
        font-size: 20px;
        background: #d42628;
        margin: 0 auto;
        display: block;
        &:hover {
            background: lighten(#d42628, 5%);
        }
    }
}
@media screen and (max-width: 1460px) {
    .footer {
        padding-top: 50px;
        //height: 270px;
    }
    .clickHere {
        height: 130px;
    }
    .clickHere p {
        line-height: 130px;
        font-size: 28px;
    }
    .footer-form-btn {
        height: 130px;
        p {
            line-height: 130px;
        }
        .icon2 {
            margin-top: 42px;
        }
    }
    .clickHere .icon1 {
        margin-top: 15px;
    }
    .clickHere .icon2 {
        margin-top: 43px;
    }
}
@media screen and (max-width: 1365px) {
    .footer-right {
        width: 720px;
    }
}