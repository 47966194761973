// wescrm home
.wescrm-index-banner {
    position: relative;
    width: 100%;
    min-width: 1250px;
    height: 562px;
    overflow: hidden;
    .img {
        width: 100%;
        height: 100%;
        background: #fff none no-repeat center center;
        background-size: auto 100%;
    }
    .banner-pagination {
        position: absolute;
        bottom: 40px;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 9;
        .swiper-pagination-bullet {
            margin: 0 5px;
            background-color: #fff;
            opacity: .5;
            outline: none;
            border-radius: 8px;
            transition: all .3s;
            &-active {
                opacity: 1;
                width: 20px;
            }
        }
    }
}
.wescrm-banner-info {
    position: absolute;
    top: 184px;
    left: calc((100% - 1200px) / 2 + 12px);
    right: calc((100% - 1200px) / 2 + 12px);
    z-index: 8;
    .title {
        line-height: 1.2;
        font-size: 46px;
        color: #fff;
        letter-spacing: 4px;
    }
    .p {
        margin-top: 16px;
        line-height: 1.6;
        font-size: 30px;
        color: #fff;
        letter-spacing: 3px;
    }
    .btn {
        display: inline-block;
        margin-top: 40px;
        min-width: 150px;
        padding: 0 px2rem(15px);
        text-align: center;
        height: 48px;
        line-height: 48px;
        font-size: 18px;
        border-radius: px2rem(4px);
        cursor: pointer;
        transition: all .3s;
        color: $wescrmColor2;
        background-color: #fff;
        &:hover {
            color: #fff;
            background-color: $wescrmColor2;
        }
    }
    &-center {
        text-align: center;
        .btn {
            color: #fff;
            background-color: $wescrmColor2;
            &:hover {
                background-color: #005ddc;
            }
        }
    }
}
.wescrm-index-title {
    margin-bottom: 35px;
    text-align: center;
    h2,
    h3 {
        font-size: 30px;
        color: #000;
    }
    p {
        margin-top: 5px;
        line-height: 1.5;
        font-size: 16px;
        color: #666;
    }
}
.wescrm-customer {
    padding-top: 55px;
    min-width: 1250px;
    &-menu {
        display: flex;
        justify-content: center;
        li {
            position: relative;
            display: flex;
            align-items: center;
            margin: 0 62px;
            padding: 5px 32px 35px;
            font-size: 18px;
            color: #666;
            transition: all .3s;
            cursor: pointer;
            &:before {
                content: '';
                position: absolute;
                bottom: -2px;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 4px;
                background-color: $wescrmColor2;
                border-radius: 2px;
                opacity: 0;
                transition: width .4s;
            }
            &:not(:last-child):after {
                content: '';
                position: absolute;
                right: -82px;
                top: 9px;
                width: 36px;
                height: 24px;
                background: url(../images/wescrm/icon5.png) center no-repeat;
                background-size: contain;
                z-index: -1;
                transition: all .3s;
            }
            &.on {
                color: $wescrmColor2;
                &:before {
                    opacity: 1;
                    width: 100%;
                }
                .icon {
                    &-default {
                        display: none;
                    }
                    &-active {
                        display: inline-block;
                    }
                }
            }
            &.loaded:after {
                background-image: url(../images/wescrm/icon5_h.png);
            }
        }
        img {
            display: inline-block;
            max-width: 100%;
            height: auto;
        }
        .icon {
            margin-right: 8px;
            width: 30px;
            &-active {
                display: none;
            }
        }
    }
    &-main {
        background-color: #f7f8fa;
    }
    &-box {
        display: none;
        padding: 30px 0;
        &:first-child {
            display: block;
        }
        .box-wp {
            display: flex;
        }
        .info {
            flex: 1;
            min-width: 0;
            margin: 88px 155px 0 0;
            &-title {
                line-height: 1.3;
                font-size: 30px;
                font-weight: bold;
                color: $wescrmColor;
            }
            &-description {
                margin-top: 10px;
                line-height: 1.5;
                font-size: 18px;
                color: #000;
            }
            &-p {
                margin-top: 25px;
                line-height: 26px;
                font-size: 16px;
                color: #666;
            }
        }
        .img {
            width: 564px;
            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }
    }
}
.wescrm-product {
    padding: 55px 0 40px;
    min-width: 1250px;
    &-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        li {
            padding: 0 10px 20px;
            width: (100% / 3);
            box-sizing: border-box;
        }
        a {
            display: block;
            height: 100%;
            padding: 10px 30px 20px;
            border: 1px solid #f0f0f0;
            box-shadow: 0 0 20px rgba(0, 0, 0, .06);
            box-sizing: border-box;
            transition: all .3s;
            &:hover {
                background: linear-gradient(-100deg, rgb(74, 158, 255) 0%, rgb(114, 123, 253) 100%);
                .num {
                    color: #5fa2ff;
                }
                h3,
                .p {
                    color: #fff;
                }
                .icon {
                    &-default {
                        display: none;
                    }
                    &-active {
                        display: inline-block;
                    }
                }
                h3:after {
                    opacity: 1;
                }
            }
        }
        .num {
            line-height: 1;
            font-weight: bold;
            font-size: 70px;
            font-family: Microsoft YaHei;
            color: #f9f9f9;
            transition: all .3s;
        }
        .title {
            display: flex;
            margin: 4px 0 12px;
        }
        img {
            display: inline-block;
            vertical-align: top;
            max-width: 100%;
            height: auto;
        }
        .icon {
            margin-right: 8px;
            width: 33px;
            text-align: center;
            &-active {
                display: none;
            }
        }
        h3 {
            flex: 1;
            min-width: 0;
            line-height: 24px;
            font-size: 18px;
            color: #000;
            transition: all .3s;
            &:after {
                content: '\e641';
                display: inline-block;
                vertical-align: middle;
                margin: -2px 0 0 12px;
                font-family: 'iconfont';
                color: #fff;
                font-size: 14px;
                opacity: 0;
                transition: all .3s;
            }
        }
        .p {
            min-height: 72px;
            font-size: 14px;
            line-height: 24px;
            color: #666;
            transition: all .3s;
        }
    }
}
.wescrm-prev,
.wescrm-next {
    position: absolute;
    top: 50%;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 22px;
    color: $wescrmColor2;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(51, 153, 255, .5);
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    z-index: 6;
    transition: all .3s;
    &:hover {
        color: #fff;
        background-color: $wescrmColor2;
    }
}
.wescrm-prev {
    left: 0;
    transform: translateY(-50%) rotate(180deg);
}
.wescrm-next {
    right: 0;
    transform: translateY(-50%);
}
.wescrm-case {
    padding: 55px 0 60px;
    background-color: #f7f8fa;
    min-width: 1250px;
    &-box {
        position: relative;
        margin-top: -20px;
        padding: 0 64px;
    }
    &-swiper {
        padding: px2rem(20px);
        .swiper-slide {
            opacity: 0;
            height: auto;
            transition: opacity .3s;
            &-visible {
                opacity: 1;
            }
        }
        a {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 30px 20px 26px;
            background-color: #fff;
            box-sizing: border-box;
            transition: all .4s;
            &:hover {
                transform: translateY(-4px);
                box-shadow: 0 6px 16px rgba(0, 0, 0, .1);
            }
        }
        .icon {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
            }
        }
        h3 {
            margin: 18px 0 20px;
            font-size: 18px;
            color: #000;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .p {
            flex-grow: 1;
            line-height: 24px;
            font-size: 14px;
            color: #666;
            transition: all .3s;
        }
        .more {
            margin-top: 30px;
            font-size: 16px;
            color: $wescrmColor2;
            &:after {
                content: '\e641';
                margin-left: 5px;
                font-family: 'iconfont';
                font-size: 14px;
            }
        }
    }
}
.wescrm-more {
    display: block;
    margin: 20px auto 0;
    width: 235px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    color: #666;
    border: 1px solid #79797a;
    border-radius: 3px;
    transition: all .3s;
    &:after {
        content: '\e641';
        margin-left: 8px;
        font-family: 'iconfont';
        font-size: 16px;
    }
    &:hover {
        color: #fff;
        background-color: $wescrmColor2;
        border-color: $wescrmColor2;
    }
}
.wescrm-honor {
    margin: 55px 0 60px;
    min-width: 1250px;
    &-box {
        position: relative;
        margin: -20px 0;
        padding: 0 64px;
    }
    &-swiper {
        padding: 20px;
        .swiper-slide {
            opacity: 0;
            height: auto;
            transition: opacity .3s;
            &-visible {
                opacity: 1;
            }
        }
        a {
            display: block;
            padding: 50px 24px 40px;
            height: 100%;
            text-align: center;
            border: 1px solid #f0f0f0;
            background: #fff url(../images/wescrm/bg2.jpg) top center no-repeat;
            background-size: 238px auto;
            box-shadow: 0 0 px2rem(20px) rgba(0, 0, 0, .06);
            box-sizing: border-box;
            transition: all .5s;
            &:hover {
                transform: scale(1.05);
            }
        }
        .num {
            line-height: 1;
            font-family: 'BodoniBookBT';
            font-size: 60px;
            color: #dcdcdc;
        }
        h3 {
            margin: 35px 0 12px;
            line-height: 28px;
            font-size: 18px;
            color: #000;
        }
        .origin {
            line-height: 1.3;
            font-size: 14px;
            color: #666;
            &:after {
                content: '';
                display: block;
                margin: 7px auto 0;
                width: 20px;
                height: 2px;
                background-color: $wescrmColor2;
                border: 1px solid #bbdefb;
            }
        }
    }
}
.wescrm-banner {
    position: relative;
    width: 100%;
    min-width: 1250px;
    height: 0;
    padding-bottom: (865 / 1920) * 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.wescrm-inside-banner {
    position: relative;
    width: 100%;
    height: 562px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100%;
}
.wescrm-banner-about {
    position: absolute;
    top: 50%;
    left: 30px;
    right: 30px;
    margin-top: 10px;
    transform: translateY(-50%);
    text-align: center;
    line-height: 70px;
    font-size: 46px;
    color: #fff;
    letter-spacing: 5px;
    z-index: 8;
}
.wescrm-design-consult {
    padding: 42px 30px 46px;
    text-align: center;
    background: url(../images/wescrm/bg1.jpg) center center no-repeat;
    background-size: cover;
    p {
        line-height: 1.4;
        font-size: 30px;
        color: #fff;
    }
    .btn {
        display: block;
        margin: 20px auto 0;
        width: 218px;
        height: 45px;
        line-height: 45px;
        font-size: 16px;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 3px;
        overflow: hidden;
        cursor: pointer;
        transition: all .3s;
        &:hover {
            color: $wescrmColor;
            background-color: #fff;
        }
    }
}
.wescrm-article {
    @include articleReset(18px);
    line-height: 27px;
    color: #666;
    overflow: hidden;
    p {
        margin-bottom: 10px;
    }
}
.wescrm-article-title {
    margin-bottom: 25px;
    line-height: 1.3;
    font-size: 30px;
    color: #000;
}
.wescrm-session,
.platform-box {
    padding: px2rem(60px) 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &:nth-child(even) {
        background-color: #f7f8fa;
    }
    &:nth-child(even) .wescrm-session-imginfo,
    &:nth-child(odd) .chain-box-imginfo {
        .info {
            margin-right: 120px;
        }
        .info-margin80 {
            margin-right: 80px;
        }
        .info-margin60 {
            margin-right: 60px;
        }
        .img-width650 {
            text-align: right;
        }
    }
    &:nth-child(odd) .wescrm-session-imginfo,
    &:nth-child(even) .chain-box-imginfo {
        .info {
            order: 2;
            margin-left: 120px;
        }
        .info-margin80 {
            margin-left: 80px;
        }
        .info-margin60 {
            margin-left: 60px;
        }
        .img-width650 {
            text-align: left;
        }
    }
    .wescrm-session-imginfo,
    .chain-box-imginfo {
        display: flex;
        .info {
            flex: 1;
            min-width: 0;
        }
        .img {
            max-width: 730px;
            text-align: center;
        }
        .img-max {
            width: 620px;
        }
        .img-width650 {
            width: 650px;
        }
        .img-width600 {
            width: 600px;
        }
        .img-width730 {
            width: 730px;
        }
        .img-marginl {
            margin-left: -30px;
        }
        .img-marginr {
            margin-right: -30px;
        }
        .max-width430 {
            max-width: 430px;
        }
        .max-width460 {
            max-width: 460px;
        }
        .info-margintop25 {
            margin-top: 25px;
        }
    }
    .img-center {
        padding: 30px 0 10px;
        text-align: center;
    }
}
.wescrm-session {
    padding: px2rem(60px) 0;
    .wescrm-session-imginfo,
    .chain-box-imginfo {
        .info {
            padding-top: 80px;
        }
    }
}
.platform-box {
    padding: 115px 0;
    .wescrm-session-imginfo,
    .chain-box-imginfo {
        .info {
            padding-top: 20px;
        }
    }
}
.brand-chain-weak {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    li {
        padding: 20px;
        width: 50%;
        list-style: none !important;
        box-sizing: border-box;
    }
    .box {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 36px 60px 30px 36px;
        background-color: #fff;
        box-shadow: 5px 10px 36px rgba(153, 153, 153, .2);
        border-radius: 8px;
        box-sizing: border-box;
        background-repeat: no-repeat;
        background-position: center;
        -webkit-background-size: cover;
        background-size: cover;
    }
    .icon {
        margin-right: 36px;
        width: 114px;
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
    .info {
        flex: 1;
        min-width: 0;
    }
    .title {
        margin-bottom: 20px;
        line-height: 1.4;
        font-size: 22px;
        color: #000;
    }
    p {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 24px;
        color: #666;
    }
}
.tob-sale-weak {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    li {
        padding: 20px;
        width: 50%;
        list-style: none !important;
        box-sizing: border-box;
    }
    .box {
        display: flex;
        height: 100%;
        padding: 36px 10px 10px 50px;
        background-color: #fff;
        box-shadow: 5px 10px 36px rgba(153, 153, 153, .2);
        border-radius: 8px;
        box-sizing: border-box;
    }
    .icon {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        text-align: right;
        margin-left: 40px;
        width: 152px;
        img {
            max-width: 100%;
        }
    }
    .info {
        flex: 1;
        min-width: 0;
        padding: 8px 0 24px;
    }
    .title {
        margin-bottom: 20px;
        line-height: 1.4;
        font-size: 22px;
        color: #000;
    }
    p {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 24px;
        color: #666;
    }
}
.chain-list-example {
    display: flex;
    flex-wrap: wrap;
    margin: -40px -32px 0;
    width: 800px;
    li {
        padding: 40px 32px 0;
        width: (100% / 3);
        list-style: none !important;
        box-sizing: border-box;
    }
    .icon {
        text-align: center;
        height: 91px;
        img {
            display: inline-block;
            height: 100% !important;
            width: auto;
        }
    }
    .title {
        margin: 20px 0 8px;
        text-align: center;
        line-height: 1.4;
        font-size: 18px;
        color: #333;
    }
    p {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 27px;
        color: #666;
        font-weight: lighter;
    }
}
.session-case-list1 {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin: 0 auto;
    max-width: 770px;
    color: #fff;
    li {
        position: relative;
        width: 110px;
        height: 110px;
        line-height: 110px;
        font-size: 30px;
        background: linear-gradient(-35deg, rgba(92, 176, 255, .8) 0%, rgb(56, 134, 255) 100%);
        border: 15px solid #d8e8fb;
        list-style: none !important;
        border-radius: 50%;
        &:not(:last-child):before {
            content: '';
            position: absolute;
            left: 120px;
            top: 50%;
            margin-top: -27px;
            width: 167px;
            height: 55px;
            background: url(../images/wescrm/icon23.png) left center no-repeat;
            background-size: contain;
            z-index: -1;
        }
    }
}
.session-case-list2 {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin: 50px auto 0;
    max-width: 770px;
    line-height: 1.4;
    color: #000;
    li {
        width: 140px;
        list-style: none !important;
        img {
            display: block;
            margin: 0 auto 14px;
            width: 76px;
            height: auto;
        }
    }
}
.chat-tools-weak {
    display: flex;
    flex-wrap: wrap;
    margin: -10px -10px 0;
    li {
        padding: 20px 10px 0;
        width: 25%;
        list-style: none !important;
        box-sizing: border-box;
    }
    .box {
        padding: 40px 20px;
        height: 100%;
        text-align: center;
        border: 1px solid #f0f0f0;
        box-shadow: 0 0 20px rgba(0, 0, 0, .06);
        box-sizing: border-box;
    }
    .icon {
        margin: 0 auto;
        width: 100px;
        height: 100px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .title {
        margin: 25px 0 15px;
        font-size: 18px;
        color: #000;
    }
    .no-line {
        margin-bottom: 20px;
    }
    p {
        margin-bottom: 0;
        line-height: 24px;
        font-size: 14px;
        color: #666;
    }
    .bottom-line {
        &:after {
            content: '';
            display: block;
            margin: 32px auto 0;
            width: 32px;
            height: 4px;
            background-color: #ccc;
        }
    }
}
.session-case-step {
    position: relative;
    display: flex;
    padding-top: 8px;
    &:before {
        content: '';
        position: absolute;
        top: 168px;
        left: 70px;
        right: 70px;
        border-bottom: 2px dashed #66b4f6;
    }
    li {
        position: relative;
        padding: 0 20px;
        width: 25%;
        text-align: center;
        list-style: none !important;
        z-index: 2;
        box-sizing: border-box;
    }
    .icon {
        position: relative;
        margin-bottom: 110px;
        height: 100px;
        line-height: 100px;
        img {
            display: inline-block;
            vertical-align: middle;
            max-width: 100%;
            max-height: 100%;
        }
        &:after {
            content: '\e641';
            position: absolute;
            top: 148px;
            left: 50%;
            transform: translateX(-50%);
            font-family: 'iconfont';
            font-weight: bold;
            line-height: 1;
            font-size: 26px;
            color: #66b4f6;
        }
    }
    p {
        margin-bottom: 0;
    }
    .step {
        line-height: 1;
        font-weight: bold;
        font-size: 40px;
        color: #eeeff0;
    }
    .text {
        margin-top: -12px;
        line-height: 1.4;
        font-size: 18px;
        color: #000;
    }
}
.session-advantage-list {
    display: flex;
    flex-wrap: wrap;
    margin: -5px -20px 0;
    p {
        margin-bottom: 0;
        line-height: 1.4;
        font-size: 18px;
        color: #333;
    }
    li {
        margin-top: 30px;
        padding: 0 20px;
        width: (100% / 6);
        text-align: center;
        list-style: none !important;
        box-sizing: border-box;
    }
    .icon {
        margin-bottom: 20px;
        height: 100px;
        line-height: 100px;
        img {
            display: inline-block;
            vertical-align: middle;
            max-width: 100%;
            max-height: 100%;
        }
        &-radius {
            margin-left: auto;
            margin-right: auto;
            width: 100px;
            border-radius: 50%;
            box-shadow: -13px 0 59px rgba(153, 153, 153, .3);
        }
    }
}
.scrm-model-list {
    display: flex;
    li {
        position: relative;
        flex: 1;
        text-align: center;
        list-style: none !important;
        & + li {
            margin-left: 100px;
        }
        &:first-child:after {
            content: '';
            position: absolute;
            top: 46%;
            right: -134px;
            width: 163px;
            height: 56px;
            background: url(../images/wescrm/icon74.png) center no-repeat;
            -webkit-background-size: contain;
            background-size: contain;
            z-index: 2;
        }
    }
    img {
        max-width: 100%;
    }
    p {
        font-size: 18px;
        color: #666;
        margin-bottom: 0;
    }
}
.parner-classify-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px -20px;
    li {
        padding: 0 10px 20px;
        width: 25%;
        list-style: none !important;
        box-sizing: border-box;
    }
    .box {
        padding: 55px 25px 32px;
        height: 100%;
        border: 1px solid #ebebeb;
        background-color: #fff;
        box-shadow: 0 6px 20px rgba(0, 0, 0, .08);
        box-sizing: border-box;
    }
    .icon {
        height: 120px;
        line-height: 120px;
        text-align: center;
        img {
            display: inline-block;
            vertical-align: middle;
            max-width: 100%;
            max-height: 100%;
        }
    }
    .title {
        margin: 34px 0 15px;
        text-align: center;
        line-height: 1.4;
        font-size: 24px;
        color: $wescrmColor2;
    }
    .p {
        font-size: 16px;
        line-height: 24px;
        color: #666;
    }
}
.partner-reason-list {
    display: flex;
    flex-wrap: wrap;
    margin: -20px -10px 0;
    li {
        padding: 20px 10px 0;
        width: (100% / 3);
        list-style: none !important;
        box-sizing: border-box;
    }
    .box {
        height: 100%;
        text-align: center;
        background-color: #fff;
        box-sizing: border-box;
        box-shadow: 0 6px 20px rgba(0, 0, 0, .08);
    }
    .img {
        position: relative;
        height: 0;
        padding-bottom: (218 / 380) * 100%;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .title {
        position: absolute;
        top: 50%;
        left: 30px;
        right: 30px;
        transform: translateY(-50%);
        line-height: 1.3;
        font-size: 30px;
        color: #fff;
        z-index: 2;
    }
    .p {
        padding: 20px 30px;
        font-size: 14px;
        line-height: 22px;
        color: #666;
    }
}
.partner-support-bd {
    position: relative;
    z-index: 2;
    li {
        position: relative;
        display: none;
        width: 100%;
        height: 320px;
        list-style: none !important;
        overflow: hidden;
        &:first-child {
            display: block;
        }
        img {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100% !important;
        }
    }
    .text {
        position: absolute;
        top: 100px;
        left: 50px;
        width: 540px;
        color: #fff;
        z-index: 2;
        &-title {
            margin-bottom: 16px;
            font-size: 24px;
            line-height: 1.3;
        }
    }
    p {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 24px;
    }
}
.partner-support-hd {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 -15px;
    li {
        position: relative;
        flex: 1;
        padding: 62px 15px 0;
        cursor: pointer;
        list-style: none !important;
        &.on {
            .triangle {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    .icon {
        height: 46px;
        line-height: 46px;
        img {
            display: inline-block;
            vertical-align: middle;
            max-width: 100%;
            max-height: 100%;
            pointer-events: none;
        }
    }
    .triangle {
        position: absolute;
        top: -1px;
        left: 50%;
        margin-left: -27px;
        width: 53px;
        height: 31px;
        opacity: 0;
        visibility: hidden;
        transition: all .3s;
        img {
            max-width: 100%;
            max-height: 100%;
            pointer-events: none;
        }
    }
    p {
        margin: 18px 0 0;
        line-height: 1.3;
        font-size: 18px;
        color: #000;
    }
}
.partner-process-list {
    position: relative;
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding-top: 20px;
    &:before {
        content: '';
        position: absolute;
        top: 165px;
        left: 0;
        right: 0;
        border-top: 1px dashed #999;
    }
    li {
        position: relative;
        width: 150px;
        padding: 0 10px;
        list-style: none !important;
        z-index: 3;
    }
    .icon {
        position: relative;
        margin: 0 auto;
        width: 110px;
        height: 110px;
        background-color: #fff;
        border-radius: 100%;
        border: 1px solid #f4f4f4;
        box-shadow: 0 2px 10px rgba(0, 0, 0, .04);
        img {
            width: 100%;
            height: 100%;
        }
        &:before {
            content: '';
            position: absolute;
            bottom: -40px;
            left: 50%;
            margin-left: -6px;
            width: 12px;
            height: 12px;
            background-color: #999;
            border-radius: 50%;
        }
    }
    p {
        position: relative;
        margin: 56px 0 0;
        line-height: 1.3;
        font-size: 18px;
        color: #000;
    }
}
.wescrm-list-content {
    padding-bottom: 75px;
    min-width: 1250px;
    text-align: center;
    background-color: #f8fbfe;
    @include articleReset(16px);
    line-height: 30px;
    color: #444;
    font-family: "Microsoft YaHei";
    p {
        margin-bottom: 10px;
    }
    .wp1300 {
        padding: 0 50px;
        width: 1420px;
        max-width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
    }
    .title {
        margin-bottom: 20px;
        overflow: hidden;
        h3 {
            line-height: 1.2;
            font-size: 48px;
            color: #333;
            font-family: "Microsoft YaHei";
        }
        p {
            margin: 5px 0 0;
            text-transform: uppercase;
            line-height: 1.4;
            font-size: 16px;
            color: #397cb0;
            span {
                position: relative;
                display: inline-block;
                vertical-align: top;
                padding: 0 25px;
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    height: 1px;
                    width: 344px;
                    background-color: #7b7d7f;
                }
                &:before {
                    right: 100%;
                }
                &:after {
                    left: 100%;
                }
            }
        }
    }
    .bold-blue {
        line-height: 1.2;
        font-size: 42px;
        font-weight: bold;
        color: #397cb0;
    }
    .light-blue {
        line-height: 1.3;
        font-size: 36px;
        color: #397cb0;
    }
    .p-en {
        padding-top: 10px;
        font-size: 12px;
        line-height: 20px;
        color: #d7d7d7;
    }
    @media screen and (max-width: 1440px) {
        .title {
            h3 {
                font-size: 42px;
            }
        }
        .bold-blue {
            font-size: 36px;
        }
        .light-blue {
            font-size: 30px;
        }
    }
}
.wescrm-list-page {
    min-width: 1250px;
    padding: 110px 0 60px;
    background-color: #d1dbe5;
    &-wp {
        margin: 0 auto;
        max-width: 1546px;
        padding: 0 27px;
        box-sizing: border-box;
    }
}
.wescrm-list {
    font-size: 0;
    li {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        padding: 0 23px 70px;
        box-sizing: border-box;
        &:nth-child(even) {
            .img {
                float: left;
            }
            .info {
                margin-left: -30px;
                padding-left: 75px;
                padding-right: 135px;
                border-top-right-radius: 95px;
                border-bottom-right-radius: 95px;
            }
        }
        &:nth-child(odd) {
            h3 {
                text-align: right;
            }
            .info {
                margin-right: -30px;
                padding-right: 75px;
                padding-left: 135px;
                border-top-left-radius: 95px;
                border-bottom-left-radius: 95px;
            }
        }
    }
    a {
        display: block;
        transition: all .4s;
        &:hover {
            transform: translateY(-8px);
        }
    }
    .info {
        display: inline-block;
        vertical-align: top;
        margin-top: 95px;
        padding-top: 100px;
        padding-bottom: 68px;
        width: calc(100% - 285px);
        background-color: #f0f3f6;
        box-sizing: border-box;
    }
    h3 {
        margin-bottom: 10px;
        line-height: 1.4;
        font-size: 30px;
        color: #2469a5;
    }
    .p {
        height: 280px;
        line-height: 28px;
        font-size: 14px;
        color: #666;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
        word-break: break-word;
    }
    .img {
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin: 0 -25px -35px;
        padding: 81px 44px 109px;
        width: 365px;
        height: 677px;
        background: url(../images/wescrm/mobile_bg.png) center no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        z-index: 2;
        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    @media screen and (max-width: 1600px) {
        li {
            &:nth-child(even) {
                .info {
                    padding-right: 100px;
                }
            }
            &:nth-child(odd) {
                .info {
                    padding-left: 100px;
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        li {
            &:nth-child(even) {
                .info {
                    padding-left: 65px;
                    padding-right: 70px;
                }
            }
            &:nth-child(odd) {
                .info {
                    padding-right: 65px;
                    padding-left: 70px;
                }
            }
        }
        .info {
            margin-top: 85px;
            padding-top: 70px;
            padding-bottom: 60px;
            width: calc(100% - 266px);
        }
        h3 {
            font-size: 26px;
        }
        .img {
            margin: 0 -22px -32px;
            padding: 71px 38px 95px;
            width: 340px;
            height: 631px;
        }
    }
}