.art-seo {
    min-width: 1250px;
    width: 100%;
    .wp1400 {
        max-width: 1400px;
        margin: 0 auto;
        box-sizing: border-box;
        position: relative;
        @media screen and (max-width: (1500px)) {
            padding: 0 px2rem(40px);
        }
        @media screen and (max-width: 1024px) {
            padding: 0 px2rem(30px);
        }
    }
    .seo-title-block {
        text-align: center;
        .title {
            font-size: 50px;
            font-weight: bold;
            line-height: 45px;
            color: #333;
        }
        .subtitle {
            margin-top: 16px;
            font-family: Arial;
            font-size: 18px;
            line-height: 1;
            color: #999;
        }
        &.is-white {
            .title,
            .subtitle {
                color: #fff;
            }
        }
    }
    .seo-banner {
        height: 580px;
        width: 100%;
        position: relative;
        @media (max-width: 1500px) {
            height: 530px;
        }
        @media (max-width: 1340px) {
            height: 480px;
        }
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
        .text-container {
            position: absolute;
            top: 150px;
            left: 0;
            @media (max-width: 1500px) {
                top: 150px;
                left: 40px;
            }
            .title {
                font-size: 60px;
                font-weight: bold;
                line-height: 75px;
                color: #fff;
                @include transition();
                @media (max-width: 1500px) {
                    font-size: 50px;
                    line-height: 65px;
                }
                @media (max-width: 1340px) {
                    font-size: 45px;
                    line-height: 55px;
                }
            }
            .subtitle {
                margin-top: 24px;
                font-size: 35px;
                line-height: 50px;
                font-weight: bold;
                color: #fff;
                @include transition();
                @media (max-width: 1500px) {
                    margin-top: 20px;
                    font-size: 30px;
                    line-height: 45px;
                }
                @media (max-width: 1340px) {
                    font-size: 26px;
                    line-height: 40px;
                }
            }
        }
    }
    .seo-problem {
        box-sizing: border-box;
        height: 780px;
        padding-top: 80px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        &-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: absolute;
            p {
                font-size: 26px;
                font-weight: bold;
                line-height: 38px;
                color: #333;
                text-align: right;
                @media (max-width: 1400px) {
                    font-size: 20px;
                }
            }
            .icon {
                width: 80px;
                height: 80px;
                margin-left: 20px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            &:nth-child(even) {
                p {
                    order: 1;
                    text-align: left;
                }
                .icon {
                    margin-left: 0;
                    margin-right: 20px;
                }
            }
            &:nth-child(1) {
                left: 105px;
                top: 175px;
            }
            &:nth-child(2) {
                right: 45px;
                top: 185px;
            }
            &:nth-child(3) {
                left: -10px;
                top: 465px;
            }
            &:nth-child(4) {
                right: 115px;
                top: 475px;
            }
        }
    }
    .seo-benefit {
        box-sizing: border-box;
        height: 820px;
        padding-top: 100px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        &-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: absolute;
            .icon {
                width: 88px;
                height: 88px;
                margin-right: 12px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            p,
            .tit {
                margin-bottom: 10px;
                font-weight: bold;
                font-size: 22px;
                line-height: 1;
                color: #fff;
                @include transition();
                @media (max-width: 1340px) {
                    font-size: 20px;
                }
            }
            .intro {
                margin-bottom: 0;
                font-weight: normal;
                font-size: 14px;
                line-height: 1.8;
                color: #fff;
                max-width: 300px;
                @media (max-width: 1340px) {
                    font-size: 12px;
                }
            }
            &:nth-child(1) {
                left: 47.5%;
                top: 168px;
                @media (min-width: 1260px) and (max-width: 1400px) {
                    left: 47vw;
                }
                @media (max-width: 1260px) {
                    left: 42.5%;
                }
                @media (max-width: 1340px) {
                    .intro {
                        max-width: 270px;
                    }
                }
            }
            &:nth-child(2) {
                left: 60%;
                top: 285px;
                .intro {
                    max-width: 330px;
                    @media (max-width: 1340px) {
                        max-width: 310px;
                    }
                }
                @media (min-width: 1260px) and (max-width: 1400px) {
                    left: 60vw;
                }
                @media (max-width: 1260px) {
                    left: 54%;
                }
            }
            &:nth-child(3) {
                left: 65%;
                top: 395px;
                .intro {
                    max-width: 170px;
                    @media (max-width: 1340px) {
                        max-width: 150px;
                    }
                }
                @media (min-width: 1260px) and (max-width: 1400px) {
                    left: 65vw;
                }
                @media (max-width: 1260px) {
                    left: 60%;
                }
            }
            &:nth-child(4) {
                left: 12.5%;
                top: 250px;
                .icon {
                    order: 1;
                    margin-right: 0;
                    margin-left: 20px;
                }
                .intro {
                    max-width: 260px;
                    @media (max-width: 1340px) {
                        max-width: 240px;
                    }
                }
            }
            &:nth-child(5) {
                left: 21%;
                top: 365px;
                @media (min-width: 1260px) and (max-width: 1400px) {
                    left: 20vw;
                }
                @media (max-width: 1260px) {
                    left: 18%;
                }
                .icon {
                    order: 1;
                    margin-right: 0;
                    margin-left: 20px;
                }
                .intro {
                    max-width: 210px;
                    @media (max-width: 1340px) {
                        max-width: 180px;
                    }
                }
            }
            &:nth-child(6) {
                left: 10.5%;
                top: 465px;
                .icon {
                    order: 1;
                    margin-right: 0;
                    margin-left: 20px;
                }
                .intro {
                    max-width: 235px;
                    @media (max-width: 1340px) {
                        max-width: 200px;
                    }
                }
            }
        }
    }
    .seo-why-choose {
        padding-top: 100px;
        padding-bottom: 100px;
        position: relative;
        &::after {
            content: '';
            display: block;
            width: 1329px;
            height: 118px;
            background: url(../images/seo3.png) no-repeat center/cover;
            position: absolute;
            bottom: 33px;
            left: 50%;
            transform: translateX(-50%);
        }
        .seo-title-block {
            margin-bottom: 40px;
        }
        &-list {
            display: flex;
            justify-content: flex-start;
        }
        &-item {
            flex: 1;
            box-sizing: border-box;
            padding: 57px 38px 40px;
            min-height: 400px;
            background-color: #effaff;
            border-top: solid 1px #cae4ef;
            border-bottom: solid 1px #cae4ef;
            border-right: solid 1px #cae4ef;
            @media screen and (max-width: 1580px) {
                padding: 57px 26px 40px;
            }
            @media screen and (max-width: 1440px) {
                padding: 57px 20px 40px;
            }
            @media (max-width: 1380px) {
                min-height: 380px;
            }
            &:nth-child(1) {
                border-left: solid 1px #cae4ef;
            }
            // &:nth-child(3) {
            //     .intro {
            //         max-width: 240px;
            //         margin-left: auto;
            //         margin-right: auto;
            //         @media (max-width: 1380px) {
            //             max-width: 220px;
            //         }
            //     }
            // }
            .icon {
                width: 120px;
                height: 120px;
                margin-left: auto;
                margin-right: auto;
                img {
                    width: 100%;
                    height: 100%;
                }
                @media (max-width: 1500px) {
                    width: 110px;
                    height: 110px;
                }
                @media (max-width: 1380px) {
                    width: 100px;
                    height: 100px;
                }
            }
            .tit {
                margin-top: 40px;
                font-size: 20px;
                line-height: 1;
                font-weight: bold;
                color: #000;
                text-align: center;
                @include transition();
                @media (max-width: 1380px) {
                    font-size: 17px;
                }
            }
            .intro {
                margin-top: 20px;
                font-size: 16px;
                line-height: 30px;
                color: #666;
                text-align: center;
                @media (max-width: 1380px) {
                    margin-top: 18px;
                    font-size: 14px;
                    line-height: 28px;
                }
            }
        }
    }
    .seo-process {
        box-sizing: border-box;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 840px;
        padding-top: 120px;
        &-bg {
            position: absolute;
            top: 160px;
            left: 8.2%;
            display: block;
            width: 1165px;
            height: auto;
            max-width: 100%;
            @media (max-width: 1400px) {
                max-width: 90%;
            }
        }
        &-item {
            font-size: 18px;
            font-weight: bold;
            line-height: 30px;
            color: #333;
            text-align: center;
            position: absolute;
            p {
                font-size: 18px;
                font-weight: bold;
                line-height: 30px;
                color: #333;
                text-align: center;
            }
            &:nth-child(odd) {
                top: 470px;
                @media (max-width: 1380px) {
                    top: 455px;
                }
            }
            &:nth-child(even) {
                top: 262px;
                @media (max-width: 1400px) {
                    top: 250px;
                }
            }
            &:nth-child(1) {
                left: 8%;
                @media (max-width: 1500px) {
                    left: 6%;
                }
                @media (max-width: 1400px) {
                    left: 6.5%;
                }
            }
            &:nth-child(2) {
                left: 24%;
                @media (max-width: 1500px) {
                    left: 22%;
                }
                @media (max-width: 1400px) {
                    left: 22.5%;
                }
            }
            &:nth-child(3) {
                left: 38%;
                @media (max-width: 1500px) {
                    left: 37%;
                }
                @media (max-width: 1400px) {
                    left: 37%;
                }
            }
            &:nth-child(4) {
                left: 53%;
                @media (max-width: 1400px) {
                    left: 53.5%;
                }
            }
            &:nth-child(5) {
                left: 68%;
                @media (max-width: 1500px) {
                    left: 69%;
                }
            }
            // &:nth-child(6) {
            //     left: 75.5%;
            //     @media (max-width: 1500px) {
            //         left: 77.5%;
            //     }
            //     @media (max-width: 1400px) {
            //         left: 78.5%;
            //     }
            // }
        }
    }
    .seo-customers {
        padding-top: 100px;
        padding-bottom: 100px;
        .seo-title-block {
            margin-bottom: 50px;
        }
        .is-relative {
            padding-bottom: 42px;
            position: relative;
        }
        .swiper-container {
            width: 100%;
            min-height: 175px;
            padding-bottom: px2rem(62px);
            .swiper-slide {
                opacity: 0;
                &-active {
                    opacity: 1;
                }
                .logo-list-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    padding-top: 55px;
                    margin-bottom: px2rem(-60px);
                    @media (max-width: 1200px) {
                        padding-top: 45px;
                    }
                    .list-row {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: px2rem(55px);
                        @media (max-width: 1920px) {
                            margin-bottom: px2vw(55);
                        }
                        @media (max-width: 1400px) {
                            margin-bottom: 39px;
                        }
                        .image {
                            position: relative;
                            box-sizing: border-box;
                            width: px2rem(200px);
                            height: px2rem(115px);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            z-index: 1;
                            @media (max-width: 1920px) {
                                width: px2vw(200);
                                height: px2vw(115);
                            }
                            @media (max-width: 1400px) {
                                width: 145.8px;
                                height: 83.8px;
                            }
                            &::after {
                                content: '';
                                display: block;
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                z-index: -1;
                                transform: translate(-50%, -50%);
                                width: px2rem(200px);
                                height: px2rem(228px);
                                background-image: url(../images/seo5.png);
                                background-size: contain;
                                background-repeat: no-repeat;
                                @media (max-width: 1920px) {
                                    width: px2vw(200);
                                    height: px2vw(228);
                                }
                                @media (max-width: 1400px) {
                                    width: 145.8px;
                                    height: 166.2px;
                                }
                            }
                            &.img-none {
                                @media (max-width: 750px) {
                                    margin-left: 1px;
                                }
                                &::after {
                                    background: none;
                                }
                            }
                            &:not(:last-child) {
                                margin-right: -1px;
                            }
                            .img-box {
                                width: 100%;
                                height: 100%;
                                overflow: hidden;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                img {
                                    max-width: 90%;
                                }
                            }
                        }
                    }
                }
            }
        }
        .swiper-pagination {
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            .swiper-pagination-bullet {
                box-sizing: border-box;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: transparent;
                margin: 0 10px;
                border: solid 1px #b5b5b5;
                opacity: 1;
                @media (max-width: 750px) {
                    width: 8px;
                    height: 8px;
                    margin: 0 6px;
                }
                &.swiper-pagination-bullet-active {
                    background: #dd1c31;
                    border: solid 1px #dd1c31;
                }
            }
        }
    }
}