
.app-title {
    h5 {
        color: #444;
        font-size: 46px;
        line-height: 46px;
        text-align: center;
    }
    h6 {
        font-size: 21.02px;
        line-height: 21.02px;
        margin-top: 10px;
        text-align: center;
        color: #3466bc;
        text-transform: uppercase;
    }
}
.app-banner {
    position: relative;
    min-width: 1250px;
    height: 1011px;
    width: 100%;
    background: url(../images/app_banner_0.png) no-repeat left bottom;
    background-size: 100% 100%;
    .app-text {
        margin: 0 auto;
        padding-top: 137px;
        z-index: 10;
        width: 830px;
        div {
            position: relative;
        }
        .h1 {
            padding-top: 10px;
            opacity: 0;
            font-size: 50px;
            font-weight: bold;
            color: #fff;
            text-align: center;
            line-height: 50px;
            transition: all .5s ease-out .3s;
        }
        .h2 {
            padding-top: 10px;
            opacity: 0;
            margin-top: 15px;
            line-height: 50px;
            font-size: 50px;
            color: #fff;
            text-align: center;
            transition: all .5s ease-out .4s;
        }
        .h3 {
            padding-top: 10px;
            opacity: 0;
            margin-top: 18px;
            font-size: 19px;
            line-height: 19px;
            color: #fff;
            text-align: center;
            text-transform: uppercase;
            transition: all .5s ease-out .5s;
        }
        .h4 {
            padding-top: 10px;
            opacity: 0;
            margin-top: 20px;
            font-size: 12px;
            color: rgba(255, 255, 255, .5);
            text-align: center;
            transition: all .5s ease-out .6s;
        }
    }
    .app-text-on {
        .h1,
        .h2,
        .h3,
        .h4 {
            padding-top: 0px;
            opacity: 1;
        }
    }
    .app-icon {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }
    .phone {
        position: relative;
        margin: 0 auto;
        width: 422px;
        height: 633px;
    }
    .the-phone {
        position: relative;
        z-index: 100;
        width: 100%;
        height: 100%;
        background: url(../images/app_banner_icon_0.png) no-repeat center bottom;
        background-size: 100% 100%;
        opacity: 0;
        background-position-y: 110px;
        transition: all .6s ease-out .6s;
    }
    .phone-on {
        opacity: 1;
        background-position-y: 0px;
    }
    .pointer {
        margin-right: -30px;
        opacity: 0;
        z-index: 110;
        position: absolute;
        right: -20px;
        top: 250px;
        width: 100px;
        height: 67px;
        background: url(../images/app_banner_icon_3.png) no-repeat center center;
        background-size: 100% 100%;
        transition: all .4s ease-out .8s;
    }
    .pointer-on {
        margin-right: 0px;
        opacity: 1;
    }
    .icon {
        position: absolute;
        top: 170px;
        left: 0px;
        opacity: 0;
        width: 204px;
        height: 232px;
        background: url(../images/app_banner_icon_1.png) no-repeat center center;
        background-size: 100% 100%;
        transition: all .4s ease-out 1.2s;
    }
    .icon1 {
        position: absolute;
        top: 170px;
        right: 0px;
        opacity: 0;
        width: 204px;
        height: 232px;
        background: url(../images/app_banner_icon_2.png) no-repeat center center;
        background-size: 100% 100%;
        transition: all .4s ease-out 1.2s;
    }
    .icon-on {
        opacity: 1;
        left: -200px;
    }
    .icon1-on {
        opacity: 1;
        right: -200px;
    }
}
.app-developmentvalue {
    width: 100%;
    min-width: 1250px;
    height: 770px;
    overflow: hidden;
    font-size: 0;
    .left {
        overflow: hidden;
        display: inline-block;
        vertical-align: top;
        position: relative;
        width: (705/1920) * 100%;
        height: 100%;
        background: #396dc6;
        .phone {
            position: absolute;
            bottom: 0;
            left: (228/705) * 100%;
            width: 326px;
            height: 593px;
            opacity: 0;
            transform: translate(0, 50%);
            background: url(../images/app_developmentvalue_0.png) no-repeat;
            background-size: 100% 100%;
            transition: all .6s ease-out;
        }
        .phone-on {
            opacity: 1;
            transform: translate(0, 0);
        }
    }
    .right {
        height: 100%;
        display: inline-block;
        vertical-align: top;
        position: relative;
        width: (1215/1920) * 100%;
        .app-title {
            width: 100%;
            margin-top: 100px;
            height: 77px;
            position: relative;
            display: inline-block;
            text-align: right;
            div {
                position: absolute;
                right: 242px;
            }
        }
    }
    &-box {
        position: absolute;
        padding-right: 73px;
        bottom: 0;
        left: -73px;
        height: 538px;
        width: 120%;
        ul {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            li {
                position: relative;
                width: 100%;
                height: 20%;
                opacity: 0;
                transform: translate(20%, 0);
                > div {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    padding-left: 66px;
                    box-sizing: border-box;
                    border-bottom: 1px dashed #cecece;
                    transition: all .4s ease-out;
                    &:hover {
                        z-index: 1000;
                        margin-left: -5px;
                        box-shadow: 0 8px 35px rgba(0, 0, 0, .2);
                        .text {
                            h5 {
                                color: #f8a7b2;
                            }
                        }
                    }
                }
                &:nth-child(1) {
                    transition: all .5s ease-out .3s;
                    > div {
                        border-top: 1px dashed #cecece;
                    }
                }
                &:nth-child(2) {
                    transition: all .5s ease-out .4s;
                }
                &:nth-child(3) {
                    transition: all .5s ease-out .5s;
                }
                &:nth-child(4) {
                    transition: all .5s ease-out .6s;
                }
                &:nth-child(5) {
                    transition: all .5s ease-out .7s;
                }
                &:nth-child(odd) {
                    > div {
                        background: #fff;
                    }
                }
                &:nth-child(even) {
                    > div {
                        background: #f9f9f9;
                    }
                }
                .num {
                    margin-top: 25px;
                    display: inline-block;
                    vertical-align: top;
                    width: 60px;
                    height: 60px;
                    background: #f8a7b2;
                    border-radius: 6px;
                    text-align: center;
                    line-height: px2rem(60px);
                    font-size: 30px;
                    color: #fff;
                    margin-right: 15px;
                }
                .text {
                    margin-top: 35px;
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    h5 {
                        font-size: 18px;
                        line-height: 18px;
                        color: #000;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        transition: all .2s ease-out;
                    }
                    p {
                        line-height: 32px;
                        font-size: 14px;
                        color: #888;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
            .li-on {
                opacity: 1;
                transform: translate(0, 0);
            }
        }
    }
}
.app-case {
    padding-top: 85px;
    padding-bottom: 140px;
    background: #edf1fc;
    width: 100%;
    min-width: 1250px;
    overflow: hidden;
    font-size: 0;
    &-box {
        min-width: 1250px;
        max-width: 1520px;
        height: 670px;
        margin: 0 auto;
        margin-top: 50px;
    }
    &-left {
        position: relative;
        z-index: 10;
        display: inline-block;
        vertical-align: top;
        width: (600/1520) * 100%;
        height: 100%;
        opacity: 0;
        transition: all .5s ease-out;
        &-on {
            opacity: 1;
        }
        li {
            position: relative;
            cursor: pointer;
            width: 33.33%;
            height: 25%;
            display: inline-block;
            vertical-align: top;
            text-align: center;
            box-sizing: border-box;
            transition: all .5s ease-out;
            .show {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                padding-top: 45px;
                box-sizing: border-box;
            }
            .border {
                opacity: 0;
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                border: 1px solid #fff;
                box-sizing: border-box;
                transition: all .3s ease-out;
            }
            .text {
                display: none;
            }
            &:nth-child(odd) {
                background: #4e80d7;
            }
            &:nth-child(even) {
                background: #396dc6;
            }
            p {
                margin-top: 20px;
                font-size: 16px;
                color: rgba(255, 255, 255, .55);
                font-weight: bold;
            }
        }
        .on {
            position: relative;
            z-index: 100;
            box-shadow: 2px 3px 43px rgba(76, 88, 110, .42);
            .border {
                opacity: 1;
                border: 7px solid #fff;
            }
        }
    }
    &-right {
        position: relative;
        margin-left: (10/1520) * 100%;
        display: inline-block;
        vertical-align: top;
        background: #fff;
        width: (910/1520) * 100%;
        height: 100%;
        opacity: 0;
        transform: translate(-50%, 0);
        transition: all 1s ease-out .3s;
        &-on {
            opacity: 1;
            transform: translate(0, 0);
        }
        .img {
            margin: 0 auto;
            margin-top: 125px;
            width: 500px;
            height: 310px;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
        .text {
            margin-top: 70px;
            text-align: center;
            &-left {
                display: inline-block;
                vertical-align: top;
                text-align: left;
                border-right: 1px solid #c9c9c9;
                padding-right: 12px;
                h5 {
                    font-size: px2rem(28px);
                    line-height: px2rem(28px);
                    font-weight: bold;
                    color: #3d6dc6;
                }
                p {
                    margin-top: 5px;
                    font-family: "Arial";
                    font-size: 14px;
                    color: #777;
                    line-height: 14px;
                    text-transform: uppercase;
                }
            }
            &-right {
                margin-top: -3px;
                display: inline-block;
                vertical-align: top;
                text-align: left;
                font-size: 14px;
                color: #555;
                padding-left: 15px;
                box-sizing: border-box;
                line-height: 28px;
                max-height: 28*4px;
                overflow: hidden;
                width: 540px;
            }
        }
        @media screen and (max-width: 1460px) {
            .text {
                margin-top: 40px;
            }
        }
    }
}
.app-case1 {
    height: 949px;
    overflow: hidden;
    width: 100%;
    min-width: 1250px;
    font-size: 0px;
    background: #2e4da3;
    .li {
        width: 25%;
        cursor: pointer;
        background: #2e4da3;
        height: 100%;
        text-align: center;
        overflow: hidden;
        transition: all .5s ease;
        &:nth-child(even) {
            position: relative;
            z-index: 10;
            border-left: 1px solid #5871b5;
            border-right: 1px solid #5871b5;
            box-sizing: border-box;
            box-shadow: 2px 3px 92px rgba(40, 40, 40, .28);
        }
        &:nth-child(odd) > div {
            transition: all .5s ease-out .3s;
        }
        &:nth-child(even) > div {
            transition: all .5s ease-out .4s;
        }
        > div {
            opacity: 0;
            position: relative;
            top: 50%;
            transform: translate(0, -30%);
        }
        &:hover {
            background: #3a62bc;
            box-shadow: 5px 3px 92px rgba(40, 40, 40, .28);
            .phone-text {
                opacity: 1;
                > div {
                    opacity: 1;
                    transform: translate(0, -50%);
                }
            }
        }
    }
    &-on {
        .li > div {
            opacity: 1;
            transform: translate(0, -50%);
        }
    }
    .h1 {
        line-height: 50px;
        font-size: 50px;
        color: #fff;
        margin-bottom: 15px;
        font-family: "DB";
    }
    .h2 {
        margin-bottom: 10px;
        line-height: px2rem(26px);
        font-size: 26px;
        font-weight: bold;
        color: #fff;
    }
    .h3 {
        text-transform: uppercase;
        font-family: "Arial";
        font-size: 12px;
        color: rgba(255, 255, 255, .5);
    }
    .phone {
        position: relative;
        margin: 0 auto;
        margin-top: 50px;
        width: 263px;
        height: 535px;
        background: url(../images/app_icon_12.png) no-repeat;
        background-size: 100% 100%;
        box-shadow: 18px 31px rgba(76, 88, 110, .06);
        &-box {
            position: absolute;
            top: 65px;
            left: 17px;
            width: 230px;
            height: 407px;
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        &-text {
            position: absolute;
            opacity: 0;
            z-index: 100;
            width: 100%;
            height: 100%;
            padding: 0px 35px;
            box-sizing: border-box;
            background: rgba(20, 34, 77, .8);
            font-size: 12px;
            color: #fff;
            line-height: 28px;
            transition: all .5s ease;
            > div {
                position: relative;
                top: 50%;
                opacity: 0;
                transform: translate(0, -100%);
                transition: all .5s ease .2s;
            }
        }
    }
}
@media screen and (max-width: 1650px) {
    .app-developmentvalue .left .phone {
        left: 20%;
    }
}
@media screen and (max-width: 1460px) {
    .app-banner {
        height: 750px;
    }
    .app-banner .app-text {
        padding-top: 80px;
    }
    .app-banner .app-text h5 {
        font-size: 36px;
        line-height: 36px;
    }
    .app-banner .app-text h6 {
        font-size: 36px;
        line-height: 36px;
    }
    .app-banner .icon {
        top: 150px;
    }
    .app-banner .icon1 {
        top: 150px;
    }
    .app-banner .phone {
        width: 313px;
        height: 470px;
    }
    .app-developmentvalue {
        height: 620px;
    }
    .app-developmentvalue .right .app-title {
        margin-top: 50px;
    }
    .app-developmentvalue .left .phone {
        width: 270px;
        height: 491px;
    }
    .app-title h1 {
        font-size: 32px;
        line-height: 32px;
    }
    .app-title h2 {
        font-size: 18px;
        line-height: 18px;
    }
    .app-developmentvalue-box {
        height: 460px;
    }
    .app-developmentvalue-box ul li .num {
        margin-top: 22px;
        height: 50px;
        width: 50px;
        line-height: 50px;
    }
    .app-developmentvalue-box ul li .text {
        margin-top: 28px;
    }
    .app-developmentvalue-box ul li > div {
        padding-left: 50px;
    }
    .app-developmentvalue-box ul li .text h5 {
        font-size: 16px;
        line-height: 16px;
    }
    .app-case {
        padding-top: 60px;
        padding-bottom: 80px;
    }
    .app-case-box {
        max-width: none;
        min-width: 0;
        width: 1120px;
        height: 550px;
    }
    .app-case-left li .show {
        padding-top: 25px;
    }
    .app-case-right .img {
        margin-top: 70px;
    }
    .app-case-right .text-right {
        width: 445px;
    }
    .app-case1 {
        height: 750px;
    }
    .app-case1 .phone {
        width: 220px;
        height: 447px;
        margin-top: 40px;
    }
    .app-case1 .phone-box {
        top: 55px;
        left: 14px;
        width: 192px;
        height: 339px;
    }
}
@media screen and (max-width: 1400px) {
    .app-developmentvalue .left .phone {
        left: 10%;
    }
}