@keyframes viewpointimg {
    0% { top: -15px; }
    50% { top: 15px; }
    100% { top: -15px; }
}
@keyframes radiusAnimationOne {
    0% { margin-top: -515px; }
    50% { margin-top: -485px; }
    100% { margin-top: -515px; }
}
@keyframes radiusAnimationTwo {
    0% { margin-top: -95px; }
    50% { margin-top: -80px; }
    100% { margin-top: -95px; }
}
@keyframes radiusAnimationThree {
    0% { margin-top: -108px; }
    50% { margin-top: -87px; }
    100% { margin-top: -108px; }
}
@keyframes radiusAnimationFour {
    0% { margin-top: 124px; }
    50% { margin-top: 104px; }
    100% { margin-top: 124px; }
}
.viewpoint {
    &-title {
        text-align: center;
        h5 {
            font-size: 45px;
            line-height: 45px;
            color: #333;
        }
        h6 {
            font-size: 15px;
            color: #b4b4b4;
            line-height: 48px;
            letter-spacing: 3px;
            text-transform: uppercase;
            font-family: "Arial";
        }
    }
    &-banner {
        position: relative;
        z-index: 10;
        min-width: 1250px;
        width: 100%;
        height: 815px;
        overflow: hidden;
        background: url(../images/viewpoint_icon_1.png) no-repeat top center;
        &-box {
            max-width: 1400px;
            min-width: 1250px;
            margin: 0 auto;
            font-size: 0;
            .left {
                padding-left: 95px;
                box-sizing: border-box;
                width: 50%;
                position: relative;
                top: 30px;
                opacity: 0;
                display: inline-block;
                vertical-align: top;
                padding-top: 110px;
                transition: all 1s ease .4s;
                img {
                    position: relative;
                    max-width: 100%;
                    animation: viewpointimg 3s ease-in-out infinite;
                }
            }
            .right {
                width: 50%;
                position: relative;
                left: 40px;
                opacity: 0;
                display: inline-block;
                vertical-align: top;
                padding-left: 30px;
                padding-top: 290px;
                transition: all .7s ease .2s;
                box-sizing: border-box;
                h5 {
                    padding-left: 120px;
                    position: relative;
                    font-size: 60px;
                    line-height: 60px;
                    font-weight: bold;
                    color: #fff;
                    font-family: "Microsoft JhengHei";
                    &:after {
                        content: "";
                        position: absolute;
                        top: -25px;
                        left: 0;
                        width: 113px;
                        height: 101px;
                        background: url(../images/viewpoint_icon_6.png);
                        background-size: 100% 100%;
                    }
                }
                h6 {
                    margin-top: 25px;
                    font-size: 35px;
                    line-height: 35px;
                    font-weight: bold;
                    color: #fff;
                    font-family: "Microsoft JhengHei";
                }
                p {
                    margin-top: 10px;
                    max-width: 580px;
                    font-size: 12px;
                    color: #fff;
                    font-family: "Arial";
                    line-height: 26px;
                    text-transform: uppercase;
                }
            }
            &-on {
                .right,
                .left {
                    top: 0;
                    left: 0;
                    opacity: 1;
                }
            }
        }
    }
    &-two {
        position: relative;
        min-width: 1250px;
        width: 100%;
        height: 2025px;
        margin-top: -165px;
        padding-top: 250px;
        box-sizing: border-box;
        overflow: hidden;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: url(../images/viewpoint_icon_3.png) no-repeat top center;
            /*2444*/
        }
        &-text {
            z-index: 10;
            position: absolute;
            top: 396px;
            left: 50%;
            margin-left: -570px;
            width: 630px;
            height: 630px;
            &:after {
                content: '';
                position: absolute;
                top: 50px;
                opacity: 0;
                right: -165px;
                width: 497px;
                height: 370px;
                background: url(../images/viewpoint_icon_4.png);
                background-size: 100% 100%;
                transition: all .5s ease-in-out 1s;
            }
            &-on {
                &:after {
                    opacity: 1;
                    top: -22px;
                    right: -265px;
                }
                .viewpoint-two-text-box {
                    margin-top: 0px;
                    opacity: 1;
                }
            }
            &-box {
                margin-top: 40px;
                opacity: 0;
                position: relative;
                z-index: 10;
                width: 100%;
                height: 100%;
                background: url(../images/viewpoint_icon_7.png) no-repeat;
                background-size: 100% 100%;
                transition: all .5s ease-in-out .5s;
                .content {
                    display: none;
                    position: absolute;
                    top: 190px;
                    left: 155px;
                }
                .on {
                    display: block;
                }
                h5 {
                    width: 420px;
                    font-size: 28px;
                    line-height: 42px;
                    color: #fff;
                    font-weight: bold;
                    span {
                        font-size: 40px;
                        padding: 0 6px;
                    }
                }
                .text {
                    margin-left: 45px;
                    margin-top: 40px;
                    font-size: 15px;
                    line-height: 30px;
                    width: 228px;
                    color: rgba(255, 255, 255, .8);
                }
            }
        }
        &-btn {
            z-index: 20;
            opacity: 0;
            position: absolute;
            top: 380px;
            left: 50%;
            margin-left: 222px;
            transform: rotate(50.8deg);
            text-align: center;
            transition: all .5s ease .3s;
            &-on {
                opacity: 1;
                top: 428px;
                margin-left: 163px;
            }
            &-li {
                cursor: pointer;
                display: block;
                position: relative;
                margin-top: 1px;
                width: 180px;
                height: 180px;
                background: #667db0;
                transition: all .4s ease;
                &:hover {
                    background: #4e5f85;
                }
                &:nth-child(4) {
                    position: absolute;
                    top: 180px;
                    left: 180px;
                    margin-top: 2px;
                    margin-left: 1px;
                }
                > div {
                    box-sizing: border-box;
                    padding-top: 47px;
                    width: 70%;
                    height: 70%;
                    transform: rotate(-50.8deg);
                    h5 {
                        margin-top: 13px;
                        font-size: 24px;
                        line-height: 24px;
                        color: #fff;
                        letter-spacing: 3px;
                    }
                    h6 {
                        margin-top: 9px;
                        font-family: "Arial";
                        font-size: 13px;
                        line-height: 13px;
                        color: #fff;
                        opacity: .5;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                    }
                }
            }
            .on {
                background: #4e5f85;
            }
        }
    }
    &-advantage {
        position: absolute;
        top: 1165px;
        width: 100%;
        .viewpoint-title {
            position: relative;
            z-index: 10;
        }
        &-text {
            position: absolute;
            top: 180px;
            left: 50%;
            width: 325px;
            z-index: 10;
            opacity: 0;
            margin-left: -700px;
            transition: all .5s ease .3s;
            .content {
                display: none;
            }
            .on {
                display: block;
            }
            &-on {
                opacity: 1;
                margin-left: -610px;
            }
            &-num {
                display: inline-block;
                width: 65px;
                height: 65px;
                background: #3c6fdf;
                border-radius: 50%;
                line-height: 65px;
                text-align: center;
                color: #fff;
                font-size: 34px;
                font-weight: bold;
                font-family: "Arial";
            }
            &-right {
                margin-top: 5px;
                padding-left: 10px;
                display: inline-block;
                vertical-align: top;
                h5 {
                    font-size: 36px;
                    line-height: 36px;
                    font-weight: bold;
                    color: #333;
                }
                h6 {
                    font-size: 13px;
                    letter-spacing: 4px;
                    line-height: 33px;
                    font-family: "Arial";
                    color: #333;
                    opacity: .7;
                    text-transform: uppercase;
                }
            }
            &-content {
                margin-top: 20px;
                width: 360px;
                color: #666;
                font-size: 15px;
                line-height: 30px;
            }
            &-a {
                margin-top: 40px;
                display: block;
                width: 135px;
                height: 35px;
                background: #3c6fdf;
                line-height: 35px;
                text-align: center;
                font-size: 14px;
                color: #fff;
                border-radius: 5px;
                transition: all .3s ease;
                &:hover {
                    color: #fff;
                    opacity: .7;
                }
            }
        }
        &-btn {
            z-index: 10;
            position: absolute;
            left: 50%;
            margin-left: -152px;
            top: 130px;
            width: 832px;
            font-size: 0;
            text-align: right;
            transition: all .3s ease;
            &-on {
                .viewpoint-advantage-btn-li {
                    margin-top: 0px;
                    opacity: 1;
                }
            }
            &-li {
                position: relative;
                cursor: pointer;
                display: inline-block;
                height: 120px;
                box-sizing: border-box;
                background-size: 100% 100%;
                text-align: left;
                transition: all .3s ease;
                margin-top: 10px;
                opacity: 0;
                &:after {
                    position: absolute;
                    bottom: 0;
                    left: 10%;
                    content: '';
                    width: 90%;
                    height: 10px;
                    box-shadow: 0 5px 29px rgba(0, 0, 0, .28);
                }
                &.on,
                &:hover {
                    z-index: 30;
                }
                > div {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 10;
                    display: block;
                    width: 100%;
                    height: 100%;
                    padding-left: 120px;
                    padding-top: 40px;
                    box-sizing: border-box;
                    transition: all .3s ease;
                    border-bottom: 1px solid #6896f2;
                }
                h5 {
                    font-size: 24px;
                    line-height: 24px;
                    color: #fff;
                }
                h6 {
                    margin-top: 10px;
                    font-size: 12px;
                    line-height: 12px;
                    letter-spacing: 2px;
                    color: rgba(255, 255, 255, .5);
                    text-transform: uppercase;
                    font-family: "Arial";
                }
                .num {
                    padding-right: 10px;
                }
                .img {
                    position: absolute;
                    top: 45px;
                    right: 30px;
                    width: 26px;
                    height: 29px;
                }
                &:nth-child(1) {
                    width: 395px;
                    transition: all .4s ease .2s;
                    div {
                        background-image: url(../images/viewpoint_icon_12.png);
                    }
                    &.on div,
                    &:hover div {
                        background-image: url(../images/viewpoint_icon_20.png);
                    }
                }
                &:nth-child(2) {
                    width: 541px;
                    transition: all .4s ease .4s;
                    div {
                        background-image: url(../images/viewpoint_icon_13.png);
                    }
                    &.on div,
                    &:hover div {
                        background-image: url(../images/viewpoint_icon_21.png);
                    }
                }
                &:nth-child(3) {
                    width: 686px;
                    transition: all .4s ease .6s;
                    div {
                        background-image: url(../images/viewpoint_icon_14.png);
                    }
                    &.on div,
                    &:hover div {
                        background-image: url(../images/viewpoint_icon_22.png);
                    }
                }
                &:nth-child(4) {
                    transition: all .4s ease .8s;
                    width: 832px;
                    div {
                        background-image: url(../images/viewpoint_icon_15.png);
                    }
                    &.on div,
                    &:hover div {
                        background-image: url(../images/viewpoint_icon_23.png);
                    }
                }
            }
        }
    }
    &-three {
        box-shadow: 0 -5px 25px rgba(78, 132, 240, .08);
        width: 100%;
        min-width: 1250px;
        height: 750px;
        background: #fff;
        &-left {
            position: relative;
            //display: inline-block;
            //vertical-align: top;
            float: left;
            width: 607px;
            height: 100%;
            background: #4e84f0;
            overflow: hidden;
            &-on {
                .viewpoint-three-phone {
                    opacity: 1;
                    transform: translate(-50%, 0%);
                }
            }
        }
        &-pn {
            position: absolute;
            z-index: 20;
            bottom: 0;
            right: 0;
            width: 60px;
            height: 334px;
            > div {
                cursor: pointer;
                width: 100%;
                height: 50%;
                transition: all .3s ease;
                background: url(../images/viewpoint_icon_27.png) #73a2ff no-repeat center center;
                &:hover {
                    background-color: #205bce;
                }
            }
            .viewpoint-three-right-p {
                box-sizing: border-box;
                border-bottom: 1px solid #9dbcfb;
            }
            .viewpoint-three-right-n {
                transform: rotate(180deg);
            }
            .swiper-button-disabled {
                background-color: #205bce;
            }
        }
        &-phone {
            position: absolute;
            bottom: 0;
            left: 50%;
            opacity: 0;
            transform: translate(-50%, 50%);
            width: 454px;
            height: 676px;
            background: url(../images/viewpoint_icon_25.png);
            background-size: 100% 100%;
            transition: all .7s ease .3s;
            &-img {
                position: relative;
                top: 144px;
                left: 68px;
                width: 313px;
                height: 532px;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &-right {
            //display: inline-block;
            //vertical-align: top;
            padding-left: 100px;
            padding-top: 100px;
            box-sizing: border-box;
            overflow: hidden;
            h5 {
                font-size: 50px;
                line-height: 50px;
                font-weight: bold;
                color: #333;
                margin-bottom: 15px;
                margin-left: -20px;
                opacity: 0;
                transition: all .5s ease .3s;
            }
            p {
                font-size: 24px;
                line-height: 38px;
                margin-left: -20px;
                opacity: 0;
                transition: all .5s ease .3s;
            }
            &-box {
                opacity: 0;
                margin-top: 75px;
                overflow: hidden;
                max-width: 1021px;
                height: 340px;
                font-size: 0;
                transition: all .5s ease .6s;
                .order {
                    display: inline-block;
                    vertical-align: top;
                    width: 20%;
                    height: 33.33%;
                    box-sizing: border-box;
                    border-right: 1px solid #d9d9d9;
                    border-bottom: 1px solid #d9d9d9;
                    &:nth-child(-n + 5) {
                        border-top: 1px solid #d9d9d9;
                    }
                    &:nth-child(1),
                    &:nth-child(6),
                    &:nth-child(11) {
                        border-left: 1px solid #d9d9d9;
                    }
                    a {
                        position: relative;
                        display: block;
                        width: 100%;
                        height: 100%;
                        &:hover {
                            .text {
                                opacity: 1;
                            }
                            .text h5 {
                                opacity: 1;
                                margin-top: 0;
                            }
                        }
                        img {
                            position: relative;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            max-width: 100%;
                            max-height: 100%;
                        }
                        .text {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            opacity: 0;
                            background: rgba(0, 0, 0, .6);
                            transition: all .4s ease-out;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            h5 {
                                font-weight: normal;
                                text-align: center;
                                padding: 0 15px;
                                margin-bottom: 0px;
                                opacity: 0;
                                margin-top: 40px;
                                color: #fff;
                                font-size: 18px;
                                transition-delay: .1s;
                                transition-duration: .4s;
                            }
                        }
                    }
                }
                .text {
                    display: none;
                }
            }
            &-on {
                h5,
                p {
                    margin-left: 0px;
                    opacity: 1;
                }
                .viewpoint-three-right-box {
                    margin-top: 45px;
                    opacity: 1;
                }
            }
        }
        .viewpoint-pagination {
            text-align: center;
            position: absolute;
            bottom: 15px;
            z-index: 10;
            span {
                background: #1571e5;
                opacity: 1;
            }
            .swiper-pagination-bullet-active {
                opacity: 1;
                background: #15c5eb;
            }
        }
    }
    &-four {
        width: 100%;
        height: 1166px;
        min-width: 1250px;
        padding-top: 100px;
        box-sizing: border-box;
        background: url(../images/viewpoint_icon_26.png) no-repeat center top;
        .viewpoint-title {
            h6 {
                margin-top: 10px;
                color: #717171;
                font-size: 16px;
            }
        }
        &-box {
            position: relative;
            max-width: 1416px;
            min-width: 1250px;
            margin: 0 auto;
            margin-top: 35px;
            height: 1048px;
            overflow: hidden;
            &-on {
                .swiper-slide-active {
                    opacity: 1;
                    .viewpoint-four-li:nth-child(1) .text {
                        left: 388 + 320px;
                        opacity: 1;
                    }
                    .viewpoint-four-li:nth-child(2) .text {
                        left: 0px;
                        opacity: 1;
                    }
                }
                .viewpoint-four-li .img {
                    opacity: 1;
                }
            }
        }
        .swiper-slide {
            position: relative;
            opacity: 0 !important;
            &-active {
                opacity: 1 !important;
            }
        }
        &-pn {
            position: absolute;
            z-index: 20;
            top: 524px;
            left: 708-320-60px;
            width: 60px;
            height: 320px;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 112px;
                height: 320px;
                background: url(../images/viewpoint_icon_28.png) no-repeat;
                background-size: 100% 100%;
            }
            > div {
                position: relative;
                z-index: 10;
                cursor: pointer;
                width: 60px;
                height: 160px;
                transition: all .3s ease;
                background: url(../images/viewpoint_icon_27.png) #73a2ff no-repeat center center;
                &:hover {
                    background-color: #205bce;
                }
            }
            .swiper-button-disabled {
                background-color: #205bce;
            }
            .viewpoint-four-prev {
                box-sizing: border-box;
                border-bottom: 1px solid #9dbcfb;
            }
            .viewpoint-four-next {
                transform: rotate(180deg);
            }
        }
        &-pn2 {
            top: 0px;
            left: 708+320px;
        }
        &-li {
            cursor: pointer;
            position: absolute;
            &:nth-child(1) {
                top: 0px;
                left: 0px;
                .img {
                    top: 0;
                    left: 0;
                }
                .text {
                    top: 0;
                    left: 708-320px;
                }
            }
            &:nth-child(2) {
                top: 320px;
                left: 708-320px;
                .img {
                    top: 0;
                    left: 320px;
                }
                .text {
                    top: 524-320px;
                    left: 320px;
                }
            }
            .img {
                opacity: 0;
                position: absolute;
                z-index: 10;
                width: 708px;
                height: 524px;
                overflow: hidden;
                transition: all .5s ease;
                img {
                    width: 100%;
                    height: 100%;
                    transition: all .5s ease;
                }
                &:hover {
                    opacity: .9;
                    img {
                        transform: scale(1.02);
                    }
                }
            }
            .text {
                opacity: 0;
                position: absolute;
                width: 320px;
                height: 320px;
                background: #346cdd;
                text-align: center;
                padding: 0 50px;
                padding-top: 77px;
                box-sizing: border-box;
                color: #fff;
                transition: all 1s ease;
                h5 {
                    line-height: 30px;
                    font-size: 30px;
                    font-weight: bold;
                }
                .content {
                    margin-top: 20px;
                    text-align: left;
                    font-size: 14px;
                    line-height: 28px;
                    height: 28*6px;
                    overflow: hidden;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
                    -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
                    -webkit-line-clamp: 6; /** 显示的行数 **/
                }
            }
        }
    }
}
.TheTeam {
    display: none;
}
@media screen and (max-width: 1740px) {
    .viewpoint-three-right {
        padding-left: 50px;
    }
}
@media screen and (max-width: 1690px) {
    .viewpoint-three-left {
        width: 500px;
    }
    .viewpoint-three-phone {
        left: 45%;
    }
    .viewpoint-three-right {
        padding-left: 25px;
    }
}
//@media screen and (max-width: 1550px) {
//	.viewpoint-three-right-box {
//		width: 921px;
//	}
//}
//@media screen and (max-width: 1470px) {
//	.viewpoint-three-right-box {
//		width: 771px;
//	}
//}
@media screen and (max-width: 1450px) {
    .viewpoint-banner-box .right {
        padding-left: 40px;
    }
    .viewpoint-four {
        height: 970px;
        padding-top: 60px;
    }
    .viewpoint-four-box {
        width: 1100px;
        min-width: 0px;
        max-width: none;
        margin-top: 30px;
    }
    .viewpoint-four-li .img {
        width: 550px;
        height: 407px;
    }
    .viewpoint-four .swiper-slide-active .viewpoint-four-li:nth-child(1) .text {
        left: 550px;
    }
    .viewpoint-four-li:nth-child(2) {
        left: 330px;
    }
    .viewpoint-four-li:nth-child(2) .img {
        left: 220px;
    }
    .viewpoint-four-li:nth-child(2) .text {
        top: 87px;
    }
    .viewpoint-four-box-on .swiper-slide-active .viewpoint-four-li:nth-child(2) .text {
        left: -100px;
    }
    .viewpoint-four-pn {
        left: 170px;
        top: 407px;
    }
    .viewpoint-four-pn2 {
        left: 550+320px;
        top: 0px;
    }
    .viewpoint-four-li .text h5 {
        font-size: 26px;
        line-height: 26px;
    }
    .viewpoint-banner {
        background-position-y: -215px;
        height: 600px;
    }
    .viewpoint-banner-box .left {
        text-align: center;
    }
    .viewpoint-banner-box .left img {
        width: 350px;
    }
    .viewpoint-banner-box .right {
        padding-top: 200px;
    }
    .viewpoint-banner-box .right h5 {
        font-size: 40px;
        line-height: 40px;
        padding-left: 80px;
    }
    .viewpoint-banner-box .right h5:after {
        width: 70px;
        height: 62.56px;
        top: -10px;
    }
    .viewpoint-banner-box .right h6 {
        margin-top: 25px;
        font-size: 30px;
        line-height: 30px;
    }
    .viewpoint-title h5 {
        font-size: 35px;
        line-height: 35px;
    }
    .viewpoint-title h6 {
        font-size: 14px;
        letter-spacing: 2px;
    }
    .viewpoint-two-text-box h5 {
        font-size: 26px;
        line-height: 42px;
        span {
            font-size: 38px;
        }
    }
    .viewpoint-three-phone {
        width: 400px;
        height: 595px;
    }
    .viewpoint-three-phone-img {
        top: 127px;
        left: 59px;
        width: 276px;
        height: 470px;
    }
    .viewpoint-three-right h5 {
        font-size: 32px;
        line-height: 32px;
    }
    .viewpoint-three-right p {
        font-size: 20px;
        line-height: 34px;
    }
    .viewpoint-three {
        height: 650px;
    }
    .viewpoint-three-pn {
        height: 260px;
    }
    .viewpoint-advantage-btn-li h5 {
        font-size: 20px;
    }
    .viewpoint-advantage-text-right h5 {
        font-size: 28px;
        line-height: 28px;
    }
    .viewpoint-advantage-text-num {
        width: 55px;
        height: 55px;
        line-height: 55px;
        font-size: 30px;
    }
    .viewpoint-two-btn-li > div h5 {
        font-size: 20px;
        line-height: 20px;
    }
}
@media screen and (max-width: 1380px) {
    .viewpoint-advantage-btn {
        top: 220px;
        margin-left: -261px;
    }
}