.loading-overlay {
    content: "";
    position: relative;
    z-index: 1000;
    opacity: .8;
    background: rgba(0, 0, 0, .1);
    transition: all .6s;
}
.sk-three-bounce {
    position: relative;
    opacity: .8;
}
.sk-three-bounce .sk-child {
    width: 20px;
    height: 20px;
    background-color: #d12322;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
    animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
}
.sk-three-bounce .sk-bounce1 {
    -webkit-animation-delay: -.32s;
    animation-delay: -.32s;
}
.sk-three-bounce .sk-bounce2 {
    -webkit-animation-delay: -.16s;
    animation-delay: -.16s;
}
.sk-three-bounce .sk-bounce3 {
    -webkit-animation-delay: -.08s;
    animation-delay: -.08s;
}
@-webkit-keyframes sk-three-bounce {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes sk-three-bounce {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
.new-banner-2021 {
    width: 100%;
    min-width: 1250px;
    height: 330px;
    @media screen and (max-width: 1500px) {
        height: 250px;
    }
    .img {
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
        }
    }
}
.new-banner {
    width: 100%;
    min-width: 1250px;
    height: 800px;
    background: #fff none no-repeat center center;
    background-size: cover;
    &-address {
        background: url(../images/new_icon_2.png) no-repeat center left;
        padding-left: 25px;
        float: left;
        color: #fff;
        margin-top: 40px;
        font-size: 12px;
        line-height: 40px;
        a {
            color: #fff;
            font-size: 12px;
            padding: 0 3px;
            &:hover {
                color: rgba(255, 255, 255, .5);
            }
        }
    }
    &-title {
        float: right;
    }
    &-box {
        margin: 0 auto;
        min-width: 1250px;
        max-width: 1443px;
        padding-top: 150px;
        .new-title {
            text-align: right;
            font-size: 40px;
            color: #fff;
            line-height: 40px;
        }
        .new-title-en {
            padding-right: 30px;
            line-height: 45px;
            font-size: 12px;
            color: rgba(255, 255, 255, .5);
            text-align: right;
            text-transform: uppercase;
        }
    }
    &-content {
        position: relative;
        margin-top: 15px;
        height: 470px;
        width: 100%;
        background: #fff;
        padding-left: 390px;
        box-sizing: border-box;
        .left {
            position: absolute;
            top: 0;
            left: 0;
            border-right: 1px solid rgba(0, 0, 0, .05);
            height: 100%;
            width: 390px;
            padding: 80px 70px 0px 65px;
            box-sizing: border-box;
            background-repeat: no-repeat !important;
            .title {
                font-size: 30px;
                color: #d12322;
                line-height: 34px;
                text-transform: uppercase;
                font-family: DINCond-MediumAlternate;
                padding-right: 30px;
                box-sizing: border-box;
                margin-bottom: 5px;
                font-weight: bold;
            }
            &-text {
                margin-bottom: 20px;
                font-size: 18px;
                color: #444;
                line-height: 26px;
                white-space: nowrap;
                font-weight: bold;
            }
            &-text2 {
                font-size: 14px;
                color: rgba(0, 0, 0, .6);
                line-height: 26px;
                height: 26*4px;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
                -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
                -webkit-line-clamp: 4; /** 显示的行数 **/
                overflow: hidden; /** 隐藏超出的内容 **/
            }
            &-btn {
                margin-top: 40px;
                font-size: 0px;
                li {
                    display: inline-block;
                    vertical-align: top;
                    width: 100px;
                    height: 27px;
                    margin-bottom: 20px;
                    text-align: center;
                    a {
                        font-size: 12px;
                        color: #fff;
                        display: block;
                        width: 100%;
                        height: 100%;
                        line-height: 27px;
                        background: #5c5d5d;
                        transition: all .3s ease;
                        &:hover {
                            background: #d12322;
                        }
                    }
                    &:nth-child(odd) {
                        margin-right: 20px;
                    }
                }
            }
        }
        .right {
            position: relative;
            width: 100%;
            height: 100%;
            padding-left: 70px;
            padding-top: 60px;
            box-sizing: border-box;
            overflow: hidden;
            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 56px;
                height: 52px;
                background: url(../images/new_icon_3.png) no-repeat;
            }
            .swiper-slide {
                opacity: 0 !important;
            }
            .swiper-slide-active {
                opacity: 1 !important;
            }
            &-pagination {
                position: absolute;
                z-index: 5;
                right: 40px;
                top: 50%;
                transform: translateY(-50%);
                span {
                    display: block;
                    background: #bebebe;
                    opacity: 1;
                    margin: 20px 0;
                    width: 10px;
                    height: 10px;
                }
                .swiper-pagination-bullet-active {
                    background: #d12322;
                }
            }
            &-box {
                position: relative;
                width: 100%;
                height: 100%;
                overflow: hidden;
                font-size: 0;
                .img {
                    display: inline-block;
                    vertical-align: top;
                    width: (530/1010) * 100%;
                    &:hover {
                        img {
                            transform: scale(1.04);
                        }
                    }
                    &-box {
                        position: relative;
                        width: 100%;
                        overflow: hidden;
                        padding-bottom: (347/540) * 100%;
                        img {
                            position: absolute;
                            width: 100%;
                            height: auto !important;
                            transition: all .3s ease;
                        }
                    }
                }
                .right-text {
                    padding-left: 30px;
                    margin-right: 100px;
                    box-sizing: border-box;
                    width: calc((480 / 1010) * 100% - 100px);
                    display: inline-block;
                    vertical-align: top;
                    cursor: pointer;
                    &:hover {
                        .title,
                        .title1 {
                            color: #d12322;
                        }
                        .title1 {
                            &:after {
                                background: #d12322;
                            }
                        }
                        .btn {
                            background: #d12322;
                        }
                    }
                    .title {
                        transition: all .2s ease;
                        margin-top: 20px;
                        font-size: 12px;
                        color: rgba(0, 0, 0, .8);
                    }
                    .title1 {
                        transition: all .2s ease;
                        position: relative;
                        margin-top: 14px;
                        font-size: 20px;
                        line-height: 32px;
                        color: #000;
                    }
                    .time {
                        font-size: 14px;
                        line-height: 30px;
                        color: #aaa;
                    }
                    .text {
                        margin-top: 20px;
                        box-sizing: border-box;
                        font-size: 14px;
                        color: #888;
                        line-height: 30px;
                        height: 30*3px;
                        word-break: break-all;
                        text-overflow: ellipsis;
                        display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
                        -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
                        -webkit-line-clamp: 3; /** 显示的行数 **/
                        overflow: hidden; /** 隐藏超出的内容 **/
                    }
                    .btn {
                        width: 100px;
                        height: 35px;
                        margin-top: 55px;
                        text-align: center;
                        font-size: 14px;
                        color: #fff;
                        line-height: 35px;
                        background: #666;
                        transition: all .3s ease;
                    }
                }
            }
        }
        &.no-image {
            height: 420px;
            .right-box {
                .right-text {
                    width: calc(100% - 100px);
                    padding-left: 0;
                    .time {
                        margin-top: 6px;
                    }
                    .btn {
                        margin-top: 45px;
                    }
                }
            }
        }
    }
}
.new-content {
    padding-bottom: 55px;
    background: #f1f1f1;
    width: 100%;
    min-width: 1250px;
    &-address {
        max-width: 1440px;
        margin: 0 auto;
        padding-bottom: 20px;
        @media screen and (max-width: 1480px) {
            max-width: 1060px;
        }
    }
    .about-new {
        padding: 0px;
        background: none;
        .about-new-content {
            max-width: 1440px;
            padding-top: 0px;
            display: block;
            opacity: 1;
            @media screen and (max-width: 1480px) {
                max-width: 1060px;
            }
            .about-new-li-box {
                padding: 20px 0px;
                overflow: hidden;
                .swiper-slide {
                    opacity: 0 !important;
                }
                .swiper-slide-active {
                    opacity: 1 !important;
                }
                .pagination-about {
                    position: relative;
                    z-index: 10;
                    text-align: center;
                    margin-top: 30px;
                    .swiper-pagination-bullet {
                        width: 10px;
                        height: 10px;
                    }
                    .swiper-pagination-bullet-active {
                        background: #b60a00;
                    }
                }
                &:nth-child(1) {
                    margin-bottom: 0;
                    .text .time {
                        left: 30px;
                    }
                }
            }
        }
    }
    .new-address2 {
        font-size: 14px;
        line-height: 20px;
        color: #777;
        background: url(../images/new_icon_2.png) no-repeat center left;
        padding-left: 26px;
        a {
            padding: 0 3px;
            color: #777;
            &:hover {
                color: #d12322;
            }
        }
    }
    .news-list {
        margin-top: 70px;
        margin-bottom: 70px;
        li {
            margin-bottom: 20px;
        }
        .item {
            display: block;
            background-color: #fff;
            padding: 38px 35px;
            transition: .3s;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 3px;
                background-color: #d12322;
                transition: .6s;
            }
            &:hover {
                box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, .2);
                &::before {
                    width: 100%;
                }
                .more-btn {
                    color: #d22827;
                }
            }
            .title {
                font-size: 22px;
                color: #000;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                span {
                    @include textEllipsis;
                }
                &::before {
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: #d12322;
                    margin-right: 10px;
                    flex-shrink: 0;
                }
            }
            .desc {
                font-size: 15px;
                color: #666;
                @include multiEllipsis;
                line-height: 2;
                margin-bottom: 17px;
            }
            .more-btn {
                font-size: 14px;
                color: #999;
                transition: .3s;
            }
        }
    }
    .htmleaf-container {
        margin-top: 60px;
        padding-bottom: 70px;
    }
    &-box {
        position: relative;
        margin: -180px auto 0;
        max-width: 1470px;
        min-width: 1250px;
        &-a-ul {
            font-size: 0;
            text-align: center;
            margin-bottom: 10px;
            .on {
                background: #d12322;
                color: #fff;
            }
        }
        &-a-li {
            margin-left: 1px;
            display: inline-block;
            vertical-align: top;
            text-align: center;
            width: 120px;
            height: 45px;
            line-height: 45px;
            color: #fff;
            font-size: 16px;
            background: rgba(0, 0, 0, .6);
            transition: all .3s ease;
            &:hover {
                background: #d12322;
                color: #fff;
            }
        }
    }
    .grid {
        display: none;
        position: relative;
        margin: 0 auto;
        width: 100%;
    }
    .grid-on {
        display: block;
    }
    .grid-load {
        display: block;
        h1 {
            margin-top: 10px;
            font-size: 16px;
        }
    }
    .grid-load-on {
        display: none;
    }
    .grid-item {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 347px;
        min-height: 120px;
        padding: 20px;
        padding-bottom: 35px;
        box-sizing: border-box;
        background-color: #fff;
        transition: .3s ease-in-out;
        &:hover {
            box-shadow: 0 15px 30px rgba(0, 0, 0, .1);
            margin-top: -5px;
            .text .title {
                color: #d12322;
            }
        }
        .img {
            width: 100%;
            img {
                width: 100%;
                height: auto !important;
            }
        }
        .text {
            padding: 40px 10px 0px;
            box-sizing: border-box;
            text-align: left;
            .title {
                line-height: 19px;
                font-size: 19px;
                color: #000;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                transition: .3s ease-in-out;
            }
            .content {
                margin-top: 14px;
                font-size: 13px;
                line-height: 26px;
                max-height: 52px;
                color: #888;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }
            .time {
                padding-top: 20px;
                color: #888;
                font-size: 12px;
                font-family: "Arial";
            }
        }
    }
    .article-content {
        display: flex;
        max-width: 1440px;
        min-width: 1250px;
        margin: 15px auto 0;
        .content-left {
            position: relative;
            width: (1050 / 1440) * 100%;
            background-color: #fff;
            .nr {
                padding: 60px 85px 95px;
                box-sizing: border-box;
                .title {
                    font-size: 32px;
                    line-height: 1;
                    color: #333;
                    text-align: center;
                }
                .sub {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 20px;
                    .box {
                        margin: 0 10px;
                        font-size: 14px;
                        line-height: 1;
                        color: #999;
                    }
                    .share {
                        display: flex;
                        align-items: center;
                        .share-box {
                            display: flex;
                            align-items: center;
                            a {
                                display: block;
                                margin: 0 5px;
                                padding: 0;
                                float: none;
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                border: 1px solid #dedede;
                                background-repeat: no-repeat !important;
                                background-position: center !important;
                            }
                            a:nth-of-type(1) {
                                background-image: url(../images/icon202107.png) !important;
                            }
                            a:nth-of-type(2) {
                                background-image: url(../images/icon202101.png) !important;
                            }
                            a:nth-of-type(3) {
                                background-image: url(../images/icon202103.png) !important;
                            }
                            a:nth-of-type(4) {
                                background-image: url(../images/icon202105.png) !important;
                            }
                            a:nth-of-type(1):hover {
                                background-image: url(../images/icon202108.png) !important;
                            }
                            a:nth-of-type(2):hover {
                                background-image: url(../images/icon202102.png) !important;
                            }
                            a:nth-of-type(3):hover {
                                background-image: url(../images/icon202104.png) !important;
                            }
                            a:nth-of-type(4):hover {
                                background-image: url(../images/icon202106.png) !important;
                            }
                            a:hover {
                                background-color: #545454;
                                border: 1px solid #dedede;
                            }
                        }
                    }
                }
                .article {
                    margin-top: 40px;
                    padding-top: 52px;
                    box-sizing: border-box;
                    border-top: 1px solid #eee;
                    font-size: 14px;
                    line-height: 30px;
                    color: #666;
                    @include articleReset(14px);
                    p {
                        margin-bottom: 28px;
                    }
                    .img {
                        text-align: center;
                        cursor: pointer;
                    }
                    h3 {
                        font-size: 14px;
                        line-height: 30px;
                        color: #666;
                        margin-bottom: 10px;
                    }
                    .s {
                        font-size: 14px;
                        line-height: 30px;
                        color: #999;
                        margin-bottom: 0;
                    }
                    .pt10 {
                        padding-top: 20px;
                    }
                }
                .turn {
                    display: flex;
                    justify-content: space-between;
                    padding-top: 38px;
                    margin-top: 54px;
                    border-top: 1px dashed #d7d7d7;
                    a {
                        display: block;
                        font-size: 14px;
                        line-height: 1;
                        color: #333;
                        transition: all .3s;
                    }
                    a:hover {
                        color: #d12322;
                    }
                }
            }
        }
        .content-right {
            position: relative;
            width: (390 / 1440) * 100%;
            background-color: #fafafa;
            padding: 68px (41.5 / 1440) * 100%;
            box-sizing: border-box;
            .close {
                position: absolute;
                right: 20px;
                top: 24px;
                font-size: 16px;
                line-height: 1;
                color: #333;
                padding-left: 25px;
                box-sizing: border-box;
            }
            .close:before {
                position: absolute;
                content: '';
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 16px;
                height: 16px;
                background: url(../images/close.png) center;
            }
            .title {
                font-size: 20px;
                line-height: 1;
                font-weight: bold;
                color: #333;
                padding-left: 15px;
                box-sizing: border-box;
                position: relative;
                margin-bottom: 30px;
            }
            .title:before {
                position: absolute;
                top: 0;
                left: 0;
                width: 6px;
                height: 20px;
                content: '';
                border-radius: 6px;
                background-color: #b41515;
            }
            .content-box {
                width: 100%;
                display: block;
                margin-bottom: 60px;
                .img {
                    position: relative;
                    width: 100%;
                    padding-bottom: (204 / 307) * 100%;
                    overflow: hidden;
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        max-width: 100%;
                        max-height: 100%;
                        transition: all .3s;
                    }
                }
                .col-title {
                    font-size: 18px;
                    line-height: 18px;
                    height: 18px;
                    color: #000;
                    margin-top: 30px;
                    @include multiEllipsis(1);
                }
                .dsc {
                    font-size: 14px;
                    line-height: 26px;
                    height: 52px;
                    @include multiEllipsis(2);
                    color: #888;
                    margin-top: 12px;
                    padding: 0 5px;
                    box-sizing: border-box;
                }
                .time {
                    font-size: 12px;
                    line-height: 1;
                    color: #999;
                    margin-top: 20px;
                    padding: 0 5px;
                    box-sizing: border-box;
                }
            }
            .content-box:nth-last-child(1) {
                margin-bottom: 0;
            }
            .content-box:hover {
                .img {
                    img {
                        transform: translate(-50%, -50%) scale(1.1);
                    }
                }
                .col-title {
                    color: #d12322;
                }
            }
        }
        .content-right-fixed {
            position: fixed;
            z-index: 3;
            top: 75px;
            right: calc(50vw - 728px);
            width: 390px;
            @media screen and (max-width: 1460px) {
                width: (390 / 1440) * 100%;
                right: 0;
            }
        }
    }
}
.pb0 {
    padding-bottom: 0;
}
@media screen and (max-width: 1460px) {
    .new-banner {
        height: 680px;
    }
    .new-banner-box {
        padding-top: 80px;
        width: 1200px;
        min-width: 0;
    }
    .new-banner-content {
        height: 420px;
    }
    .new-banner-content.no-image {
        height: 370px;
    }
    .new-banner-content .left {
        padding-top: 60px;
    }
    .new-banner-content.no-image .left {
        padding-top: 45px;
        padding-left: 50px;
        padding-right: 40px;
    }
    .new-banner-content .left .title {
        margin-bottom: 25px;
        font-size: 33px;
        line-height: 33px;
    }
    .new-banner-content .left-btn {
        margin-top: 20px;
    }
    .new-banner-content .right {
        padding-left: 40px;
    }
    .new-banner-content.no-image .right {
        padding-top: 50px;
    }
    .new-banner-content .right-box .right-text .title {
        margin-top: 0px;
    }
    .new-banner-content .right-box .right-text {
        padding-right: 60px;
        padding-left: 30px;
    }
    .new-banner-content .right-box .right-text .btn,
    .new-banner-content.no-image .right-box .right-text .btn {
        margin-top: 15px;
    }
    .new-content {
        padding-top: 30px;
    }
    .new-content .htmleaf-container {
        padding-bottom: 30px;
    }
}
