.school-container {
    min-width: 1250px;
    .item-box {
        position: relative;
        overflow: hidden;
    }
    .scene-1 {
        background: url(../images/school_1.jpg) no-repeat center center;
        background-size: cover;
        height: 800px;
        .word {
            position: absolute;
            left: 50%;
            transform: translate(-50%, -20px);
            top: 156px;
            text-align: center;
            opacity: 0;
            transition: all .6s 1s ease;
        }
        .line-box {
            position: absolute;
            left: 50%;
            transform: translate(-50%, 20px);
            top: 320px;
            background: url(../images/school_3.png) no-repeat center center;
            width: 1020px;
            height: 255px;
            opacity: 0;
            transition: all .6s 1.2s ease;
        }
        .dot {
            position: absolute;
            width: 140px;
            height: 140px;
            border-radius: 100%;
            background-color: #ff7200;
            font-size: 34px;
            color: #fff;
            font-weight: bold;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all .4s ease;
            box-sizing: border-box;
            padding: 0 20px;
            opacity: 0;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            h2 {
                font-size: 34px;
                font-weight: bold;
                line-height: 40px;
            }
            &:nth-child(1) {
                top: 20px;
                left: -34px;
            }
            &:nth-child(2) {
                bottom: -72px;
                left: 214px;
            }
            &:nth-child(3) {
                top: -35px;
                left: 592px;
            }
            &:nth-child(4) {
                top: 58px;
                right: -54px;
            }
        }
        &.anim {
            .word {
                opacity: 1;
                transform: translate(-50%, 0);
            }
            .line-box {
                opacity: 1;
                transform: translate(-50%, 0);
            }
            .dot {
                animation-name: rubberBand;
                &:nth-child(1) {
                    animation-delay: 1.5s;
                }
                &:nth-child(2) {
                    animation-delay: 2s;
                }
                &:nth-child(3) {
                    animation-delay: 2.5s;
                }
                &:nth-child(4) {
                    animation-delay: 3s;
                }
            }
        }
        @media screen and (max-width: 1440px) {
            height: 750px;
            .word {
                top: 100px;
            }
        }
        @media screen and (max-width: 1366px) {
            height: 700px;
            .word {
                top: 80px;
                img {
                    width: 90%;
                }
            }
            .line-box {
                top: 225px;
            }
            .dot {
                width: 120px;
                height: 120px;
                padding: 0 30px;
                h2 {
                    font-size: 26px;
                }
                &:nth-child(4) {
                    right: -44px;
                }
            }
        }
    }
    .scene-2 {
        height: 850px;
        background: url(../images/school_4.jpg) no-repeat center center;
        background-size: cover;
        .word {
            position: absolute;
            top: 80px;
            text-align: center;
            color: #333;
            width: 100%;
            left: 0;
            h2 {
                font-size: 55px;
                margin-bottom: 10px;
                transition: all .4s .5s ease;
                opacity: 0;
                transform: translateY(20px);
            }
            p {
                font-size: 26px;
                transition: all .4s 1s ease;
                opacity: 0;
                transform: translateY(20px);
            }
        }
        .phone {
            position: absolute;
            bottom: 25px;
            z-index: 2;
            left: 50%;
            margin-left: -262px;
        }
        .teacher {
            position: absolute;
            left: 50%;
            top: 438px;
            z-index: 3;
            margin-left: -563px;
            transition: all 1s 1.2s ease;
            opacity: 0;
        }
        .student {
            position: absolute;
            left: 50%;
            top: 438px;
            z-index: 5;
            margin-left: 488px;
            transition: all 1s 1.4s ease;
            opacity: 0;
        }
        .message {
            position: absolute;
            left: 50%;
            top: 375px;
            z-index: 4;
            margin-left: 160px;
            opacity: 0;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }
        &.anim {
            .word {
                h2,
                p {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
            .teacher,
            .student {
                opacity: 1;
            }
            .message {
                animation-name: rubberBand;
                animation-delay: 2s;
            }
        }
        @media screen and (max-width: 1440px) {
            height: 800px;
            .word {
                top: 50px;
                h2 {
                    font-size: 48px;
                }
            }
        }
        @media screen and (max-width: 1366px) {
            height: 700px;
            .word {
                h2 {
                    font-size: 42px;
                }
                p {
                    font-size: 22px;
                }
            }
            .phone {
                img {
                    height: 480px;
                }
            }
            .teacher {
                margin-left: -520px;
            }
            .student {
                top: 420px;
                margin-left: 430px;
            }
            .message {
                top: 360px;
                margin-left: 95px;
            }
        }
    }
    .scene-3 {
        background: url(../images/school_9.jpg) no-repeat center center;
        background-size: cover;
        height: 850px;
        .bus {
            position: absolute;
            left: 50%;
            margin-left: -604px;
            bottom: 69px;
            transform: translateX(-1250px);
            transition: all .6s ease-in-out;
        }
        .word {
            position: absolute;
            margin-left: -600px;
            top: 145px;
            left: 50%;
            color: #333;
            h2 {
                font-size: 55px;
                opacity: 0;
                transform: translateY(20px);
                transition: all .4s .5s ease;
            }
            p {
                font-size: 26px;
                opacity: 0;
                transform: translateY(10px);
                transition: all .2s .8s ease;
            }
        }
        .icon-list {
            position: absolute;
            margin-left: -600px;
            top: 283px;
            left: 50%;
            color: #333;
            font-size: 0;
            opacity: 0;
            transition: all .2s 1s ease;
            li {
                vertical-align: top;
                width: 120px;
                height: 160px;
                border-radius: 54px;
                background-color: #bae5f4;
                border: 1px solid #7dc9e2;
                box-sizing: border-box;
                color: #0b3947;
                display: inline-flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-right: 5px;
                box-shadow: 1px 1px 2px #abd0db;
                i {
                    font-size: 50px;
                    margin-bottom: 15px;
                }
                h3 {
                    font-size: 20px;
                }
            }
        }
        .icon-xiaoche {
            &:before {
                content: '\e64e';
            }
        }
        .icon-pay {
            &:before {
                content: '\e651';
            }
        }
        .icon-daka {
            &:before {
                content: '\e649';
            }
        }
        .icon-xiaochedingwei {
            &:before {
                content: '\e659';
            }
        }
        .icon-tongzhi {
            &:before {
                content: '\e797';
            }
        }
        &.anim {
            .bus {
                transform: translateX(0);
            }
            .word {
                h2,
                p {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
            .icon-list {
                opacity: 1;
                li {
                    animation-name: topbottom;
                    animation-duration: 1s;
                    &:nth-child(1) {
                        animation-delay: 1s;
                    }
                    &:nth-child(2) {
                        animation-delay: 1.5s;
                    }
                    &:nth-child(3) {
                        animation-delay: 2s;
                    }
                    &:nth-child(4) {
                        animation-delay: 2.5s;
                    }
                    &:nth-child(5) {
                        animation-delay: 3s;
                    }
                }
            }
        }
        @media screen and (max-width: 1440px) {
            height: 800px;
            .word {
                top: 80px;
                h2 {
                    font-size: 48px;
                }
            }
            .icon-list {
                top: 220px;
            }
        }
        @media screen and (max-width: 1366px) {
            height: 700px;
            .word {
                h2 {
                    font-size: 42px;
                }
                p {
                    font-size: 22px;
                }
            }
            .icon-list {
                li {
                    width: 100px;
                    height: 140px;
                    i {
                        font-size: 36px;
                    }
                    h3 {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .scene-4 {
        background: url(../images/school_11.jpg) no-repeat center center;
        background-size: cover;
        height: 850px;
        .word {
            color: #333;
            position: absolute;
            left: 50%;
            margin-left: 252px;
            top: 210px;
            width: 350px;
            opacity: 0;
            transition: all .8s ease;
            h2 {
                font-size: 55px;
            }
            p {
                font-size: 26px;
            }
        }
        .img {
            top: 170px;
            position: absolute;
            left: 50%;
            margin-left: -600px;
            opacity: 0;
            transform: translateX(-30px);
            transition: all .8s ease;
        }
        &.anim {
            .word {
                opacity: 1;
            }
            .img {
                opacity: 1;
                transform: translateX(0);
            }
        }
        @media screen and (max-width: 1440px) {
            height: 800px;
            .word {
                top: 180px;
                h2 {
                    font-size: 48px;
                }
            }
            .img {
                top: 140px;
            }
        }
        @media screen and (max-width: 1366px) {
            height: 700px;
            .word {
                top: 140px;
                margin-left: 190px;
                h2 {
                    font-size: 42px;
                }
                p {
                    font-size: 22px;
                }
            }
            .img {
                top: 100px;
                img {
                    width: 650px;
                }
            }
        }
    }
    .scene-5 {
        background: url(../images/school_14.jpg) no-repeat center center;
        background-size: cover;
        height: 850px;
        .front-img {
            position: absolute;
            width: 100%;
            height: 100%;
            background: url(../images/school_13.png) no-repeat center bottom;
            background-size: cover;
            top: 0;
            left: 0;
            z-index: 4;
        }
        .page-img {
            position: absolute;
            z-index: 3;
            top: 498px;
            left: 50%;
            margin-left: -604px;
            opacity: 0;
        }
        .word {
            position: absolute;
            top: 160px;
            left: 50%;
            margin-left: -600px;
            color: #333;
            z-index: 5;
            opacity: 0;
            transition: all 1s ease;
            h2 {
                font-size: 55px;
                margin-bottom: 10px;
            }
            p {
                font-size: 26px;
            }
        }
        &.anim {
            .word {
                opacity: 1;
            }
            .page-img {
                animation-name: messageShake;
                animation-duration: 10s;
                animation-fill-mode: forwards;
                animation-iteration-count: infinite;
            }
        }
        @media screen and (max-width: 1440px) {
            height: 800px;
            .word {
                top: 130px;
                h2 {
                    font-size: 48px;
                }
            }
            .page-img {
                margin-left: -566px;
                top: 470px;
            }
        }
        @media screen and (max-width: 1366px) {
            height: 700px;
            .word {
                top: 100px;
                h2 {
                    font-size: 42px;
                }
                p {
                    font-size: 22px;
                }
            }
            .page-img {
                margin-left: -500px;
                top: 410px;
            }
        }
    }
}
@keyframes rubberBand {
    from {
        transform: scale3d(1, 1, 1);
        opacity: 0;
    }
    30% {
        transform: scale3d(1.25, .75, 1);
    }
    40% {
        transform: scale3d(.75, 1.25, 1);
    }
    50% {
        transform: scale3d(1.15, .85, 1);
    }
    65% {
        transform: scale3d(.95, 1.05, 1);
    }
    75% {
        transform: scale3d(1.05, .95, 1);
    }
    to {
        transform: scale3d(1, 1, 1);
        opacity: 1;
    }
}
@keyframes topbottom {
    from,
    to {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}
@keyframes messageShake {
    from,
    to {
        opacity: 1;
        transform: translateY(0);
    }
    50% {
        opacity: 1;
        transform: translateY(-20px);
    }
}