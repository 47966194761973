/*banner*/
.index-banner {
    position: relative;
    min-width: 1250px;
    //height: 810px;
    .pagination-index {
        position: absolute;
        text-align: center;
        bottom: 20px;
        left: 0px;
        width: 100%;
        z-index: 10;
        span {
            width: 10px;
            height: 10px;
            background: #3d3d3d;
            opacity: 1;
        }
        .swiper-pagination-bullet-active {
            background: #b60a00;
        }
    }
    .swiper-banner {
        //position: absolute;
        //top: 0;
        //left: 0;
        //width: 100%;
        //height: 100%;
        //overflow: hidden;
        .swiper-slide {
            position: relative;
        }
        .theanimate {
            .text-one {
                h5 {
                    transition: all .5s ease-out .2s;
                }
                p {
                    transition: all .7s ease-out .3s;
                }
                .btn-div {
                    transition: all .5s ease-out .5s;
                }
            }
            .text-two {
                h5 {
                    transition: all .7s ease-out .2s;
                }
                p {
                    &:nth-child(1) {
                        transition: all .7s ease-out .3s;
                    }
                    &:nth-child(2) {
                        transition: all .7s ease-out .4s;
                    }
                    &:nth-child(3) {
                        transition: all .7s ease-out .5s;
                    }
                    &:nth-child(4) {
                        transition: all .7s ease-out .6s;
                    }
                }
                .btn-div {
                    transition: all .7s ease-out .7s;
                }
            }
            .text-three {
                .text-box {
                    transition: all .8s ease-out .2s;
                }
                .three-ul .li {
                    &:nth-child(1) {
                        transition: all .5s ease-out .3s;
                    }
                    &:nth-child(2) {
                        transition: all .5s ease-out .4s;
                    }
                    &:nth-child(3) {
                        transition: all .5s ease-out .5s;
                    }
                    &:nth-child(4) {
                        transition: all .5s ease-out .6s;
                    }
                    &:nth-child(5) {
                        transition: all .5s ease-out .7s;
                    }
                    &:nth-child(6) {
                        transition: all .5s ease-out .8s;
                    }
                    &:nth-child(7) {
                        transition: all .5s ease-out .9s;
                    }
                }
            }
            .text-four {
                .text-box {
                    transition: all .8s ease-out .2s;
                }
                .three-ul .li {
                    &:nth-child(1) {
                        transition: all .5s ease-out .3s;
                    }
                    &:nth-child(2) {
                        transition: all .5s ease-out .4s;
                    }
                    &:nth-child(3) {
                        transition: all .5s ease-out .5s;
                    }
                    &:nth-child(4) {
                        transition: all .5s ease-out .6s;
                    }
                    &:nth-child(5) {
                        transition: all .5s ease-out .7s;
                    }
                    &:nth-child(6) {
                        transition: all .5s ease-out .8s;
                    }
                    &:nth-child(7) {
                        transition: all .5s ease-out .9s;
                    }
                }
            }
        }
        .theactive {
            .text-one {
                h5,
                p {
                    margin-left: 0;
                    opacity: 1;
                }
                .btn-div {
                    opacity: 1;
                    transform: scale(1);
                }
            }
            .text-two {
                h5 {
                    transform: scale(1);
                    opacity: 1;
                    margin-top: 0;
                }
                p {
                    transform: scale(1);
                    opacity: 1;
                    margin-top: 0;
                }
                .btn-div {
                    opacity: 1;
                    transform: scale(1);
                    margin-top: 0;
                }
            }
            .text-three {
                .text-box {
                    opacity: 1;
                    margin-left: 0;
                }
                .three-ul .li {
                    opacity: 1;
                    margin-top: 0;
                }
            }
        }
        .img {
            //position: absolute;
            //top: 0;
            //left: 0;
            //width: 100%;
            //height: 100%;
            //background: #FFF none no-repeat center center;
            //background-size: cover;
            position: relative;
            width: 100%;
            padding-bottom: (810/1920) * 100%;
            overflow: hidden;
            display: block;
            img {
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }
        .text-one {
            //width: 1200px;
            position: absolute;
            left: (150/1920) * 100%;
            right: 0;
            top: 185px;
            color: #fff;
            font-size: 26px;
            font-family: "SimHei";
            h5 {
                font-size: 45px;
                line-height: 64px;
                font-family: "SimHei";
                opacity: 0;
                margin-left: -100px;
            }
            p {
                opacity: 0;
                margin-left: -100px;
            }
            h5 + p {
                margin-top: 20px;
            }
            .btn-div {
                width: 86px;
                height: 46px;
                transform: scale(0);
                opacity: 0;
            }
            .btn {
                margin-top: 40px;
                display: block;
                width: 86px;
                height: 46px;
                background: url(../images/banner_icon_0.png) no-repeat center center;
                border-radius: 23px;
                border: 1px solid rgba(255, 255, 255, .5);
                transition: all .4s ease;
                &:hover {
                    width: 106px;
                    background-color: rgba(255, 255, 255, .3);
                }
            }
        }
        .text-two {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            width: 715px;
            text-align: center;
            margin: 0 auto;
            font-size: 34px;
            color: #fff;
            font-family: "SimHei";
            h5 {
                opacity: 0;
                transform: scale(1.5);
                margin-top: -30px;
                font-size: 51px;
                line-height: 87px;
                font-family: "SimHei";
                em {
                    vertical-align: bottom;
                    font-style: normal;
                }
                span {
                    padding-right: 6px;
                    vertical-align: bottom;
                    font-size: 87.02px;
                }
            }
            p {
                opacity: 0;
                transform: scale(1.5);
                margin-top: -30px;
            }
            .btn-div {
                opacity: 0;
                transform: scale(1.5);
                margin-top: -30px;
            }
            .btn {
                margin: 0 auto;
                margin-top: 32px;
                display: block;
                width: 190px;
                height: 48px;
                border-radius: 24px;
                background: url(../images/banner_icon_1.png);
                font-size: 18px;
                line-height: 48px;
                color: #fff;
                font-family: "SimHei";
                transition: all .3s ease;
                &:hover {
                    background: url(../images/banner_icon_2.png);
                }
            }
        }
        .text-three {
            position: absolute;
            left: (200/1920) * 100%;
            top: 200px;
            color: rgba(255, 255, 255, .7);
            font-size: 24.32px;
            font-family: "SimHei";
            .text-box {
                opacity: 0;
                margin-left: -80px;
            }
            h5 {
                font-size: 35px;
                color: #fff;
                line-height: 64px;
            }
            h5 + p {
                margin-top: 33px;
            }
            .three-ul {
                font-size: 0;
                padding-top: 45px;
                .li {
                    cursor: pointer;
                    opacity: 0;
                    margin-top: 30px;
                    margin-right: 19px;
                    display: inline-block;
                    vertical-align: top;
                    background-color: rgba(255, 255, 255, .1);
                    border-radius: 43px;
                    width: 86px;
                    height: 131px;
                    text-align: center;
                    padding-top: 28px;
                    box-sizing: border-box;
                    font-size: 15.04px;
                    color: #fff;
                    transition: all .3s ease;
                    &:hover {
                        p {
                            margin-top: 15px;
                        }
                    }
                    p {
                        box-sizing: border-box;
                        margin-top: 10px;
                        transition: all .3s ease;
                    }
                }
            }
        }
        .text-four {
            h5 {
                font-size: 60px;
                + p {
                    color: #fff;
                    margin-top: 20px;
                }
            }
            .three-ul {
                .li {
                    background-color: #fff;
                    color: #555;
                }
            }
        }
        .advisory-btn {
            position: absolute;
            width: 168px;
            height: 46px;
            color: #fff;
            text-align: center;
            line-height: 46px;
            transition: all .2s;
            border-radius: 2px;
            font-size: 16px;
            cursor: pointer;
            z-index: 2;
        }
        .advisory-4 {
            background-color: #0fb7db;
            top: (532/810) * 100%;
            left: (164/1920) * 100%;
            &:hover {
                background-color: lighten(#0fb7db, 5%);
            }
        }
        .advisory-1 {
            background-color: #13bde1;
            top: (429/810) * 100%;
            left: (334/1920) * 100%;
            &:hover {
                background-color: lighten(#13bde1, 5%);
            }
        }
        .advisory-6 {
            background-color: #3560a4;
            top: (551/810) * 100%;
            left: (217/1920) * 100%;
            &:hover {
                background-color: lighten(#3560a4, 5%);
            }
        }
        .advisory-2 {
            background-color: #13bde1;
            top: (700/810) * 100%;
            left: (876/1920) * 100%;
            &:hover {
                background-color: lighten(#13bde1, 5%);
            }
        }
        .advisory-5 {
            background-color: #13bde1;
            top: (500/810) * 100%;
            left: (202/1920) * 100%;
            &:hover {
                background-color: lighten(#13bde1, 5%);
            }
        }
        .advisory-3 {
            background-color: #13bde1;
            top: (540/810) * 100%;
            left: (220/1920) * 100%;
            &:hover {
                background-color: lighten(#13bde1, 5%);
            }
        }
    }
}
.index-advisory {
    &-dialog {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1002;
        display: none;
        .mask {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .6);
            position: absolute;
        }
    }
    &-box {
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        background-image: url(../images/advisory_bg.png);
        background-repeat: no-repeat;
        background-position: center top;
        position: absolute;
        box-sizing: border-box;
        width: 505px;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        backface-visibility: hidden;
        padding: 50px;
        .box {
            display: flex;
        }
        .info {
            flex: 1;
            overflow: hidden;
        }
        .avatar {
            line-height: 60px;
            margin-bottom: 30px;
            img {
                vertical-align: top;
                margin-right: 10px;
            }
            strong {
                font-size: 30px;
                color: #fff;
            }
        }
        .tip {
            font-size: 16px;
            color: #555;
            position: relative;
            padding-left: 15px;
            &:before {
                content: '“';
                position: absolute;
                top: 0;
                left: 0;
                font-size: 40px;
                line-height: 1;
                color: #e5e5e5;
                font-family: Arial;
            }
            &:after {
                content: '”';
                display: inline-block;
                vertical-align: top;
                height: 24px;
                line-height: 1.4;
                font-size: 40px;
                color: #e5e5e5;
                font-family: Arial;
            }
        }
        .ewm {
            width: 120px;
            height: 120px;
            padding: 10px;
            background: #fff;
            margin-left: 20px;
            box-shadow: 0 0 30px rgba(#3e81df, 1);
            border-radius: 6px;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
    &-close {
        position: absolute;
        top: 12px;
        right: 12px;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
        transition: all .3s;
        z-index: 3;
    }
}
@media screen and (max-width: 1500px) {
    .index-banner {
        //height: 632px;
    }
    .index-banner .swiper-banner .text-three {
        top: 120px;
    }
    .index-banner .swiper-banner .text-one {
        font-size: 20px;
    }
    .index-banner .swiper-banner .text-one h5 {
        font-size: 35px;
        line-height: 50px;
    }
    .index-banner .swiper-banner .text-one h5 + p {
        margin-top: 10px;
    }
    .index-banner .swiper-banner .text-one .btn {
        margin-top: 20px;
    }
    .index-banner .swiper-banner .text-two {
        font-size: 24px;
    }
    .index-banner .swiper-banner .text-two h5 {
        font-size: 40px;
        line-height: 60px;
        margin-bottom: 10px;
    }
    .index-banner .swiper-banner .text-two h5 span {
        font-size: 50px;
    }
    .index-banner .swiper-banner .text-two .btn {
        margin-top: 15px;
        width: 170px;
        height: 38px;
        line-height: 38px;
        border-radius: 19px;
    }
    .index-banner .swiper-banner .text-three {
        font-size: 20px;
    }
    .index-banner .swiper-banner .text-three h5 {
        font-size: 36px;
        line-height: 50px;
    }
    .index-banner .swiper-banner .text-three h5 + p {
        margin-top: 20px;
    }
    .index-banner .swiper-banner .text-three .three-ul {
        padding-top: 20px;
    }
    .index-banner .swiper-banner .text-three .three-ul .li {
        padding-top: 15px;
        width: 76px;
        font-size: 13px;
        height: 110px;
    }
}
@media screen and (max-width: 1366px) {
    .index-banner {
        //height: 576px;
    }
    .index-banner .swiper-banner .text-one {
        top: 120px;
    }
}
/*title*/
.index-title {
    text-align: center;
    .big {
        font-size: 55px;
        color: #000;
        font-family: "SimSun";
        line-height: 55px;
    }
    .small {
        font-size: 16px;
        color: #555;
        line-height: 40px;
    }
}
/*Our Business*/
.index-business {
    min-width: 1250px;
    background-color: #e5e5e5;
    padding: 80px 0 117px;
    &-box {
        position: relative;
        width: 1440px;
        margin: 30px auto 0;
        font-size: 0;
        &:after {
            position: absolute;
            width: 100%;
            height: 470px;
            background-image: url(../images/business_shadow.png);
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: 100% auto;
            left: 0;
            bottom: -50px;
            content: '';
        }
        &-ul {
            display: flex;
            font-size: 0;
            position: relative;
            z-index: 2;
        }
        &-li {
            flex: 290;
            overflow: hidden;
            background-color: #fff;
            display: flex;
            height: 500px;
            transition: all .4s linear;
            .title-box {
                position: relative;
                width: 290px;
                height: 500px;
                .front-img,
                .back-img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .back-img {
                    display: none;
                }
                .title {
                    text-align: center;
                    position: absolute;
                    top: 80px;
                    left: 0;
                    width: 100%;
                    z-index: 5;
                }
                .cn {
                    font-size: 28px;
                    color: #333;
                    a {
                        color: #333;
                    }
                }
                .en {
                    font-size: 12px;
                    text-transform: uppercase;
                    color: rgba(#333, .4);
                }
            }
            .info-box {
                flex: 1;
                overflow: hidden;
                position: relative;
                .wrapper {
                    position: absolute;
                    width: 570px;
                    left: 0;
                    top: 0;
                    height: 100%;
                    box-sizing: border-box;
                    padding: 30px;
                    font-size: 0;
                }
                .item {
                    display: inline-block;
                    vertical-align: top;
                    width: 33.33%;
                    text-align: center;
                    padding: 20px 0;
                    &:hover {
                        .t {
                            color: #60a7f7;
                        }
                    }
                }
                .icon {
                    width: 70px;
                    height: 70px;
                    border-radius: 100%;
                    overflow: hidden;
                    background: #eff3f5;
                    margin: 0 auto 15px;
                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .t {
                    font-size: 14px;
                    color: #666;
                }
            }
            &.on {
                flex: 860;
                .title-box {
                    &:after {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        border-right: 10px solid #fff;
                        border-top: 10px solid transparent;
                        border-bottom: 10px solid transparent;
                        content: '';
                        margin-top: -10px;
                        z-index: 5;
                    }
                    .front-img {
                        display: none;
                    }
                    .back-img {
                        display: block;
                    }
                    .cn {
                        color: #fff;
                        a {
                            color: #fff;
                        }
                    }
                    .en {
                        color: rgba(#fff, .4);
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1500px) {
    .index-title .big {
        font-size: 45px;
        line-height: 45px;
    }
    .index-title .small {
        font-size: 14px;
        line-height: 36px;
    }
}
@media screen and (max-width: 1460px) {
    .index-business-box {
        width: 1300px;
        &-li {
            .info-box {
                .wrapper {
                    width: 486px;
                }
            }
        }
    }
}
@media screen and (max-width: 1400px) {
    .index-business {
        padding: 80px 0;
    }
    .index-business-box {
        width: 1200px;
        &-li {
            .info-box {
                .wrapper {
                    width: 426px;
                }
            }
        }
    }
}
.index-brand-list {
    display: none;
}
.index-chose {
    position: relative;
    min-width: 1250px;
    padding-top: 128px;
    padding-left: 721px;
    box-sizing: border-box;
    height: 899px;
    overflow: hidden;
    .pagination-brand {
        margin-top: 65px;
        @media screen and (max-width: 1500px) {
            margin-top: 65px;
        }
        @media screen and (max-width: 1400px) {
            margin-top: 5px;
        }
        span {
            width: 10px;
            height: 10px;
            background: #555;
            opacity: 1;
            margin: 0 3px;
        }
        .swiper-pagination-bullet-active {
            background: #b60a00;
        }
    }
    &-left {
        position: absolute;
        top: 0;
        left: 0;
        width: 721px;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .index-title {
        margin-left: -110px;
    }
    &-box2 {
        position: relative;
        width: 100%;
        height: 100%;
        padding-left: 227px;
        box-sizing: border-box;
    }
    &-box {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        box-sizing: border-box;
        height: 562px;
        padding-left: 190px;
        padding-right: 75px;
        &-left {
            position: absolute;
            left: 0;
            top: 0;
            width: 227px;
            &-new {
                position: relative;
                display: block;
                width: 227px;
                height: 224px;
                overflow: hidden;
                &:after {
                    content: "";
                    top: 0;
                    left: 0;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: url(../images/chose_icon_0.png) no-repeat left top;
                }
                img {
                    width: 100%;
                    height: 100%;
                    transition: all .5s ease;
                }
                &:hover {
                    img {
                        transform: scale(1.05);
                    }
                }
            }
            &-link {
                display: block;
                width: 227px;
                height: 562-224px;
                background-color: #333;
                padding: 65px 40px 0;
                box-sizing: border-box;
                font-size: 23px;
                color: #fff;
                line-height: 33px;
                transition: all .5s ease;
                &:hover {
                    background: #2c2c2c;
                    p {
                        background-position-x: 20px;
                    }
                }
                p {
                    font-weight: bold;
                    padding-bottom: 85px;
                    transition: all .5s ease;
                    background: url(../images/chose_icon_1.png) no-repeat left bottom;
                }
            }
        }
        &-right {
            width: 100%;
            height: 100%;
            overflow: hidden;
            background: #fff;
            ul {
                border-top: 1px solid #d9d9d9;
                box-sizing: border-box;
                font-size: 0;
                width: 100%;
                height: 100%;
                li {
                    background: #fff;
                    display: inline-block;
                    vertical-align: top;
                    height: 112.5px;
                    width: (100/7) * 1%;
                    border-right: 1px solid #d9d9d9;
                    border-bottom: 1px solid #d9d9d9;
                    box-sizing: border-box;
                    a {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;
                        cursor: pointer;
                        &:hover {
                            .text {
                                opacity: 1;
                            }
                            .text h3 {
                                opacity: 1;
                                margin-top: 0;
                            }
                        }
                    }
                    .text {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        background: rgba(0, 0, 0, .6);
                        transition: all .4s ease-out;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        h3 {
                            text-align: center;
                            padding: 0 10px;
                            opacity: 0;
                            margin-top: 40px;
                            color: #fff;
                            font-size: 18px;
                            transition-delay: .1s;
                            transition-duration: .4s;
                            width: 100%;
                            box-sizing: border-box;
                            @media screen and (max-width: 1366px) {
                                font-size: 15px;
                            }
                        }
                    }
                    img {
                        max-width: 100%;
                        max-height: 100%;
                        transition: all .4s ease-out;
                    }
                }
            }
        }
    }
}
.index-case {
    min-width: 1250px;
    padding-top: 80px;
    padding-bottom: 100px;
    background: #e8e8e8;
    &-outline {
        position: relative;
        max-width: 1600px;
        min-width: 1250px;
        margin: 50px auto 0;
    }
    &-box {
        position: relative;
        z-index: 10;
        border: 1px solid #d1d1d1;
        background: #fff;
        width: 100%;
        box-sizing: border-box;
        padding: 110px 185px 100px;
    }
    &-box2 {
        position: relative;
        width: 100%;
        height: 1615px;
    }
    .shadow {
        position: absolute;
        bottom: -45px;
        left: 0;
        width: 100%;
        padding-bottom: (121/1602) * 100%;
        background: url(../images/case_icon_1.png) no-repeat;
        background-size: 100% 100%;
    }
    .text {
        padding-top: 20px;
        box-sizing: border-box;
        height: 89px;
        transition: all .4s ease-out;
        h3 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 16px;
            color: #444;
        }
        h6 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            color: #a3a3a3;
        }
    }
    .img {
        position: relative;
        width: 380px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        color: #888;
        overflow: hidden;
        transition: all .4s ease-out;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .item {
        position: absolute;
        width: 410px;
        padding: 0 15px;
        box-sizing: border-box;
        a {
            display: block;
            &:hover {
                .img {
                    box-shadow: 0 15px 30px rgba(0, 0, 0, .1);
                    transform: translate3d(0, -5px, 0);
                }
                .text {
                    transform: translate3d(0, -5px, 0);
                }
            }
        }
        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(11),
        &:nth-child(12) {
            height: 323px;
            .img {
                height: 234px;
            }
        }
        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(10) {
            height: 646px;
            .img {
                height: 557px;
            }
        }
        &:nth-child(1) {
            top: 0px;
            left: 0px;
        }
        &:nth-child(2) {
            top: 0px;
            left: 410px;
        }
        &:nth-child(3) {
            top: 0px;
            left: 820px;
        }
        &:nth-child(4) {
            top: 323px;
            left: 0px;
        }
        &:nth-child(5) {
            top: 323px;
            left: 820px;
        }
        &:nth-child(6) {
            top: 646px;
            left: 410px;
        }
        &:nth-child(7) {
            top: 646px;
            left: 820px;
        }
        &:nth-child(8) {
            top: 969px;
            left: 0px;
        }
        &:nth-child(9) {
            top: 969px;
            left: 410px;
        }
        &:nth-child(10) {
            top: 969px;
            left: 820px;
        }
        &:nth-child(11) {
            top: 1292px;
            left: 0px;
        }
        &:nth-child(12) {
            top: 1292px;
            left: 410px;
        }
    }
    .case-more {
        display: block;
        width: 379px;
        height: 51px;
        margin: 0 auto;
        margin-top: 25px;
        background: url(../images/case_icon_0.png);
        transition: all .3s ease;
        &:hover {
            opacity: .7;
        }
    }
}
@media screen and (max-width: 1600px) {
    .index-case-outline {
        max-width: 1400px;
    }
    .index-case-box {
        padding-left: 85px;
        padding-right: 85px;
    }
}
@media screen and (max-width: 1400px) {
    .index-case-outline {
        max-width: 1060px;
    }
    .index-chose {
        padding-top: 70px;
        height: 820px;
    }
    .index-chose-box {
        height: 90*7px;
    }
    .index-chose-left {
        width: 600px;
    }
    .index-chose-box-left-link {
        height: 406px;
    }
    .index-chose-box-right ul li {
        height: 90px;
        width: (1/5) * 100%;
    }
    .index-chose-box-right ul li .text h5 {
        font-size: 16px;
    }
}
.viewpoint {
    padding-top: 57px;
    min-width: 1250px;
    .index-title {
        h5 {
            color: rgba(0, 0, 0, .7);
        }
        h6 {
            color: rgba(89, 89, 89, .7);
        }
    }
    &-box {
        position: relative;
        width: 100%;
        margin-top: 36px;
        padding-bottom: (733/1920) * 100%;
        background: #fff none no-repeat center center;
        background-size: 100%;
        .img {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    &-text {
        position: absolute;
        display: block;
        cursor: pointer;
        top: 50%;
        transform: translate(0, -50%);
        left: 140px;
        width: 680px;
        font-size: 18px;
        color: #fff;
        font-family: "SimHei";
        line-height: 34px;
        letter-spacing: 1px;
        &:hover {
            color: #fff;
        }
        h5 {
            line-height: 66px;
            font-size: 55px;
            color: #fff;
            font-family: "SimHei";
            transition: all .5s ease-out;
            margin-left: -40px;
            opacity: 0;
        }
        .p {
            margin-left: -40px;
            opacity: 0;
            margin-top: 40px;
            &:nth-child(1) {
                transition: all .5s ease-out .2s;
            }
            &:nth-child(2) {
                transition: all .5s ease-out .3s;
            }
            &:nth-child(3) {
                transition: all .5s ease-out .4s;
            }
            &:nth-child(4) {
                transition: all .5s ease-out .5s;
            }
        }
        .icon-div {
            opacity: 0;
            margin-left: -40px;
            transition: all .5s ease-out .5s;
        }
        .icon {
            margin-top: 40px;
            width: 59px;
            height: 59px;
            background: url(../images/viewpoint_icon_0.png) no-repeat;
            background-size: 100% 100%;
            transition: all .5s ease;
            &:hover {
                margin-left: 15px;
                opacity: .5;
            }
        }
    }
    .theactive {
        h5,
        .p,
        .icon-div {
            margin-left: 0;
            opacity: 1;
        }
    }
}
.index-new {
    min-width: 1250px;
    padding-top: 64px;
    padding-bottom: 113px;
    background: #e8e8e8;
    .index-title {
        h5 {
            color: rgba(0, 0, 0, .7);
        }
        h6 {
            color: rgba(51, 51, 51, .7);
        }
    }
    &-switch {
        font-size: 15px;
        color: #666;
        text-align: center;
        margin-top: 15px;
        li {
            display: inline-block;
            text-align: center;
            padding-left: 32px;
            padding-right: 32px;
            position: relative;
            a {
                cursor: pointer;
                &:hover {
                    color: #b41515;
                }
            }
        }
        .on {
            a {
                color: #b41515;
            }
        }
        li + li {
            &:before {
                content: "";
                position: absolute;
                left: -8px;
                top: 11px;
                width: 15px;
                height: 1px;
                background: #bababa;
            }
        }
        .li {
            max-width: 1600px;
            font-size: 0;
            margin: 0 auto;
            margin-top: 48px;
            &-title {
                display: none;
            }
            .new-more {
                display: block;
                width: 379px;
                height: 51px;
                margin: 0 auto;
                margin-top: 60px;
                background: url(../images/case_icon_0.png);
                transition: all .3s ease;
                @media screen and (max-width: 1500px) {
                    margin-top: 50px;
                }
                @media screen and (max-width: 1400px) {
                    margin-top: 40px;
                }
                &:hover {
                    opacity: .7;
                }
            }
            .left {
                position: relative;
                width: 578px;
                height: 561px;
                display: inline-block;
                vertical-align: top;
                transition: all .4s ease-out;
                &:hover {
                    transform: translate3d(0, -5px, 0);
                    .swiper-slide-text .text h5 {
                        color: #d12322;
                    }
                }
                &-box {
                    background: #fff;
                    position: relative;
                    z-index: 10;
                    width: 100%;
                    height: 100%;
                    padding: 43px 47px 50px 53px;
                    box-sizing: border-box;
                    &-swiper {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                    }
                }
                .shadow {
                    position: absolute;
                    bottom: -40px;
                    left: 0px;
                    width: 100%;
                    padding-bottom: (99/612) * 100%;
                    background: url(../images/new_icon_0.png);
                    background-size: 100% 100%;
                }
                .swiper-slide {
                    display: block;
                    background: #fff;
                }
                .pagination2 {
                    text-align: left;
                    .swiper-pagination-bullet {
                        background: #4e4e4e;
                        opacity: 1;
                        margin-right: 4px;
                    }
                    .swiper-pagination-bullet-active {
                        background: #dc301f;
                    }
                }
                .swiper-slide-box {
                    width: 478px;
                    height: 313px;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        max-width: 100%;
                        height: auto !important;
                    }
                }
                .swiper-slide-text {
                    width: 478px;
                    margin-top: 30px;
                    font-size: 0;
                    .text {
                        display: inline-block;
                        vertical-align: top;
                        width: 388px;
                        height: 90px;
                        text-align: left;
                        padding-right: 20px;
                        box-sizing: border-box;
                        h3 {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 19px;
                            color: #060606;
                            transition: all .3s ease;
                        }
                        p {
                            height: 50px;
                            margin-top: 12px;
                            word-break: break-all;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            font-size: 13px;
                            line-height: 25px;
                            color: #888;
                        }
                    }
                    .date {
                        display: inline-block;
                        vertical-align: top;
                        width: 90px;
                        height: 90px;
                        border-radius: 4px;
                        background: #b41515;
                        text-align: center;
                        .d {
                            font-size: 58px;
                            color: #fff;
                            font-family: "CG";
                            line-height: 58px;
                            margin-top: 6px;
                        }
                        .ym {
                            margin-top: 2px;
                            line-height: 16.4px;
                            font-size: 16.4px;
                            font-family: "Arial";
                            color: #fff;
                        }
                    }
                }
            }
            .right {
                margin-left: 22px;
                width: 1000px;
                height: 561px;
                display: inline-block;
                vertical-align: top;
                text-align: left;
                &-li {
                    position: relative;
                    text-align: left;
                    display: inline-block;
                    vertical-align: top;
                    width: 50%;
                    height: 187px;
                    transition: all .4s ease-out;
                    &:nth-child(even) {
                        padding-left: 2px;
                        box-sizing: border-box;
                    }
                    &:nth-child(n+3) {
                        padding-top: 1px;
                        box-sizing: border-box;
                    }
                    &-box {
                        position: relative;
                        z-index: 10;
                        width: 100%;
                        height: 100%;
                        background: #fff;
                    }
                    &:hover {
                        z-index: 100;
                        box-shadow: 0 8px 35px rgba(0, 0, 0, .2);
                        .date .d {
                            color: #d12322;
                        }
                        .date .ym {
                            color: #d12322;
                        }
                        .text h5 {
                            color: #d12322;
                        }
                    }
                }
                .shadow {
                    position: absolute;
                    bottom: -30px;
                    left: 0;
                    width: 100%;
                    padding-bottom: (100/524) * 100%;
                    background: url(../images/new_icon_1.png);
                    background-size: 100% 100%;
                }
                .date {
                    display: inline-block;
                    vertical-align: top;
                    width: 110px;
                    height: 100%;
                    padding-top: 55px;
                    padding-right: 25px;
                    padding-left: 35px;
                    box-sizing: border-box;
                    text-align: center;
                    .d {
                        font-size: 42px;
                        color: #555;
                        font-family: "Arial";
                        line-height: 42px;
                        margin-top: 6px;
                        transition: color .3s ease;
                    }
                    .ym {
                        margin-top: 2px;
                        line-height: 14px;
                        font-size: 14px;
                        font-family: "Arial";
                        color: #333;
                        transition: color .3s ease;
                    }
                }
                .text {
                    display: inline-block;
                    vertical-align: top;
                    width: 333px;
                    height: 100%;
                    text-align: left;
                    padding-top: 55px;
                    box-sizing: border-box;
                    h3 {
                        font-size: 15px;
                        color: #333;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        transition: color .3s ease;
                    }
                    p {
                        margin-top: 10px;
                        color: #888;
                        font-size: 13px;
                        line-height: 25px;
                        height: 50px;
                        word-break: break-all;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1620px) {
    .index-new-switch .li .left {
        width: 508px;
    }
    .index-new-switch .li .left-box {
        padding-left: 15px;
        padding-right: 15px;
    }
    .index-new-switch .li .right {
        margin-left: 15px;
    }
    .index-new-switch .li .right {
        width: 900px;
    }
    .index-new-switch .li .right .date {
        padding-left: 20px;
        padding-right: 12px;
        width: 90px;
    }
    .viewpoint-text h5 {
        line-height: 50px;
        font-size: 40px;
    }
    .viewpoint-text .p {
        margin-top: 20px;
    }
    .viewpoint-text .icon {
        margin-top: 20px;
    }
}
@media screen and (max-width: 1450px) {
    .index-new-switch .li .left {
        height: 480px;
        width: 458px;
    }
    .index-new-switch .li .left .swiper-slide-box {
        width: 428px;
        height: 280px;
    }
    .index-new-switch .li .left .swiper-slide-text {
        width: 428px;
    }
    .index-new-switch .li .left .swiper-slide-text .text {
        width: 338px;
    }
    .index-new-switch .li .left-box {
        padding-top: 23px;
        padding-bottom: 30px;
    }
    .index-new-switch .li .right {
        width: 800px;
        height: 480px;
    }
    .index-new-switch .li .right .date {
        padding-left: 20px;
        padding-right: 12px;
        padding-top: 45px;
        width: 90px;
    }
    .index-new-switch .li .right .text {
        width: 233px;
        padding-top: 45px;
    }
    .index-new-switch .li .right-li {
        height: 160px;
    }
}
@media screen and (max-width: 1400px) {
    .viewpoint-text {
        width: 500px;
    }
    .viewpoint-text h5 {
        font-size: 34px;
        line-height: 40px;
    }
    .index-case {
        padding-top: 60px;
        padding-bottom: 80px;
    }
    .index-case-outline {
        margin-top: 20px;
        width: 1200px;
        max-width: none;
        min-width: 0;
    }
    .index-case .item {
        width: 330px;
        padding: 0 5px;
    }
    .index-case .img {
        width: 356px;
    }
    .index-case-box {
        padding: 50px 30px;
    }
    .index-case .text {
        padding-top: 10px;
        height: 70px;
    }
    .index-case .text h3 {
        padding-top: 5px;
    }
    .index-case-box2 {
        height: 1366px;
    }
    .index-case .text h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .index-case .item:nth-child(1),
    .index-case .item:nth-child(3),
    .index-case .item:nth-child(5),
    .index-case .item:nth-child(6),
    .index-case .item:nth-child(7),
    .index-case .item:nth-child(8),
    .index-case .item:nth-child(9),
    .index-case .item:nth-child(11),
    .index-case .item:nth-child(12) {
        height: 200px;
        width: 330px;
        padding: 0 5px;
    }
    .index-case .item:nth-child(1) .img,
    .index-case .item:nth-child(3) .img,
    .index-case .item:nth-child(5) .img,
    .index-case .item:nth-child(6) .img,
    .index-case .item:nth-child(7) .img,
    .index-case .item:nth-child(8) .img,
    .index-case .item:nth-child(9) .img,
    .index-case .item:nth-child(11) .img,
    .index-case .item:nth-child(12) .img {
        height: 197px;
        width: 320px;
    }
    .index-case .item:nth-child(2) .img,
    .index-case .item:nth-child(4) .img,
    .index-case .item:nth-child(10) .img {
        height: 470px;
        width: 320px;
    }
    .index-case .item:nth-child(2),
    .index-case .item:nth-child(4),
    .index-case .item:nth-child(10) {
        height: 580px;
    }
    .index-case .item:nth-child(4) {
        top: 273px;
    }
    .index-case .item:nth-child(2) {
        left: 335px;
    }
    .index-case .item:nth-child(5) {
        top: 273px;
        left: 670px;
    }
    .index-case .item:nth-child(3) {
        left: 670px;
    }
    .index-case .item:nth-child(6) {
        top: 546px;
        left: 335px;
    }
    .index-case .item:nth-child(7) {
        top: 546px;
        left: 670px;
    }
    .index-case .item:nth-child(8) {
        top: 815px;
    }
    .index-case .item:nth-child(9) {
        top: 815px;
        left: 335px;
    }
    .index-case .item:nth-child(10) {
        top: 815px;
        left: 670px;
    }
    .index-case .item:nth-child(11) {
        top: 1088px;
    }
    .index-case .item:nth-child(12) {
        top: 1088px;
        left: 335px;
    }
    .index-new-switch .li .right {
        width: 700px;
    }
    .index-new-switch .li .left {
        width: 400px;
        height: 482px;
    }
    .index-new-switch .li .right-li {
        height: 160px;
    }
    .index-new-switch .li .right .date {
        padding-top: 40px;
    }
    .index-new-switch .li .right .text {
        padding-top: 40px;
    }
    .index-new {
        padding-top: 60px;
        padding-bottom: 70px;
    }
    .index-new-switch .li {
        margin-top: 30px;
    }
    .index-new-switch .li .left .swiper-slide-box {
        width: 370px;
        height: 274px;
    }
    .index-new-switch .li .left .swiper-slide-text {
        width: 370px;
        margin-top: 20px;
    }
    .index-new-switch .li .left .swiper-slide-text .text {
        width: 270px;
    }
}

.pop-window {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba(#000, .7);
    display: none;
    &-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .service-window {
        width: 380px;
        height: 410px;
        border-radius: 10px;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: #f1f1f1;
        box-sizing: border-box;
        padding: 70px 34px 42px;
    }
    &-item {
        position: relative;
        transform: scale(.8);
        transition: .4s;
        display: none;
        &.show {
            transform: scale(1);
        }
        .close-btn {
            position: absolute;
            right: 26px;
            top: 26px;
            z-index: 10;
            cursor: pointer;
            font-size: 16px;
            color: #fff;
        }
    }
    .content-box {
        display: flex;
        justify-content: space-between;
        flex-flow: column;
        height: 100%;
        // padding: 64px 45px;
        @media screen and (max-width: 1024px) {
            padding: px2rem(100px) px2rem(60px) 0 0;
            align-items: center;
        }
        .box {
            color: #fff;
            display: flex;
            font-size: 15px;
            align-items: flex-end;
            line-height: 1;
            p {
                border-left: 1px solid #fff;
                padding-left: 8px;
                margin-left: 8px;
            }
        }
        .phone {
            font-size: 24px;
            font-weight: bold;
            color: #fff;
            font-family: Arial;
            display: flex;
            align-items: center;
            margin-top: 15px;
            img {
                margin-right: 5px;
            }
        }
        .qrcode-box {
            width: 130px;
            height: 130px;
            border-radius: 5px;
            box-shadow: 0px 3px 7px 0px rgba(200, 0, 30, .4);
            background-color: #fff;
            padding: 7px;
            box-sizing: border-box;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .bottom {
            display: flex;
            align-items: center;
            .p {
                font-size: 16px;
                color: #333;
                line-height: 24px;
                margin-left: 34px;
                flex: 1;
                vertical-align: bottom;
                position: relative;
                img {
                    &:nth-of-type(1) {
                        position: absolute;
                        bottom: 100%;
                        left: 0;
                        transform: translateX(0);
                        margin-bottom: 10px;
                    }
                    vertical-align: bottom;
                    transform: translateX(10px);
                }
            }
        }
    }
}