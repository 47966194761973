.website {
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: 1920px;
    min-width: 1250px;
    height: 5735px;
    overflow: hidden;
    background: url(../images/website_bg_1.jpg) no-repeat top center;
}
.website-menu {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 501;
    background-color: rgba(#f7f7f7, .9);
    border-bottom: 1px solid #dfdfdf;
    text-align: center;
    font-size: 0;
    transition: all .2s linear;
    transform: translateY(-20px);
    opacity: 0;
    visibility: hidden;
    &.show {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
    li {
        display: inline-block;
        vertical-align: top;
        width: 10%;
        box-sizing: border-box;
        border-left: 1px solid #efefef;
        //background-color: #f7f7f7;
        position: relative;
        height: 102px;
        transition: all .3s ease;
        cursor: pointer;
        &:last-child {
            border-right: 1px solid #efefef;
        }
        &:nth-child(1) {
            .iconfont {
                &:before {
                    content: "\e603";
                }
            }
        }
        &:nth-child(2) {
            .iconfont {
                &:before {
                    content: "\e63d";
                }
            }
        }
        &:nth-child(3) {
            .iconfont {
                &:before {
                    content: "\e6b4";
                }
            }
        }
        &:nth-child(4) {
            .iconfont {
                &:before {
                    content: "\e6a2";
                }
            }
        }
        &:nth-child(5) {
            .iconfont {
                &:before {
                    content: "\e663";
                }
            }
        }
        &:nth-child(6) {
            .iconfont {
                &:before {
                    content: "\e802";
                }
            }
        }
        &:nth-child(7) {
            .iconfont {
                &:before {
                    content: "\e63e";
                }
            }
        }
        &:hover {
            z-index: 10;
            box-shadow: 0 15px 30px rgba(0, 0, 0, .1);
            background-color: #f7f7f7;
            .iconfont,
            h3 {
                color: #b41515;
            }
        }
    }
    .box {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
    .iconfont {
        font-size: 37px;
        color: #555;
        transition: all .3s ease;
    }
    h3 {
        font-size: 13px;
        color: #888;
    }
}
.website-title {
    display: inline-block;
    h5 {
        font-size: 72px;
        line-height: 72px;
        color: #fff;
        margin-bottom: 28px;
    }
    h6 {
        font-size: 22px;
        line-height: 36px;
        color: #fff;
        font-family: "SimHei";
    }
    p {
        font-size: 18px;
        line-height: 36px;
        color: #fff;
        font-family: "SimHei";
    }
    .btn {
        position: relative;
        margin-top: 70px;
        display: block;
        width: 260px;
        height: 56px;
        border-radius: 28px;
        background: #fff;
        box-shadow: 3px 5px 16px rgba(52, 121, 242, .28);
        transition: all .3s ease;
        &:after {
            content: "";
            position: absolute;
            right: 12px;
            top: 10px;
            width: 37px;
            height: 37px;
            background: url(../images/website_icon_1.png);
            background-size: 100% 100%;
        }
        span {
            position: absolute;
            top: 0;
            left: 0;
            margin-left: 54px;
            font-size: 19px;
            line-height: 56px;
            color: #377dff;
            font-family: "Microsoft JhengHei";
            transition: all .3s ease;
        }
        &:hover {
            box-shadow: 0 0px 32px 5px rgba(8, 0, 49, .16);
            span {
                margin-left: 60px;
            }
        }
    }
}
.website-title-1 {
    .content {
        max-width: 594px;
        padding-top: 60px;
        background: url(../images/website_img_9.png) no-repeat;
        margin-left: 80px;
    }
    h5 {
        font-size: 72px;
        line-height: 72px;
        color: #353535;
        margin-bottom: 23px;
    }
    h6 {
        font-size: 22px;
        line-height: 36px;
        color: #353535;
        font-family: "SimHei";
    }
    p {
        font-size: 18px;
        line-height: 36px;
        color: #353535;
        font-family: "SimHei";
    }
    .btn {
        position: relative;
        margin-top: 30px;
        display: block;
        width: 260px;
        height: 56px;
        border-radius: 28px;
        background: #377dff;
        box-shadow: 3px 5px 16px rgba(52, 121, 242, .28);
        transition: all .3s ease;
        &:after {
            content: "";
            position: absolute;
            right: 12px;
            top: 10px;
            width: 37px;
            height: 37px;
            background: url(../images/website_icon_3.png);
            background-size: 100% 100%;
        }
        span {
            position: absolute;
            top: 0;
            left: 0;
            margin-left: 54px;
            font-size: 19px;
            line-height: 56px;
            color: #fff;
            font-family: "Microsoft JhengHei";
            transition: all .3s ease;
        }
        &:hover {
            box-shadow: 0 0px 32px 5px rgba(8, 0, 49, .16);
            span {
                margin-left: 60px;
            }
        }
    }
}
.website-title-2 {
    .content {
        width: 594px;
        padding-top: 60px;
        margin-left: 80px;
    }
    h5 {
        font-size: 72px;
        line-height: 72px;
        color: #fff;
        margin-bottom: 23px;
    }
    h6 {
        font-size: 22px;
        line-height: 36px;
        font-family: "SimHei";
        color: #fff;
    }
    p {
        font-size: 18px;
        line-height: 36px;
        color: #fff;
        font-family: "SimHei";
    }
    .btn {
        position: relative;
        margin-top: 30px;
        display: block;
        width: 260px;
        height: 56px;
        border-radius: 28px;
        background: #fff;
        box-shadow: 3px 5px 16px rgba(52, 121, 242, .28);
        transition: all .3s ease;
        &:after {
            content: "";
            position: absolute;
            right: 12px;
            top: 10px;
            width: 37px;
            height: 37px;
            background: url(../images/website_icon_1.png);
            background-size: 100% 100%;
        }
        span {
            position: absolute;
            top: 0;
            left: 0;
            margin-left: 54px;
            font-size: 19px;
            line-height: 56px;
            color: #377dff;
            font-family: "Microsoft JhengHei";
            transition: all .3s ease;
        }
        &:hover {
            box-shadow: 0 0px 32px 5px rgba(8, 0, 49, .16);
            span {
                margin-left: 60px;
            }
        }
    }
}
.website {
    &-one {
        position: relative;
        .website-title {
            position: absolute;
            left: 50%;
            opacity: 0;
            margin-top: 30px;
            transition: all .6s ease .4s;
            margin-left: -770px;
            padding-top: 280px;
        }
        .img {
            position: absolute;
            left: 50%;
            opacity: 0;
            margin-left: -430px;
            top: 101px;
            width: 995px;
            height: 897px;
            background-size: 100% 100%;
            transition: all .8s ease .7s;
        }
        &-on {
            .website-title {
                margin-top: 0px;
                opacity: 1;
            }
            .img {
                opacity: 1;
                top: 83px;
                margin-left: -338px;
            }
        }
        .mouse {
            cursor: pointer;
            position: absolute;
            top: 768px;
            margin-top: 0px;
            left: 50%;
            margin-left: -45px;
            width: 90px;
            height: 90px;
            border-radius: 50%;
            box-shadow: 0 21px 28px 5px rgba(8, 0, 49, .16);
            background: url(../images/website_icon_2.png) #fff no-repeat center center;
            transition: all .3s ease;
            &:hover {
                margin-top: -5px;
            }
        }
    }
    &-two {
        .website-title-1 {
            position: absolute;
            top: 930px;
            left: 0;
            width: 100%;
            text-align: center;
            .img {
                display: inline-block;
                vertical-align: middle;
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                margin-left: -440px;
                opacity: 0;
                width: 730px;
                transition: all .8s ease .3s;
                img {
                    max-width: 100%;
                }
            }
            .content {
                display: inline-block;
                vertical-align: middle;
                position: absolute;
                left: 50%;
                top: 150px;
                transform: translate(-50%, 0);
                margin-left: 350px;
                opacity: 0;
                transition: all .8s ease .4s;
                text-align: left;
            }
        }
        &-on .website-title-1 {
            .img {
                opacity: 1;
                margin-left: -390px;
            }
            .content {
                opacity: 1;
                margin-left: 300px;
            }
        }
    }
    &-three {
        .website-title-2 {
            position: absolute;
            top: 1630px;
            right: 0;
            width: 100%;
            .img {
                position: absolute;
                top: 0px;
                left: 50%;
                margin-left: 600px;
                transform: translate(-50%, 0%);
                opacity: 0;
                transition: all .8s ease .3s;
            }
            .content {
                position: absolute;
                top: 300px;
                left: 50%;
                margin-left: -400px;
                transform: translate(-50%, 0%);
                opacity: 0;
                transition: all .8s ease .4s;
            }
        }
        &-on .website-title-2 {
            .img {
                margin-left: 400px;
                opacity: 1;
            }
            .content {
                margin-left: -300px;
                opacity: 1;
            }
        }
    }
    &-four {
        .website-title-2 {
            position: absolute;
            top: 2540px;
            left: 0%;
            width: 100%;
            .img {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, 0);
                opacity: 0;
                transition: all .8s ease .3s;
                margin-left: 70px;
                width: 846px;
                img {
                    max-width: 100%;
                }
            }
            .content {
                position: absolute;
                top: 240px;
                left: 50%;
                transform: translate(-50%, 0);
                opacity: 0;
                transition: all .8s ease .4s;
                margin-top: -120px;
            }
        }
        &-on .website-title-2 {
            .img {
                margin-left: -300px;
                opacity: 1;
            }
            .content {
                margin-left: 400px;
                opacity: 1;
            }
        }
    }
    &-five {
        .website-title-1 {
            position: absolute;
            top: 3434px;
            right: 0;
            width: 100%;
            .img {
                position: absolute;
                right: -100px;
                opacity: 0;
                transition: all .8s ease .3s;
                margin-left: 70px;
                width: 745px;
                img {
                    max-width: 100%;
                }
            }
            .content {
                position: absolute;
                top: 120px;
                margin-left: -500px;
                left: 50%;
                transform: translate(-50%, 0);
                opacity: 0;
                transition: all .8s ease .4s;
            }
        }
        &-on .website-title-1 {
            .img {
                right: 0;
                opacity: 1;
            }
            .content {
                margin-left: -300px;
                opacity: 1;
            }
        }
    }
    &-six {
        .website-title-1 {
            position: absolute;
            top: 3960px;
            left: 0px;
            width: 100%;
        }
        .img {
            width: 983px;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            margin-left: -500px;
            opacity: 0;
            transition: all .8s ease .3s;
        }
        .content {
            width: 552px;
            position: absolute;
            left: 50%;
            top: 260px;
            transform: translate(-50%, 0);
            opacity: 0;
            transition: all .8s ease .4s;
            margin-top: 20px;
            margin-left: 460px;
        }
        &-on .website-title-1 {
            .img {
                margin-left: -400px;
                opacity: 1;
            }
            .content {
                margin-left: 400px;
                opacity: 1;
            }
        }
    }
    &-seven {
        &-btn {
            position: absolute;
            top: 4960px;
            left: 50%;
            transform: translate(-50%, 0);
            &-li {
                position: relative;
                cursor: pointer;
                width: 120px;
                height: 120px;
                transform: rotate(45deg);
                border-radius: 10px;
                background: #377dff;
                border: solid 1px #fff;
                color: #fff;
                transition: all .5s ease;
                margin-top: -37px;
                opacity: 0;
                display: inline-block;
                vertical-align: top;
                margin: 0px 25px;
                &:hover {
                    background-color: #fff;
                    color: #377dff;
                }
                .p {
                    display: block;
                    margin-top: 45px;
                    text-align: center;
                    font-size: 18px;
                    transform: rotate(-45deg);
                }
            }
            &-on {
                background-color: #fff;
                color: #377dff;
            }
            &-scroll {
                .website-seven-btn-li {
                    opacity: 1;
                    margin-top: 0px;
                }
            }
        }
        &-box {
            position: absolute;
            width: 100%;
            top: 5120px;
            opacity: 0;
            margin-top: -50px;
            transition: all .6s ease .3s;
            text-align: center;
            &-on {
                margin-top: 0px;
                opacity: 1;
            }
            &-li {
                display: none;
                position: absolute;
                width: 1300px;
                left: 50%;
                transform: translate(-50%, 0);
                &-on {
                    display: block;
                }
            }
            .content {
                text-align: left;
                width: 594px;
                display: inline-block;
                vertical-align: top;
                position: relative;
                top: 120px;
            }
            .img {
                padding-left: 30px;
                display: inline-block;
                vertical-align: top;
                width: 583px;
                height: 556px;
                img {
                    max-width: 100%;
                }
            }
            h5 {
                font-size: 72px;
                line-height: 72px;
                color: #fff;
                margin-bottom: 23px;
            }
            h6 {
                font-size: 22px;
                line-height: 36px;
                font-family: "SimHei";
                color: #fff;
            }
            p {
                font-size: 18px;
                line-height: 36px;
                color: #fff;
                font-family: "SimHei";
            }
            .btn {
                position: relative;
                margin-top: 30px;
                display: block;
                width: 260px;
                height: 56px;
                border-radius: 28px;
                background: #fff;
                box-shadow: 3px 5px 16px rgba(52, 121, 242, .28);
                transition: all .3s ease;
                &:hover {
                    box-shadow: 0 0px 32px 5px rgba(8, 0, 49, .16);
                    span {
                        margin-left: 60px;
                    }
                }
                &:after {
                    content: "";
                    position: absolute;
                    right: 12px;
                    top: 10px;
                    width: 37px;
                    height: 37px;
                    background: url(../images/website_icon_1.png);
                    background-size: 100% 100%;
                }
                span {
                    margin-left: 54px;
                    font-size: 19px;
                    line-height: 56px;
                    color: #377dff;
                    font-family: "Microsoft JhengHei";
                    transition: all .3s ease;
                }
            }
        }
    }
}
@media screen and (max-width: 1560px) {
    .website {
        height: 4775px;
        background: url(../images/website_bg_2.jpg) no-repeat top center;
    }
    .website-one .website-title {
        position: absolute;
        left: 0px;
        margin-left: 20px;
    }
    .website-title h1 {
        font-size: 36px;
        line-height: 36px;
    }
    .website-title .btn {
        margin-top: 20px;
        width: 220px;
        height: 46px;
    }
    .website-title .btn:hover span {
        margin-left: 40px;
    }
    .website-title .btn span {
        line-height: 46px;
        margin-left: 30px;
        font-size: 16px;
    }
    .website-title .btn:after {
        width: 30px;
        height: 30px;
        top: 8px;
    }
    .website-title-1 h5 {
        font-size: 36px;
        line-height: 36px;
    }
    .website-title-1 h6 {
        font-size: 18px;
        line-height: 32px;
    }
    .website-title-1 p {
        font-size: 16px;
        line-height: 32px;
    }
    .website-title-1 .btn {
        margin-top: 20px;
        width: 220px;
        height: 46px;
    }
    .website-title-1 .btn:hover span {
        margin-left: 40px;
    }
    .website-title-1 .btn span {
        line-height: 46px;
        margin-left: 30px;
        font-size: 16px;
    }
    .website-title-1 .btn:after {
        width: 30px;
        height: 30px;
        top: 8px;
    }
    .website-title-2 h5 {
        font-size: 40px;
        line-height: 40px;
    }
    .website-title-2 h6 {
        font-size: 18px;
        line-height: 32px;
    }
    .website-title-2 p {
        font-size: 16px;
        line-height: 32px;
    }
    .website-title-2 .btn {
        margin-top: 20px;
        width: 220px;
        height: 46px;
    }
    .website-title-2 .btn:hover span {
        margin-left: 40px;
    }
    .website-title-2 .btn span {
        line-height: 46px;
        margin-left: 30px;
        font-size: 16px;
    }
    .website-title-2 .btn:after {
        width: 30px;
        height: 30px;
        top: 8px;
    }
    .website-seven-box .btn {
        margin-top: 20px;
        width: 220px;
        height: 46px;
    }
    .website-seven-box .btn:hover span {
        margin-left: 40px;
    }
    .website-seven-box .btn span {
        line-height: 46px;
        margin-left: 30px;
        font-size: 16px;
    }
    .website-seven-box .btn:after {
        width: 30px;
        height: 30px;
        top: 8px;
    }
    .website-one p {
        width: 380px;
    }
    .website-two .website-title-1 {
        position: absolute;
        top: 840px;
        left: 0px;
        margin-left: 20px;
    }
    .website-two .website-title-1 .img {
        width: 600px;
        top: 50px;
    }
    .website-two-on .website-title-1 .img {
        margin-left: -310px;
    }
    .website-two-on .website-title-1 .content {
        margin-left: 240px;
        width: 440px;
    }
    .website-three .website-title-2 .img {
        width: 700px;
        img {
            max-width: 100%;
        }
    }
    .website-four .website-title-2 {
        top: 2070px;
        left: 0px;
        margin-left: 20px;
    }
    .website-four .website-title-2 .img {
        margin-left: -380px;
        width: 700px;
    }
    .website-four .website-title-2 .content {
        top: 200px;
    }
    .website-four-on .website-title-2 .img {
        margin-left: -280px;
    }
    .website-four-on .website-title-2 .content {
        margin-left: 300px;
    }
    .website-three .website-title-2 {
        top: 1440px;
    }
    .website-three .website-title-2 .content {
        top: 200px;
        margin-left: -240px;
    }
    .website-three-on .website-title-2 .content {
        margin-left: -140px;
    }
    .website-five .website-title-1 {
        top: 2800px;
    }
    .website-five .website-title-1 .img {
        width: 600px;
    }
    .website-five .website-title-1 .content {
        top: 80px;
    }
    .website-five-on .website-title-1 .content {
        margin-left: -250px;
        max-width: 620px;
    }
    .website-five .content {
        margin-left: 0px;
    }
    .website-six .website-title-1 {
        top: 3200px;
    }
    .website-six .website-title-1 .img {
        width: 800px;
        img {
            max-width: 100%;
        }
    }
    .website-six .content {
        top: 200px;
    }
    .website-six-on .website-title-1 .content {
        margin-left: 360px;
    }
    .website-six-on .website-title-1 .img {
        margin-left: -350px;
    }
    .website-six .website-title-2 {
        left: 0px;
        margin-left: 0px;
    }
    .website-seven-btn {
        top: 4100px;
    }
    .website-seven-box {
        top: 4250px;
    }
    .website-seven-box h5 {
        font-size: 36px;
        line-height: 36px;
    }
    .website-seven-box h6 {
        font-size: 18px;
        line-height: 32px;
    }
    .website-seven-box .content {
        width: 540px;
        top: 100px;
        padding-left: 20px;
        box-sizing: border-box;
        background-position-x: 20px;
    }
    .website-seven-btn-li {
        width: 102px;
        height: 102px;
    }
    .website-seven-btn-li .p {
        margin-top: 36px;
        font-size: 16px;
    }
    .website-seven-box .img {
        width: 524px;
        height: 500px;
    }
}